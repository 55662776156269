import {TextField} from '@mui/material'
import React from 'react'
type IProps = {
  label?: string
  id: string
  hasError: boolean
  val: string
  changedValues: (value: string) => void
  isRequired: boolean
  errorText: string
  placeHolder?: string
  isMultiline?: boolean
  rows?: number
  isFullWidth?: boolean
  isDisabled?: boolean
  type?: string
  onBlur?: () => void
}
const MuiTextField = ({
  hasError,
  id,
  label,
  val,
  changedValues,
  errorText,
  isRequired,
  placeHolder,
  isMultiline,
  rows,
  isFullWidth,
  isDisabled,
  type = 'search',
  onBlur,
}: IProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value
    changedValues(val.trim() === '' ? '' : val)
  }
  return (
    <TextField
      {...(isFullWidth && {fullWidth: isFullWidth})}
      required={isRequired}
      error={isRequired && hasError ? true : false}
      id={id}
      variant={'outlined'}
      {...(label && {label: label})}
      value={val}
      onChange={handleChange}
      placeholder={placeHolder ? placeHolder : ''}
      InputLabelProps={{shrink: true}}
      type={type}
      helperText={isRequired && hasError ? errorText : ''}
      {...(isMultiline && {multiline: true})}
      {...(rows !== undefined && {rows: rows})}
      {...(isDisabled && {disabled: isDisabled})}
      {...(onBlur && {onBlur: onBlur})}
    />
  )
}

export default MuiTextField
