import React, {useEffect, useState} from 'react'
import {
  CardCountRequestProps,
  ICOLORS,
  ICardSessionData,
  ICsrdsUOM,
  IUserCards,
} from '../../models/type'
import {H4Title} from '../../common-utilities/ui/Headings'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'
import {getCardCount} from '../../../redux/cards/api'
import {useLocation, useNavigate} from 'react-router-dom'
import _ from 'lodash'
import {getTrend} from './detail-view/CardDetailContext'
import {showErrorToast, showWarningToast} from '../../common-utilities/alerts/AlertModels'
import {useStoreData} from '../../common-utilities/hooks/storeData'
import {dateFormats, errorStatements} from '../../common-utilities/ui/Standards'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
type IProps = {
  data: IUserCards
  index: number
}

const DURATIONS: {[key: string]: '1' | '2' | '3'} = {
  'in last week': '1',
  'in last month': `2`,
  'in last quarter': `3`,
}
const UOMS: {[key: string]: ICsrdsUOM} = {
  hrs: 'Hours',
  '%': `Percent`,
  days: `Days`,
  mins: 'Mins',
}

type IValues = {
  currentValue: number
  previousValue: number
  trendClass: string
  border: ICOLORS
  unit: string
  duration: string
  statusUpdated?: boolean
  lastModifiedDate?: string
  emptyRecord: boolean
}

const initialValues: IValues = {
  currentValue: 0,
  previousValue: 0,
  trendClass: 'svg-icon-gray-300',
  border: 'white',
  unit: '',
  duration: '',
  emptyRecord: true,
}
const WidgetCard = ({data, index}: IProps) => {
  const CARD_ID = data.cardId
  const {uom, trend, cardTypeId, description} = data
  const {defaultLibrary} = useStoreData()
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const [count, setCount] = useState<IValues>(initialValues)
  const [error, setError] = useState<string | undefined>(undefined)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    checkData()
    return () => {
      setCount(initialValues)
      setError(undefined)
      setShowInfo(false)
    }
  }, [])
  useEffect(() => {
    checkData()
  }, [defaultLibrary])
  const checkData = () => {
    let sessionData = sessionStorage.getItem(`card-${CARD_ID}`)
    setError(undefined)
    if (sessionData) {
      let temp: ICardSessionData = JSON.parse(sessionData)
      if (
        temp.config &&
        _.isEqual(temp.config, data) &&
        temp.parms.libraryName === defaultLibrary &&
        temp.response !== undefined
      ) {
        setCount({
          currentValue: temp.currentValue,
          previousValue: temp.previousValue,
          trendClass: temp.trendClass,
          border: temp.border,
          unit: temp.unit,
          duration: temp.duration,
          lastModifiedDate: temp.response.lastModifiedDate,
          statusUpdated: temp.response.statusUpdated,
          emptyRecord: temp.response.emptyRecord,
        })
      } else loadCount()
    } else loadCount()
  }
  const loadCount = () => {
    if (defaultLibrary) {
      setLoading(true)

      let cardType = cardTypeId.toString()
      let parms: CardCountRequestProps = {
        cardId: parseInt(CARD_ID),
        period: DURATIONS[data.period] ? DURATIONS[data.period] : '',
        libraryName: defaultLibrary,
        cardtype: cardType,
        uom: UOMS[data.uom] ? UOMS[data.uom] : '',
      }
      getCardCount(parms)
        .then((res) => {
          setLoading(false)
          try {
            let {currentValue, previousValue, scope, lastModifiedDate, statusUpdated, emptyRecord} =
              res.data
            let fromDate = moment(scope.from)
            let toDate = moment(scope.to)
            let durationFormat = `${fromDate.format(dateFormats.onTable)}  to  ${toDate.format(
              dateFormats.onTable
            )}`
            if (cardType === '2' || cardType === '3') {
              if (UOMS[data.uom] === 'Days') {
                currentValue = Math.floor(Number(currentValue) / 24)
                previousValue = Math.floor(Number(previousValue) / 24)
              }

              if (UOMS[data.uom] === 'Mins') {
                currentValue = Math.round(Number(currentValue) * 60)
                previousValue = Math.round(Number(previousValue) * 60)
              }
            }
            let diff: 'up' | 'down' | 'equal' =
              currentValue > previousValue
                ? 'up'
                : currentValue === previousValue
                ? 'equal'
                : 'down'
            const {border, trendClass} = getTrend({trendValue: trend, diff})

            sessionStorage.setItem(
              `card-${CARD_ID}`,
              JSON.stringify({
                config: data,
                currentValue: currentValue,
                previousValue: previousValue,
                duration: durationFormat,
                trendClass: trendClass,
                border: border,
                unit: uom,
                parms,
                response: res.data,
              })
            )
            setCount({
              currentValue,
              previousValue,
              trendClass,
              border,
              unit: uom,
              duration: durationFormat,
              lastModifiedDate,
              statusUpdated,
              emptyRecord,
            })
          } catch (err) {
            //for demo
            setLoading(false)
            setCount(initialValues)
            setError(errorStatements.noRecords)
            console.log(err)
          }
        })
        .catch((err) => {
          setLoading(false)
          setCount(initialValues)
          setError(errorStatements.noRecords)
        })
    }
  }

  const handleCardClick = (id: string) => {
    navigate(`${pathname}/card-details/${id}`)
  }
  const handleWarnings = () => {
    showErrorToast({message: "There's no data to present"})

    if (!count.statusUpdated && count.lastModifiedDate !== '') {
      let date = moment(count.lastModifiedDate).format(dateFormats.dateTime)

      showWarningToast({
        title: 'Data ageing information',
        message: (
          <>
            <div>{`This report is using data extracted on`}</div>
            <div>{`${date}`}</div>
          </>
        ),
      })
    }
  }
  return (
    <>
      <Modal show={showInfo} onHide={() => setShowInfo(false)} size='sm' centered>
        <Modal.Header closeButton>
          <div className='heading4'>{`${data.name}`}</div>
        </Modal.Header>
        <Modal.Body>
          <div className='body2-regular'>{description}</div>
        </Modal.Body>
      </Modal>
      <div
        key={`Card_${index}`}
        className={`widgetCard bg-white border bg-opacity-70 position-relative h-100 error-div ${
          loading || error ? 'cursor-noClick' : 'cursor-pointer'
        }`}
        title='View detail report'
      >
        <div className='info_icon icon-hover-scale-3'>
          <KTSVG
            path={IconPaths.cardInfoIcon}
            className='svg-icon-primary svg-icon-2x'
            onClick={() => setShowInfo(true)}
            alt='description'
            title='Card description'
          />
        </div>
        <div
          className={`widgetCard_content px-6`}
          style={{height: '80%'}}
          onClick={() =>
            loading || error
              ? null
              : count.currentValue || (data.cardTypeId === 1 && !count.emptyRecord)
              ? handleCardClick(CARD_ID)
              : handleWarnings()
          }
        >
          <div className='d-flex justify-content-end px-3 align-items-center my-10'>
            {loading ? (
              <>
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </>
            ) : (
              <>
                <KTSVG
                  path={IconPaths.trendIndicator}
                  className={`svg-icon-3x mx-2 ${count.trendClass}`}
                  alt='Trend'
                  title='Trend indicator'
                />
                <H4Title color={'gray-500'}>{`${count.previousValue} ${count.unit}`}</H4Title>
              </>
            )}
          </div>
          {error !== undefined && error !== '' ? (
            <>
              <div className=''>
                <KTSVG
                  path={IconPaths.toastDanger}
                  className='svg-icon-2hx svg-icon-danger'
                  alt='Error'
                  title='Error'
                />
                <div className='d-flex flex-column  text-dark body1-medium hover-underline'>
                  <span>{`No information found!`}</span>
                  <span>{`for ${
                    data.cardTypeName && data.cardTypeName !== null ? data.cardTypeName : data.name
                  }`}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`widgetCard_count heading1  text-gray-700 mt-15`}>
                {loading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    <span>{count.currentValue}</span>
                    <span>{count.unit}</span>
                  </>
                )}
                {/* <span className={`fs-1 fw-boldest`}>{ele.unit}</span> */}
              </div>
              <div className={`widgetCard_title mt-3`}>
                <H4Title color={'gray-700'}>
                  {data.cardTypeName && data.cardTypeName !== null ? data.cardTypeName : data.name}
                </H4Title>
              </div>
            </>
          )}
        </div>
        <div className={`widgetCard_duration px-6 mt-5`}>
          <div className='label-medium text-gray-500'>{count.duration}</div>
        </div>
        <div className={`widgetCard_border bg-${count.border}`}></div>
      </div>
    </>
  )
}

export default WidgetCard
