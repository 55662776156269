import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import {r6DashboardReducer} from '../../app/modules/redux/DashboardSlice'
import {reactTableSlice} from '../../app/modules/redux/ReactTableConfigSlice'
import {dashboardTemplateSlice} from '../../app/modules/redux/DashboardTemplatesSlice'
import {ruleConfigSlice} from '../../app/modules/redux/RulesConfigSlice'
import {agedPayableReducer} from '../../app/modules/redux/AgedPayableSlice'
export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: r6DashboardReducer,
  reportConfig: reactTableSlice.reducer,
  dashboardTemplates: dashboardTemplateSlice.reducer,
  ruleConfig: ruleConfigSlice.reducer,
  agedPayable: agedPayableReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
