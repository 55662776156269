import React, {useCallback, useEffect, useState} from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import {toggleArrayValues} from '../../../common-utilities/sorting/SortingMethods'
import {FixedSizeList} from 'react-window'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useAsyncDebounce} from 'react-table'

type IProps = {
  items: string[]
  uniqueProp?: string
  onChange: (selected: any[]) => void
  selectedData: any[]
  label: string
}
const ITEM_HEIGHT = 50
const ITEM_PADDING_TOP = 8
const MenuProps = {
  autoFocus: false,
}

const CustomMultiSelect = React.memo(({items, onChange, selectedData, label}: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const handleSelectAll = useCallback(() => {
    if (selectedData.length === items.length) onChange([])
    else onChange(items)
  }, [items, selectedData, onChange])
  const [filteredItems, setFilteredItems] = useState(items)
  useEffect(() => {
    setFilteredItems(items)
  }, [items])
  const handleSearch = useAsyncDebounce((value: string) => {
    if (value === '') setFilteredItems(items)
    else setFilteredItems(items.filter((ele) => ele.toLowerCase().includes(value.toLowerCase())))
  }, 500)
  useEffect(() => {
    setFilteredItems(items)
  }, [open])
  return (
    <div>
      <FormControl fullWidth variant='outlined' className='customReorderSelect'>
        <InputLabel shrink id='demo-multiple-checkbox-label' htmlFor='demo-multiple-checkbox'>
          {label}
        </InputLabel>
        <Select
          fullWidth
          notched
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          variant='outlined'
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          label={label}
          value={selectedData}
          renderValue={(selected) => `( ${selected.length} ) Selected`}
          MenuProps={MenuProps}
        >
          <div
            key={'SelectAll'}
            className={`MuiMenuItem-root MuiMenuItem-gutters MuiButtonBase-root dropDownMenuItem  css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root d-flex ${
              selectedData.length === items.length ? 'selected' : ''
            }`}
          >
            <Checkbox checked={selectedData.length === items.length} onChange={handleSelectAll} />
            <ListItemText>
              <span className='fs-3'>{'Show all'}</span>
            </ListItemText>

            <div className='d-flex align-items-center position-relative '>
              <span className='svg-icon svg-icon-1 position-absolute ms-4'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2 svg-icon-primary'
                />
              </span>
              <input
                type='text'
                data-kt-filter='search'
                className='form-control border-primary  ps-14'
                placeholder='Refine list'
                onChange={(e) => handleSearch(e.target.value)}
                autoFocus
              />
            </div>
          </div>

          <FixedSizeList
            height={ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP}
            itemSize={ITEM_HEIGHT}
            itemCount={filteredItems.length}
            width={'100%'}
          >
            {({index, style}) => (
              <MenuItem
                key={filteredItems[index]}
                value={filteredItems[index]}
                className={`dropDownMenuItem ${
                  selectedData.indexOf(filteredItems[index]) > -1 ? 'selected' : ''
                }`}
                onClick={() => {
                  onChange(toggleArrayValues(selectedData, filteredItems[index]))
                }}
                style={style}
              >
                <Checkbox
                  checked={selectedData.indexOf(filteredItems[index]) > -1}
                  onChange={() => {
                    onChange(toggleArrayValues(selectedData, filteredItems[index]))
                  }}
                />
                <ListItemText>
                  <span className='fs-3'>{filteredItems[index]}</span>
                </ListItemText>
              </MenuItem>
            )}
          </FixedSizeList>
        </Select>
      </FormControl>
    </div>
  )
})

export default CustomMultiSelect
