import {Id, toast} from 'react-toastify'
import swal from 'sweetalert'
import {H4Title} from '../ui/Headings'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from '../ui/icons/iconPaths'
import moment from 'moment'
import {dateFormats} from '../ui/Standards'
export const deleteWArning = () => {
  let result = swal({
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover selected records!',
    icon: 'warning',
    buttons: ['Cancel', true],
    dangerMode: true,
  }).then((willDelete) => {
    return willDelete
  })
  return result
}

export const discardWArning = (title?: string, message?: string) => {
  let result = swal({
    title: title ? title : 'Are you sure?',
    text: message ? message : 'All changes will discard , do you want to continue ?',
    icon: 'warning',
    buttons: ['No', 'Yes'],
    dangerMode: true,
  }).then((willDelete) => {
    console.log(willDelete)
    return willDelete
  })
  return result
}
type ToastProps = {
  message: string | JSX.Element
  title?: string
  toastId?: Id
  type?: 'success' | 'error'
}
type MessageBoxProps = {
  message: string | JSX.Element
  title?: string
  icon?: string
  type: 'danger' | 'success' | 'warning' | 'primary'
}
export const MessageBox = ({icon, message, title, type}: MessageBoxProps) => {
  return (
    <div className='d-flex align-items-center gap-5'>
      {icon && <KTSVG path={icon} className={`svg-icon-2x svg-icon-${type}`} />}
      <div className='toastContent d-flex flex-column'>
        {title && <H4Title>{title}</H4Title>}
        <div className='text-gray-700 body3-regular'>{message}</div>
      </div>
    </div>
  )
}
export const showErrorToast = ({message, title}: ToastProps) => {
  return toast(
    <MessageBox icon={IconPaths.toastDanger} message={message} title={title} type='danger' />,
    {className: 'bg-white toastBorder dangerToast'}
  )
}
export const showSuccessToast = ({message, title}: ToastProps) => {
  return toast(
    <MessageBox icon={IconPaths.toastSuccess} message={message} title={title} type='success' />,
    {className: 'bg-white toastBorder successToast'}
  )
}
export const showWarningToast = ({message, title}: ToastProps) => {
  return toast(
    <MessageBox icon={IconPaths.toastWarning} message={message} title={title} type='warning' />,
    {className: 'bg-white toastBorder warningToast'}
  )
}
export const showPrimaryToast = ({message, title}: ToastProps) => {
  return toast(
    <MessageBox icon={IconPaths.toastPrimary} message={message} title={title} type='primary' />,
    {className: 'bg-white toastBorder primaryToast'}
  )
}

export const showLoadingToast = ({message, title, toastId, type}: ToastProps) => {
  if (toastId) {
    toast.update(toastId, {
      render: (
        <MessageBox
          icon={type === 'success' ? IconPaths.toastSuccess : IconPaths.toastDanger}
          message={message !== '' ? message : 'Completed...'}
          type={type === 'success' ? 'success' : 'danger'}
        />
      ),
      className: `bg-white toastBorder ${type === 'success' ? 'successToast' : 'dangerToast'}`,
      isLoading: false,
      autoClose: 2000,
    })
  } else {
    let id = toast.loading(<MessageBox message={message} title={title} type='primary' />, {
      className: 'bg-white toastBorder primaryToast',
    })
    return id
  }
}
const errorMessage = (err: any) => {
  try {
    if (err.response.data && err.response.data.Message) return err.response.data.Message.toString()
    else if (err.response.data && err.response.data.messages) {
      let res = ''
      if (typeof err.response.data.messages === 'object')
        err.response.data.messages.forEach((text: any) => {
          res = res + text + '\n'
        })
      else if (typeof err.response.data.messages === 'string') res = err.response.data.messages
      return res
    } else return false
  } catch (err) {
    return false
  }
}
export const getErrorMessage = (err: any) => {
  let message = errorMessage(err)
  if (err.response) {
    if (err.response.status === 401) {
      return message ? message : 'Session Expired'
    } else if (err.response.status === 404) {
      return message ? message : 'No data found'
    } else if (err.response.status === 400) return message ? message : 'Bad request'
    else if (err.response.status === 500) {
      return message ? message : 'Internal error'
    }
  } else {
    return 'Something Wrong !'
  }
}

export const checkForAgedData = (statusUpdated?: boolean, lastModifiedDate?: string) => {
  if (
    statusUpdated !== undefined &&
    lastModifiedDate !== undefined &&
    !statusUpdated &&
    lastModifiedDate !== ''
  ) {
    let date = moment(lastModifiedDate).format(dateFormats.dateTime)

    return showWarningToast({
      title: 'Data ageing information',
      message: (
        <>
          <div>{`This report is using data extracted on`}</div>
          <div>{`${date}`}</div>
        </>
      ),
    })
  }
}
