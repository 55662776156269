import {FC} from 'react'
import {Link} from 'react-router-dom'
import {LinkProps} from '../../models/type'

type IProps = {
  color?: 'dark' | 'white' | 'primary' | 'success' | 'info' | 'warning' | 'gray' | any
  className?: string
  fontSize?: 'fs-1' | 'fs-2' | 'fs-3' | 'fs-4' | 'fs-5' | 'fs-6' | 'fs-7'
}
const H1Title: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return <div className={`${className ? className : ''} heading1 text-${color}`}>{children}</div>
}

const H2Title: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return <div className={`${className ? className : ''} heading2 text-${color}`}>{children}</div>
}

const H3Title: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return <div className={`${className ? className : ''} heading3 text-${color} `}>{children}</div>
}

const H4Title: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return <div className={`${className ? className : ''} heading4 text-${color} `}>{children}</div>
}
const H5Title: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return <div className={`${className ? className : ''} heading5 text-${color}`}>{children}</div>
}
const H6Title: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return (
    <div className={`${className ? className : ''} fw-bold text-${color} fs-6`}>{children}</div>
  )
}
const H7Title: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return (
    <div className={`${className ? className : ''} fw-bold text-${color} fs-7`}>{children}</div>
  )
}

const ErrorText: FC<IProps> = (props) => {
  const {children, className, color = 'danger', fontSize = 'fs-2'} = props
  return (
    <div className={`${className ? className : ''} fw-boldest text-${color} ${fontSize}`}>
      {children}
    </div>
  )
}
const ExtraLargeTitle: FC<IProps> = (props) => {
  const {children, className, color = 'dark'} = props
  return (
    <div
      className={`${className ? className : ''} fw-boldest text-${color}`}
      style={{fontSize: '4rem'}}
    >
      {children}
    </div>
  )
}

const CustomLink: FC<LinkProps> = (props) => {
  const {link, linkTitle, children, isExternal, linkTarget = '_self', isDisabled} = props
  if (isExternal) {
    return (
      <a
        href={link}
        target={linkTarget}
        title={linkTitle}
        className={`${isDisabled ? 'cursor-noClick' : ''}`}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        to={link}
        title={linkTitle}
        target={linkTarget}
        className={`${isDisabled ? 'cursor-noClick' : ''}`}
      >
        {children}
      </Link>
    )
  }
}
export {
  H1Title,
  H2Title,
  H3Title,
  H4Title,
  H5Title,
  H6Title,
  H7Title,
  ErrorText,
  ExtraLargeTitle,
  CustomLink,
}
