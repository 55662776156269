import React from 'react'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MenuItem, MenuItemExternalLink} from './MenuItem'
import AccountMenus from './AccountMenus'
import {IconPaths} from '../../../../app/modules/dashboard/common-utilities/ui/icons/iconPaths'
const RightHeader = () => {
  return (
    <>
      <div className='header-menu align-items-stretch rightHeaderMenu'>
        <div
          className='menu menu-lg-rounded menu-column menu-lg-row  fw-bold my-5 my-lg-0 align-items-stretch'
          id='#kt_header_menu'
          data-kt-menu='true'
        >
          <MenuItem
            title=''
            to={`/notifications`}
            badgeCount={3}
            icon={IconPaths.notificationIcon}
            mainClass='d-lg-block  d-xs-none mx-0  py-5 link-bottom-primary'
            iconTitle='Notifications'
          />
          <MenuItemExternalLink
            title=''
            to={`${process.env.REACT_APP_REDMAP_URL}/Help/Index`}
            icon={IconPaths.faq}
            mainClass='d-lg-block  d-xs-none  py-5 link-bottom-primary'
            iconTitle='Help'
          />
          <MenuInnerWithSub
            title='Account'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={false}
            icon={IconPaths.profile}
            menuPlacement='bottom-end'
            menuTrigger={`click`}
            iconSize='svg-icon-2hx'
            mainClass='py-5 link-bottom-primary'
          >
            <AccountMenus />
          </MenuInnerWithSub>
        </div>
      </div>{' '}
    </>
  )
}

export default RightHeader
