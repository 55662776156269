import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Grid} from '@mui/material'
import MuiCustomSelect from '../components/MuiCustomSelect'
import HeaderHoc from '../../../common-utilities/ui/HeaderHoc'
import {level2FiltersProps} from '../components/data-schema/FilterContext'
import {useAsyncDebounce} from 'react-table'
import CustomMultiSelect from '../components/CustomMultiSelect'
import {useAgedPayableSlice} from '../../../common-utilities/hooks/storeData'
import {useDispatch} from 'react-redux'
import {
  setDetailedViewProp,
  setLevel2Filters,
  setSearchTextLevel2,
} from '../../../../redux/AgedPayableSlice'
import SearchBox from '../../../common-utilities/ui/SearchBox'

const Header = () => {
  const {
    level2Filters,

    filterValues,
    detailedViewProp,

    reportData,
  } = useAgedPayableSlice()
  const dispatch = useDispatch()
  const [groupValues, setGroupValues] = useState<string[]>([])
  const [selectedValues, setSelectedValues] = useState<string[]>(
    detailedViewProp ? detailedViewProp : []
  )
  const handleSearch = useAsyncDebounce((value: string) => {
    dispatch(setSearchTextLevel2(value))
  }, 700)
  const [filters, setFilters] = useState<level2FiltersProps>(level2Filters)
  const submitFilter = () => {
    dispatch(setLevel2Filters(filters))
    dispatch(setDetailedViewProp(selectedValues))
  }
  useEffect(() => {
    if (reportData) {
      const repoData = reportData.agedPayableDataView
      let data = repoData.map((ele: any) => ele[filterValues.groupBy.toLocaleLowerCase()])
      setGroupValues(data)
    }

    return () => {
      setSelectedValues([])
      setGroupValues([])
    }
  }, [filterValues.groupBy])
  const isChanged = (): boolean => {
    if (JSON.stringify(selectedValues) !== JSON.stringify(detailedViewProp)) return true
    else if (JSON.stringify(level2Filters.hiddenColumns) !== JSON.stringify(filters.hiddenColumns))
      return true
    else if (JSON.stringify(level2Filters.columns) !== JSON.stringify(filters.columns)) return true
    else return false
  }
  return (
    <HeaderHoc
      innerItems={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <CustomMultiSelect
                items={groupValues}
                onChange={(values) => {
                  setSelectedValues(values)
                }}
                selectedData={selectedValues}
                label={`Select ${filterValues.groupBy}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MuiCustomSelect
                items={filters.columns.filter((ele) => ele !== filterValues.groupBy)}
                onFinish={(hid, order) => {
                  setFilters({
                    ...filters,
                    columns: order,
                    hiddenColumns: hid,
                  })
                }}
                selectedData={filters.columns.filter(
                  (ele) => !filters.hiddenColumns.includes(ele) && ele !== filterValues.groupBy
                )}
                uniqueProp='FieldName'
              />
            </Grid>
          </Grid>
        </>
      }
      outerItems={
        <Grid container xs={12} md={6} xl={6}>
          <Grid item>
            <div className='d-flex align-items-center position-relative my-1 px-3 '>
              <span className='svg-icon svg-icon-1 position-absolute ms-4'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2 svg-icon-primary'
                />
              </span>
              <SearchBox
                handleSearch={(value: string) => {
                  handleSearch(value)
                }}
                value={undefined}
                placeHolder='Refine list by supplier, approver, warehouse or entity'
              />
            </div>
          </Grid>
        </Grid>
      }
      handleUpdate={submitFilter}
      disableSubmit={selectedValues.length === 0 || !isChanged()}
    />
  )
}

export {Header}
