import React from 'react'
import {IWidgetModel} from './models/type'

import GridReportPanel from './widgets/grid-report'
import WFChartPanel from './widgets/wf-charts'
type IProps = {
  item: IWidgetModel
}

const GridContainer = React.forwardRef((props: IProps, ref) => {
  const {className, style, width, height, children, ...rest}: any = props
  const item: IWidgetModel = props.item

  return (
    <>
      {item !== undefined && item.plugin.toLowerCase() === 'gridreport' && (
        <GridReportPanel contentId={item.widgetId} ref={ref} {...props} />
      )}
      {item !== undefined && item.plugin.toLowerCase() === 'wfcharts' && (
        <WFChartPanel contentId={item.widgetId} ref={ref} {...props} />
      )}
    </>
  )
})

export default GridContainer
