/* eslint-disable */
import React, {useEffect, useState} from 'react'

import {Column} from 'react-table'
import {H3Title, H4Title} from '../../../common-utilities/ui/Headings'
import {ExcelColumns} from '../components/level-1'
import DataTable from '../../tables/DataTable'

import moment from 'moment'
import {USDollar} from '../../../common-utilities/sorting/SortingMethods'
import BasicPopover from './PDFPop'
import {useAgedPayableSlice} from '../../../common-utilities/hooks/storeData'
import {AgedPayableFieldsTypes} from '../../../models/type'
import {serverPDFUrls} from '../../../common-utilities/ui/Standards'
// import VirtualTable from '../../tables/VirtualTable'
const REDMAP_URL = process.env.REACT_APP_REDMAP_URL
const Level2DataTable = () => {
  const {
    detailedViewProp,

    filterValues,
    searchTextLevel2,
    level2Filters,

    reportData,
  } = useAgedPayableSlice()

  const [data, setData] = useState<any[]>([])
  const [columns, setColumns] = useState<Column[]>([])
  const [exportColumns, setExportColumns] = useState<ExcelColumns[]>([])
  const [amount, setAmount] = useState<number>(0.0)
  const [showAll, setShowAll] = useState<boolean>(false)
  const currentDate = moment()
  useEffect(() => {
    if (detailedViewProp && reportData) {
      const temp = reportData.agedPayableDataView.filter((ele: any) =>
        detailedViewProp.includes(ele[filterValues.groupBy.toLowerCase()])
      )

      let totalRecords = reportData.agedPayableDataView.length
      const mainData = temp.reduce((subRows: any[], row: any) => [...subRows, ...row.subRows], [])
      if (mainData && mainData.length > 0) {
        // @ts-ignore
        const totalAmount = mainData.reduce((sum: number, row: any) => row['totalIncTax'] + sum, 0)

        let groupedColumn: Column = {
          Header: () => <span>{filterValues.groupBy}</span>,
          accessor: filterValues.groupBy.toLowerCase(),
          id: filterValues.groupBy,
          Cell: ({row}: any) => {
            //console.log('groupedCell', row.original)
            return (
              <span className='align-items-center'>{`${
                row.original ? row.original[filterValues.groupBy.toLowerCase()] : ''
              }`}</span>
            )
          },

          width: 120,
          maxWidth: 150,
          minWidth: 100,
        }
        let expToggleCol: ExcelColumns[] = []
        let tempColumns = reportData.drillViewToggleColumns.filter(
          (ele) =>
            level2Filters.columns.includes(ele.fieldCaption) &&
            ele.fieldCaption !== filterValues.groupBy
        )
        level2Filters.columns.forEach((ele) => {
          let temp = tempColumns.find((item: AgedPayableFieldsTypes) => item.fieldCaption === ele)
          if (temp && !level2Filters.hiddenColumns.includes(temp.fieldCaption)) {
            expToggleCol.push({
              FieldName: temp.fieldName,
              FieldType: temp.fieldType,
              isCurrency: false,
              totalRequired: false,
              caption: temp.fieldCaption,
            })
          }
        })
        let selectableColumns: Column[] = tempColumns.map((ele) => {
          return {
            Header: () => <span>{`${ele.fieldCaption}`}</span>,
            accessor: ele.fieldName,
            id: ele.fieldCaption,
            Cell: ({row}: any) => {
              let value =
                row.original && row.original[ele.fieldName] ? row.original[ele.fieldName] : '-'
              if (ele.fieldName.toLocaleLowerCase() === 'status' && value !== '')
                return <span className='border-warning  roundBorderChips'>{value}</span>
              else if (ele.fieldType === 'datetime' && value !== '') {
                let date = moment(value)
                return (
                  <span className='d-flex justify-content-start'>
                    {value === '-' ? '' : date.format('DD MMM YYYY')}
                  </span>
                )
              } else
                return (
                  <span
                    className={`d-flex ${
                      ele.fieldType === 'number' || ele.fieldType === 'double'
                        ? 'justify-content-end'
                        : 'justify-content-start'
                    }`}
                  >{`${value}`}</span>
                )
            },
            Footer: (info: any) => {
              return (
                <div style={{textAlign: 'end'}}>
                  <H4Title className='py-3'>{`-`}</H4Title>

                  <H4Title className='py-3'>{`-`}</H4Title>
                </div>
              )
            },
            width: 120,
            maxWidth: 150,
            minWidth: 100,
          }
        })

        let itemColumn: Column = {
          Header: () => <span>{`Itemid`}</span>,
          accessor: 'itemid',
          Cell: ({row}: any) => {
            if (row.isGrouped) {
              return (
                <a
                  title='Search to R5'
                  href={`${REDMAP_URL}/Search/Main?lib=${
                    filterValues.library
                  }&grp=AccountsPayable&fldn=${filterValues.groupBy.toLowerCase()}&fldv=${
                    row.groupByVal
                  }`}
                  target='_blank'
                >
                  <span className='linkedTitle link1-semibold'>{row.groupByVal}</span>
                </a>
              )
            } else
              return (
                <div className='d-flex align-items-center text-nowrap'>
                  <BasicPopover itemId={row.original['itemid']} library={filterValues.library} />
                  <a
                    title='Search to R5'
                    href={`${REDMAP_URL}/Search/Main?lib=${filterValues.library}&grp=AccountsPayable&fldn=itemid&fldv=${row.original['itemid']}`}
                    target='_blank'
                  >{`${row.original ? row.original['itemid'] : '-'}`}</a>
                </div>
              )
          },
          Footer: (info: any) => {
            return (
              <div>
                <H4Title className='py-3'>{`Total`}</H4Title>

                <H4Title className='py-3'>{`Percentage`}</H4Title>
              </div>
            )
          },
          width: 150,
          maxWidth: 150,
          minWidth: 100,
        }
        let currencyColumns: Column[] = reportData?.currencyColumns.map((ele, index) => {
          const {fieldCaption, fieldName, fieldType} = ele
          expToggleCol.push({
            FieldName: fieldName,
            FieldType: 'number',
            isCurrency: true,
            totalRequired: true,
            caption: fieldCaption,
          })
          return {
            Header: () => {
              return <span>{fieldCaption}</span>
            },

            accessor: fieldName,

            Cell: ({row}: any) => {
              // console.log('currencyCell', row)
              return (
                <div
                  className={`currencyCell ${
                    fieldName === 'older' || fieldName === 'totalIncTax' ? 'fs-3 fw-boldest' : ''
                  } ${fieldName === 'older' ? 'text-primary' : ''}`}
                  style={{textAlign: 'end'}}
                >{`${
                  row.values[fieldName] !== null
                    ? USDollar.format(row.values[fieldName].toFixed(2))
                    : '$ 0.00'
                }`}</div>
              )
            },

            Footer: ({rows}: any) => {
              // Only calculate total visits if rows change
              //console.log('info', info)

              let total: any = 0.0
              if (totalRecords !== detailedViewProp.length) {
                rows.forEach((element: any) => {
                  total =
                    total +
                    element.subRows.reduce(
                      (sum: number, row: any) => row.values[fieldName] + sum,
                      0
                    )
                })
              } else
                total = React.useMemo(
                  () => rows.reduce((sum: number, row: any) => row.values[fieldName] + sum, 0),
                  [rows]
                )

              let p = (total / totalAmount) * 100
              return (
                <div className='text-nowrap' style={{textAlign: 'end'}}>
                  <H4Title className='py-3'>{`${USDollar.format(total.toFixed(2))}`}</H4Title>

                  <H4Title className='py-3'>{`${p.toFixed(2)}%`}</H4Title>
                </div>
              )
            },
            width: 120,
            maxWidth: 150,
            minWidth: 100,
          }
        })
        const exports: ExcelColumns[] = [
          {
            FieldName: 'itemid',
            FieldType: 'number',
            isCurrency: false,
            totalRequired: false,
            caption: 'Itemid',
          },
          {
            FieldName: filterValues.groupBy.toLowerCase(),
            FieldType: 'string',
            isCurrency: false,
            totalRequired: false,
            caption: filterValues.groupBy,
          },
        ]
        setData(mainData)
        setColumns([groupedColumn, itemColumn, ...selectableColumns, ...currencyColumns])
        setExportColumns([...exports, ...expToggleCol])
        setAmount(totalAmount)
        setShowAll(reportData.agedPayableDataView.length === detailedViewProp.length)
      }
    }
  }, [level2Filters, detailedViewProp])

  const getColumnOrder = () => {
    return showAll
      ? [
          'itemid',
          filterValues.groupBy,
          ...level2Filters.columns.filter((ele) => ele !== filterValues.groupBy),
        ]
      : ['itemid', ...level2Filters.columns.filter((ele) => ele !== filterValues.groupBy)]
  }
  const getHiddenCol = () => {
    return showAll
      ? [...level2Filters.hiddenColumns]
      : [filterValues.groupBy, ...level2Filters.hiddenColumns]
  }
  const getGroupBy = () => {
    return showAll ? undefined : [filterValues.groupBy]
  }
  const getExpanded = () => {
    if (detailedViewProp) {
      let groupField = filterValues.groupBy
      let val: any = {}
      detailedViewProp.forEach((ele) => {
        let key = `${groupField}:${ele}`
        val[key] = true
      })

      return showAll
        ? undefined
        : {
            '0': {
              groupField: true,
            },
            ...val,
          }
    } else return undefined
  }

  return (
    <>
      {/* <Header ApplyFilters={applyFilters} selectedColumns={[]} toggleColumns={[]} /> */}

      <div className='d-flex flex-column py-2 mb-8'>
        <H3Title>{`Showing ${detailedViewProp?.length} ${filterValues.groupBy}`}</H3Title>
        <div className='d-flex flex-wrap py-4 text-gray-700'>
          {detailedViewProp?.length === 1 && (
            <>
              <span className='fs-3 fw-bold'>{` ${filterValues.groupBy},`}</span>
              &nbsp;&nbsp;
              {detailedViewProp && (
                <span className='fs-3 fw-boldest'>{` ${detailedViewProp?.toString()},`}</span>
              )}
            </>
          )}{' '}
          &nbsp;&nbsp;
          <span className='fs-3 fw-bold'>{` Ageing by`}</span>&nbsp;&nbsp;
          <span className='fs-3 fw-boldest'>{` ${filterValues.ageingBy}, `}</span>&nbsp;&nbsp;
          <span className='fs-3 fw-bold'>{` as of ${currentDate.format('DD MMM YYYY')}`}</span>
        </div>
      </div>

      {columns.length > 0 && (
        <>
          <DataTable
            columns={columns}
            data={data}
            reportConfig={{
              columnOrder: getColumnOrder(),
              hiddenColumns: getHiddenCol(),
              defaultSortBy: [{id: filterValues.groupBy}],
              defaultGroupBy: getGroupBy(),
              defaultExpand: getExpanded(),
            }}
            globalSearch={searchTextLevel2}
            footerOnLast={true}
            isFooterRow={true}
            exportConfig={{
              columns: exportColumns,
              data: data,
              totalForPerRow: amount,
              fileName: `${filterValues.library}_${filterValues.interval}_${
                filterValues.ageingPeriod
              }_${currentDate.format('DD-MM-YYYY')}`,
              excelExtraConfig: {
                freezCol: 2,
              },
              pdfLandscape: false,
              serverPdf: {
                url: serverPDFUrls.agedPayableSummaryDetail,
                body: {
                  ageingGroupBy: filterValues.groupBy,
                  library: filterValues.library,
                  ageingPeriod: filterValues.ageingPeriod,
                  ageingBy: 'Invoice date' ? 'InvoiceDate' : 'DueDate',
                  columnConfig: level2Filters.columns
                    .filter(
                      (ele) =>
                        ele !== filterValues.groupBy && !level2Filters.hiddenColumns.includes(ele)
                    )
                    .toString(),
                  selectedDetailViewName: detailedViewProp?.toString(),
                  agiengInterval: filterValues.interval,
                },
              },
            }}
            tableClass='fixed-table-layout dividedFooter'
          />
        </>
      )}
    </>
  )
}

export default Level2DataTable
