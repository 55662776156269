import React, {useState, useEffect} from 'react'

import moment from '@date-io/moment'
import TextField from '@mui/material/TextField'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker'
import {Dropdown} from 'react-bootstrap'
import {IDateRange} from '../../models/type'
type IProps = {
  data: IDateRange | undefined
  onRangeChange: (result: IDateRange) => void

  dateField?: string
}
const DateRange = ({data, onRangeChange, dateField}: IProps) => {
  // const {selected} = useListView()
  const date = new moment()
  const [dateRange, setDateRange] = useState<IDateRange>({Type: 'All', End: '', Start: ''})
  useEffect(() => {
    if (data !== undefined) {
      // console.log('dateRange', data)
      if (data.Type === 'Custom') setDateRange({...data})
      else setDateRange(getDateRange(data.Type))
    }
  }, [data])
  const getDateRange = (type: string) => {
    let today = date.date()!.format('YYYY-MM-DD')
    let weekFirst = date.startOfWeek(new moment().date()!)!.format('YYYY-MM-DD')
    let weekLast = date.endOfWeek(new moment().date()!)!.format('YYYY-MM-DD')
    let monthFirst = date.startOfMonth(new moment().date()!)!.format('YYYY-MM-DD')
    let monthLast = date.endOfMonth(new moment().date()!)!.format('YYYY-MM-DD')
    if (type === 'Today') {
      return {Type: type, End: today, Start: today} as IDateRange
    } else if (type === 'This Week')
      return {Type: type, End: weekLast, Start: weekFirst} as IDateRange
    else if (type === 'This Month')
      return {Type: type, End: monthLast, Start: monthFirst} as IDateRange
    else if (type === 'All') return {Type: type, End: today, Start: ''} as IDateRange
    else return {Type: 'All', End: '', Start: ''} as IDateRange
  }
  const handleTypeChange = (type: string) => {
    if (type === 'Custom')
      setDateRange((pre) => {
        return {...pre, Type: type}
      })
    else {
      let res = getDateRange(type)
      setDateRange(res)
      onRangeChange(res)
    }
  }
  const customDateChange = (field: string, value: string) => {
    let val = new Date(value).toISOString().slice(0, 10)
    if (field === 'Start') {
      setDateRange({
        ...dateRange,
        Start: val,
      })
      dateRange.End !== undefined &&
        dateRange.End !== '' &&
        onRangeChange({
          ...dateRange,
          Start: val,
        })
    } else {
      setDateRange({
        ...dateRange,
        End: val,
      })
      dateRange.Start !== undefined &&
        dateRange.Start !== '' &&
        onRangeChange({
          ...dateRange,
          End: val,
        })
    }
  }

  const CustomToggle = React.forwardRef(
    ({children, onClick}: {children: any; onClick: any}, ref) => (
      <a
        href=''
        className='btn btn-sm btn-light btn-active-light-primary'
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
          if (data) {
            if (data.Type === 'Custom') setDateRange({...data})
            else setDateRange(getDateRange(data.Type))
          }
        }}
      >
        {children}
      </a>
    )
  )

  return (
    <>
      {dateField && dateField !== '' && (
        <div>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
              {dateRange.Type === 'All'
                ? `Filter by ${dateField} (All till) -  ${dateRange.End}`
                : `Filter by ${dateField} ${dateRange.Start} - ${dateRange.End}`}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{position: dateRange.Type === 'Custom' ? 'relative' : 'absolute'}}
            >
              <div className='d-flex'>
                <div className='d-flex flex-column'>
                  {['Today', 'This Week', 'This Month', 'All', 'Custom'].map((ele, index) => {
                    return (
                      <>
                        <Dropdown.Item
                          key={ele}
                          active={dateRange.Type === ele}
                          onClick={(e) => {
                            ele === 'Custom' && e.stopPropagation()
                            handleTypeChange(ele)
                          }}
                        >
                          {ele}
                        </Dropdown.Item>
                      </>
                    )
                  })}
                </div>
                {dateRange.Type === 'Custom' && (
                  <div className='row'>
                    <div className='d-flex flex-stack'>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDatePicker
                            displayStaticWrapperAs='desktop'
                            openTo='day'
                            value={dateRange.Start}
                            inputFormat='YYYY-MM-DD'
                            onChange={(newValue) => {
                              newValue !== null && customDateChange('Start', newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDatePicker
                            displayStaticWrapperAs='desktop'
                            openTo='day'
                            inputFormat='YYYY-MM-DD'
                            value={dateRange.End}
                            onChange={(newValue) => {
                              newValue !== null && customDateChange('End', newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {(dateField === undefined || dateField === '') && <span>No Date Field Selected</span>}
    </>
  )
}

export {DateRange}
