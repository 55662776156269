import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../setup/redux/RootReducer'
import {IDashboardTemplateSlice, IDashboardTemplate} from '../dashboard/models/type'

const initialState: IDashboardTemplateSlice = {
  templateList: undefined,
  actionTemplate: {
    action: '',
    id: 0,
    templateConfig: {
      id: 0,
      dashboardName: '',
      status: true,
      role: '',
      description: '',
      defaultCards: '',
      widgetTemplatesId: '',
    },
  },
  currentViewTemplate: {id: 0},
  isLoading: false,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const dashboardTemplateSlice = createSlice({
  name: 'dashboardTemplates',
  initialState: initialState,
  reducers: {
    startCall: (state: IDashboardTemplateSlice, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    resetConfig: (state: IDashboardTemplateSlice, action: PayloadAction<boolean>) => {
      state.actionTemplate = {
        action: '',
        id: 0,
        templateConfig: {
          id: 0,
          dashboardName: '',
          status: true,
          role: '',
          description: '',
          defaultCards: '',
          widgetTemplatesId: '',
        },
      }
    },
    setTemplateList: (
      state: IDashboardTemplateSlice,
      action: PayloadAction<Array<IDashboardTemplate> | undefined>
    ) => {
      if (action.payload) state.templateList = [...action.payload]
      else state.templateList = undefined
    },
    setActionTemplate: (
      state: IDashboardTemplateSlice,
      action: PayloadAction<{id: number; addOrEdit: 'Add' | 'Edit'}>
    ) => {
      const {id, addOrEdit} = action.payload

      if (addOrEdit === 'Edit') {
        const data = state.templateList?.find((ele) => ele.id === id)
        if (data !== undefined) {
          state.actionTemplate = {
            action: addOrEdit,
            id: id,
            templateConfig: {...data},
          }
        }
      } else {
        state.actionTemplate = {
          action: addOrEdit,
          id: id,
          templateConfig: {
            id: id,
            dashboardName: '',
            status: true,
            role: '',
            description: '',
            defaultCards: '',
            widgetTemplatesId: '',
          },
        }
      }
    },
    updateTemplateConfig: (
      state: IDashboardTemplateSlice,
      action: PayloadAction<{id: number; data: Partial<IDashboardTemplate>}>
    ) => {
      const {id, data} = action.payload
      //const old = state.actionTemplate.templateConfig
      if (id === state.actionTemplate.id && data !== undefined) {
        // state.actionTemplate = {
        //   ...state.actionTemplate,
        //   templateConfig: {...old, data},
        // }
      }
    },
  },
})

export const {startCall, resetConfig, updateTemplateConfig, setTemplateList, setActionTemplate} =
  dashboardTemplateSlice.actions

export const dashboardTemplates = (state: RootState) => state.dashboardTemplates

export const dashboardTemplateList = (state: RootState) => state.dashboardTemplates.templateList

export default dashboardTemplateSlice.reducer
