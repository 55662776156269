import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFilters,
  Column,
  useColumnOrder,
  useGroupBy,
  useExpanded,
  SortingRule,
  ColumnInstance,
  useGlobalFilter,
} from 'react-table'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'
import {DefaultColumnFilter} from './ColumnFilters'
import ColumnGroupBy from './ColumnGroupBy'
import MuiCustomSelect from '../detail-view/components/MuiCustomSelect'
import ExportHoc, {IPropsExports} from '../detail-view/components/excel-export/ExportHoc'
import VirtualRows from './VirtualRows'
import _ from 'lodash'
import HorizontalScrollControllers from './HorizontalScrollControllers'

export type ExtraConfigType = {
  order?: 'asc' | 'desc'
  fieldType?: 'string' | 'number'
  sort?: string
  hiddenColumns?: string[]
  isChildren?: boolean
  childColumns?: Column[]
  childCheckPropertyName?: string
  selectionRequired?: boolean
  path?: string
  defaultGroupBy?: string[]
  defaultSortBy?: Array<SortingRule<{}>>
  columnOrder?: string[]
  allowGroupBy?: boolean
  allowReorder?: boolean
  columnsToToggle?: string[]
  defaultFilters?: {id: string; value: any}[]
  defaultExpand?: any
}

export type ReactTableProps = {
  columns: Column[]
  data: any
  reportConfig?: ExtraConfigType
  isFooterRow?: boolean
  deleteSelected?: (values: any) => void
  exportSelected?: (values: any) => void
  getHeaderProps?: (column: ColumnInstance) => {
    className?: string
    style?: React.CSSProperties
  }
  tableClass?: string
  globalSearch?: string
  reference?: React.LegacyRef<HTMLTableElement>
  headerRowTitle?: JSX.Element
  footerOnLast?: boolean
  exportConfig?: IPropsExports
  horizontalScrollRequired?: boolean
}
const VirtualTable = ({
  columns,
  data,
  reportConfig,
  isFooterRow,

  getHeaderProps,
  tableClass = '',
  globalSearch = '',

  headerRowTitle,
  footerOnLast = false,
  exportConfig,
  horizontalScrollRequired,
}: ReactTableProps) => {
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(25)

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      disableFilters: true,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setSortBy,
    allColumns,
    setColumnOrder,
    setGroupBy,
    visibleColumns,
    setHiddenColumns,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: reportConfig && reportConfig.defaultSortBy ? reportConfig.defaultSortBy : [],

        hiddenColumns:
          reportConfig !== undefined && reportConfig.hiddenColumns !== undefined
            ? reportConfig.hiddenColumns
            : [],
        columnOrder: reportConfig && reportConfig.columnOrder ? reportConfig.columnOrder : [],
        groupBy: reportConfig && reportConfig.defaultGroupBy ? reportConfig.defaultGroupBy : [],
        expanded: reportConfig && reportConfig.defaultExpand ? reportConfig.defaultExpand : {},
      },
      defaultColumn,
    },
    useFilters,
    (hooks) => {
      return reportConfig && reportConfig.selectionRequired
        ? hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: 'All',
              Cell: ({row}) => (
                <input
                  type='checkbox'
                  className='form-check-input'
                  {...row.getToggleRowSelectedProps()}
                />
              ),
              disableSortBy: true,
            },
            ...columns,
          ])
        : [...columns]
    },
    useColumnOrder,
    useGlobalFilter,
    useGroupBy,
    useSortBy,

    useExpanded,
    useRowSelect
  )
  useMemo(() => {
    setGlobalFilter(globalSearch)
  }, [globalSearch])
  const {groupBy, sortBy} = state

  const tableRef = useRef<any>(null)

  const debouncedScrollHandler = _.debounce((table, endIndex, setEndIndex, rows) => {
    if (table) {
      const {scrollTop, clientHeight, scrollHeight} = table

      if (scrollHeight - scrollTop <= clientHeight * 1.2 && endIndex < rows.length) {
        let ind = endIndex + 25
        setEndIndex(ind > rows.length ? rows.length : ind)
      }
    }
  }, 200)

  const handleScroll = useCallback(() => {
    // Call the stable debounced function with the required arguments
    debouncedScrollHandler(tableRef.current, endIndex, setEndIndex, rows)
  }, [endIndex, rows])
  useEffect(() => {
    const table = tableRef.current
    if (table) {
      table.addEventListener('scroll', handleScroll)
    }

    if (rows.length === 0 && globalSearch !== '') setEndIndex(0)
    else if (rows.length !== 0 && rows.length <= 25) {
      setEndIndex(rows.length)
    } else if (rows.length > 25 && endIndex < 25) setEndIndex(25)

    return () => {
      if (table) {
        table.removeEventListener('scroll', handleScroll)
      }
    }
  }, [tableRef, rows, handleScroll])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleResize = () => {
    if (tableRef.current) {
      const rowHeight = tableRef.current.querySelector('tr')?.offsetHeight || 0
      const containerHeight = window.innerHeight * 0.8 // 80vh
      const tableHeight = Math.max(20 * rowHeight, containerHeight - 400)
      tableRef.current.style.maxHeight = `${tableHeight}px`
    }
  }
  //for getting total count & matching data sorting in export files with ui
  const {sortedData, datalength} = useMemo(() => {
    let tempsort = rows.filter((item) => item.original).map((ele) => ele.original)

    return {sortedData: tempsort, datalength: rows.length}
  }, [rows])

  const getHeaderClass = (column: ColumnInstance) => {
    const {id} = column

    let col = exportConfig?.columns.find(
      (ele) =>
        ele.FieldName.toLowerCase() === id.toLowerCase() ||
        ele.caption?.toLowerCase() === id.toLowerCase()
    )
    if (col) {
      let {FieldType, FieldName} = col
      switch (true) {
        case FieldType === 'string' ||
          FieldType === 'datetime' ||
          FieldName.toLowerCase() === 'itemid':
          return {className: 'justify-content-start text-nowrap'}
        case FieldType === 'number' || FieldType === 'double':
          return {className: 'justify-content-end text-nowrap '}

        default:
          return {className: 'justify-content-center text-nowrap'}
      }
    } else return {className: 'justify-content-center text-nowrap'}
  }
  return (
    <>
      <div className='d-flex align-items-center'>
        {reportConfig?.allowReorder && (
          <MuiCustomSelect
            items={
              reportConfig?.columnsToToggle
                ? reportConfig?.columnsToToggle
                : allColumns.map((ele) => ele.id)
            }
            onFinish={(hiddCol, colOrder) => {
              setColumnOrder(colOrder)
              setHiddenColumns(hiddCol)
            }}
            selectedData={visibleColumns.map((ele) => ele.id)}
            uniqueProp=''
          />
        )}
        {reportConfig?.allowGroupBy && (
          <ColumnGroupBy columns={visibleColumns} setGroupBy={setGroupBy} groupBy={groupBy} />
        )}
      </div>
      <div className='d-flex gap-3 align-items-center'>
        {exportConfig && sortedData && (
          <ExportHoc
            {...exportConfig}
            serverPdfExtraProps={{
              isOrderByDescending: sortBy[0]?.desc ? true : false,
              sortingOrderColumn: sortBy[0]?.id,
            }}
            data={sortedData}
          />
        )}
        {horizontalScrollRequired && <HorizontalScrollControllers tableRef={tableRef} />}
      </div>

      <div
        className='virtualTableContainer'
        style={{maxHeight: `500px`, overflowY: 'auto'}}
        ref={tableRef}
        id={`DataTable_${data.length}`}
      >
        <table
          className={`table align-middle  table-row-bordered  fs-6 gy-5 dataTable no-footer ${tableClass}`}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((group) => (
              <tr
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 mainTableHeaderRow'
                {...group.getHeaderGroupProps()}
              >
                {group.headers.map((column) => {
                  let data = getHeaderProps ? getHeaderProps(column) : getHeaderClass(column)

                  return (
                    <th {...column.getHeaderProps()}>
                      <span
                        className={`headContent  text-truncate ${
                          data.className ? data.className : ''
                        }`}
                        data-toggle='tooltip'
                        title={column.id}
                      >
                        {column.render('Header')}

                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                        {!column.disableSortBy && (
                          <span className='d-flex flex-column mx-2 sortIcon'>
                            <KTSVG
                              path={IconPaths.ascIcon}
                              className={`${
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? 'svg-icon-gray-400'
                                    : 'svg-icon-primary'
                                  : 'svg-icon-gray-400'
                              }`}
                              onClick={() => {
                                setSortBy([{id: column.id}])
                                column.toggleSortBy(false)
                              }}
                            />

                            <KTSVG
                              path={IconPaths.descIcon}
                              className={`${
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? 'svg-icon-primary'
                                    : 'svg-icon-gray-400'
                                  : 'svg-icon-gray-400'
                              }`}
                              onClick={() => {
                                setSortBy([{id: column.id, desc: true}])
                                column.toggleSortBy(true)
                              }}
                            />
                          </span>
                        )}
                        {/* {column.canFilter && (
                          <ColumnFilterPanel
                            column={column}
                            setSortBy={setSortBy}
                            setGroupBy={setGroupBy}
                            groupBy={groupBy}
                          >
                            {column.render('Filter')}
                          </ColumnFilterPanel>
                        )} */}
                      </span>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{maxHeight: `400px`, overflowY: 'auto'}}>
            {headerRowTitle && (
              <tr>
                <td>{headerRowTitle}</td>
                <td colSpan={columns.length - 1}></td>
              </tr>
            )}
            <VirtualRows
              startIndex={startIndex}
              endIndex={endIndex}
              prepareRow={prepareRow}
              rows={rows}
            />
          </tbody>

          {isFooterRow && (
            <tfoot
              className={`${footerOnLast ? '' : 'stickyFooter'}`}
              style={{background: '#ffffff'}}
            >
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      <div className='d-flex align-items-center mt-5'>
        <div className='body1-medium me-5'>{`Showing ${endIndex} of ${datalength} results`}</div>
        <div
          className='progress w-sm-100 w-md-150px'
          style={{height: '1.3rem', background: '#DBDBDB'}}
        >
          <div
            className='progress-bar bg-success h-2'
            role='progressbar'
            style={{width: `${(endIndex / datalength) * 100}%`}}
            aria-valuenow={(endIndex / datalength) * 100}
            aria-valuemin={25}
            aria-valuemax={datalength}
          ></div>
        </div>
      </div>
    </>
  )
}

export default VirtualTable
