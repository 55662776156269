import * as React from 'react'
import {ColumnInstance} from 'react-table'
import {MuiSelectField} from '../../common-utilities/ui'

type IProps = {
  columns: ColumnInstance[]

  [x: string]: any
}
export default function ColumnGroupBy({columns, ...rest}: IProps) {
  const {setGroupBy, groupBy} = rest

  return (
    <>
      <MuiSelectField
        changedValues={(value: string) => setGroupBy([value])}
        errorText=''
        hasError={false}
        id='tableFilter'
        isRequired={false}
        items={columns.map((ele) => ele.id)}
        val={groupBy[0]}
      />
    </>
  )
}
