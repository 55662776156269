import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {IUserCards} from '../../models/type'
import {getDefaultCards} from '../../../redux/cards/api'
import {showErrorToast} from '../../common-utilities/alerts/AlertModels'
import WidgetCard from './WidgetCard'
import {sortNumbers} from '../../common-utilities/sorting/SortingMethods'
import {setUserCards} from '../../../redux/DashboardSlice'
import {H3Title} from '../../common-utilities/ui/Headings'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'

const CardPanel = () => {
  const dispatch = useDispatch()
  const [cards, setCards] = useState<IUserCards[]>([])

  useEffect(() => {
    loadUserCards()
  }, [])

  const loadUserCards = async () => {
    getDefaultCards()
      .then((res) => {
        let temp = sortNumbers(res.data, 'asc', 'cardTypeId')
        temp && setCards(temp)
        dispatch(setUserCards(res.data))
      })
      .catch((err) => {
        showErrorToast({message: 'Failed to load user cards'})
      })
  }

  return (
    <>
      {cards.length > 0 && (
        <>
          <div className='d-flex flex-stack my-4 d-none'>
            <H3Title color={'gray-700'}>Invoices</H3Title>
            <KTSVG
              path={IconPaths.configWheelNew}
              className='svg-icon-3x svg-icon-primary'
              alt='card config'
              title='Card configuration'
            />
          </div>
          <div className='widget-card-container mt-5'>
            {cards.slice(0, 5).map((ele, index) => (
              <div className='widgetCard_item'>
                <WidgetCard data={ele} index={index} key={`cards_${index}`} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default CardPanel
