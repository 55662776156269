/* eslint-disable react-hooks/exhaustive-deps */

import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
// import {Header} from './Header'
import {BreadcrumbNew, ModuleTitle} from './page-title/DefaultTitle'
import RightHeader from './RightHeader'
import {MenuItem} from './MenuItem'
import {IconPaths} from '../../../../app/modules/dashboard/common-utilities/ui/icons/iconPaths'

// import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {attributes} = useLayout()

  return (
    <div
      className='d-flex flex-column'
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '250px'}"
      {...attributes.headerMenu}
      style={{position: 'sticky', top: 0, zIndex: 100}}
    >
      <div id='main-Header' className=' d-flex flex-stack px-8  bg-primary'>
        {/*Header logo search box */}

        <div className='d-flex align-items-center d-xl-none  ms-n3 me-1' title='Show aside menu'>
          <div className='mx-3 menu-btn cursor-pointer' id='kt_aside_mobile_toggle'>
            <KTSVG path={IconPaths.hmburger} className='svg-icon-white svg-icon-3hx' />
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='header-logo me-5 me-md-10 py-5 flex-grow-1 flex-lg-grow-0'>
            <Link to='/'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/redmap-logo-white.svg')}
                className='logo-default h-lg-40px h-md-30px h-sm-25px'
              />
            </Link>
          </div>

          {/* <SearchPanel /> */}
        </div>

        <div>
          <RightHeader />
        </div>

        {/*Header menus */}
      </div>
      <div className='d-flex flex-stack d-lg-none d-md-none bg-primary'>
        <div id='header-title'>
          <ModuleTitle />
        </div>
        <div className='d-flex'>
          <MenuItem
            title=''
            to={`/notifications`}
            iconTitle='Notifications'
            badgeCount={3}
            icon={IconPaths.notificationIcon}
          />
          <MenuItem
            title=''
            iconTitle='Help'
            to={`${process.env.REACT_APP_REDMAP_URL}/Help/Index`}
            icon={IconPaths.faq}
          />
        </div>
      </div>
      <div
        id='sub-Header'
        className='bg-white align-items-center  px-8 border-gray-300 border-bottom d-flex'
      >
        {/* <ModuleBreadcrumb /> */}
        <div id='header-title' className='d-lg-block d-md-block d-sm-none d-xs-none py-3'>
          <ModuleTitle />
        </div>{' '}
        <BreadcrumbNew />
      </div>
    </div>
  )
}
