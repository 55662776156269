/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'demo@redmap.com',
  password: 'imFFzrJ$623!3$D',
}

export function LoginNew() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        //debugger;
        login(values.email, values.password)
          .then(({data: {access_token}}) => {
            setLoading(false)
            dispatch(auth.actions.login(access_token))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })
  return (
    <>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10'>
        {/* begin::Logo */}

        {/* end::Logo */}
        {/* begin::Wrapper */}

        <div className='w-lg-500px bg-white p-10 p-lg-15 mx-auto'>
          <div className='fw-bolder fs-2 pb-5'>Account Login</div>
          <label className='mb-10 text-gray-700  fw-bolder fs-6'>
            Enter your username and password to login. Labels with marked with an asterisk [ * ] are
            required.
          </label>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {formik.status && (
              <div className='mb-10'>
                <Alert variant='filled' severity='error'>
                  {formik.status}
                </Alert>
              </div>
            )}
            <div className='fv-row mb-10'>
              <TextField
                fullWidth
                required
                error={formik.errors.email ? true : false}
                id={formik.errors.email ? `outlined-error-helper-text` : `outlined-required`}
                label='Username'
                placeholder='Enter email or username'
                InputLabelProps={{style: {fontSize: 16}}}
                InputProps={{style: {fontSize: 15}}}
                FormHelperTextProps={{style: {fontSize: '1rem'}}}
                {...formik.getFieldProps('email')}
                type='search'
                name='email'
                helperText={formik.errors.email}
              />
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Link
                  to='/auth/forgot-password'
                  className='link-primary fs-6 fw-bolder'
                  style={{marginLeft: '5px'}}
                >
                  Forgot Password ?
                </Link>
              </Box>
              <TextField
                fullWidth
                required
                error={formik.errors.password ? true : false}
                id={formik.errors.password ? `outlined-error-helper-text` : `outlined-required`}
                label='Password'
                placeholder='Enter password'
                InputLabelProps={{style: {fontSize: 16}}}
                InputProps={{style: {fontSize: 15}}}
                {...formik.getFieldProps('password')}
                type='password'
                FormHelperTextProps={{style: {fontSize: '1rem'}}}
                helperText={formik.errors.password}
              />
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className=''>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Login</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* begin::Separator */}

            {/* end::Separator */}

            {/* begin::Google link */}
            <div className='d-flex flex-column py-5 bg-white'>
              <div className='fw-bolder fs-3'>Other Accounts</div>
              <div className='text-muted mb-5'>
                Or login using your <span className='fw-bolder text-gray-800'>Google</span> or
                <span className='fw-bolder text-gray-800'> Microsoft </span>
                account
              </div>
              <div className='d-flex flex-stack'>
                <a
                  href='#'
                  className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 text-hover-primary'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-20px me-3'
                  />
                  <span>Google Account</span>
                </a>
              </div>
              <div className='d-flex flex-stack'>
                <a
                  href='#'
                  className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 text-hover-primary'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/microsoft-5.svg')}
                    className='h-20px me-3'
                  />
                  <span>Microsoft Account</span>
                </a>
              </div>
            </div>
            <div>
              <a href='#'>Contact Redmap if you don't have an account.</a>
            </div>
            {/* end::Action */}
          </form>
        </div>
        {/* end::Wrapper   src\_metronic\assets\sass\components\_variables.custom.scss*/}
      </div>
    </>
  )
}
