import React, {useState, useEffect} from 'react'
import {IDateRange, IWidgetModel} from '../../models/type'
import * as widgetAPIS from '../../../redux/widgets/api'
import * as widgetActions from '../../../redux/DashboardSlice'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'

import ChartContainer from './ChartContainer'
import {DateRange} from '../../common-utilities/ui/DateRange'

import {
  deleteWArning,
  getErrorMessage,
  showErrorToast,
  showSuccessToast,
} from '../../common-utilities/alerts/AlertModels'
import {ContentLoading} from '../../common-utilities/loading/ContentLoading'
import {KTSVG} from '../../../../../_metronic/helpers'
import WidgetSidePanel from './WidgetSidePanel'
import {updateDateRange} from '../../../redux/configurations/api'
import {RootButtons} from '../../common-utilities'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'
// import DateRangeNew from '../../common-utilities/ui/DateRangeNew'
type IProps = {
  contentId: number
}

const WFChartPanel = React.forwardRef((props: IProps, ref) => {
  const {className, style, width, height, children, contentId, ...rest}: any = props

  const widthTemp = parseInt(style.width, 10)
  const heightTemp = parseInt(style.height, 10) - 90

  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [DRSaving, setDRSaving] = useState<boolean>(false)
  const [hasError, setHasError] = useState<string>('')
  const [togglePanel, setTogglePanel] = useState<boolean>(false)
  const widgetConfig: IWidgetModel = useSelector<RootState>(
    ({dashboard}) => dashboard.widgets.find((item: IWidgetModel) => item.widgetId === contentId),
    shallowEqual
  ) as IWidgetModel
  useEffect(() => {
    loadWidgetData()
  }, [widgetConfig])

  const loadWidgetData = () => {
    setLoading(true)
    setHasError('')
    widgetAPIS
      .getWidgetDataById(contentId, 1, 100)
      .then((response) => {
        setLoading(false)
        dispatch(widgetActions.widgetDataFetched({widgetId: contentId, content: response.data}))
      })
      .catch((err) => {
        setLoading(false)
        setHasError(getErrorMessage(err))
      })
  }

  const dateRangeChange = async (data: IDateRange) => {
    setDRSaving(true)

    updateDateRange(data, contentId)
      .then((res) => {
        setDRSaving(false)
        let result = res.data
        dispatch(
          widgetActions.updateWidgetList({...result, gridConfig: JSON.parse(result.userConfig)})
        )
      })
      .catch((err) => {
        setDRSaving(false)
        showErrorToast({message: getErrorMessage(err)})
      })
  }
  const deleteReport = async () => {
    let res = await deleteWArning()
    if (res) {
      setLoading(true)
      widgetAPIS
        .deleteWidget(contentId)
        .then((res) => {
          if (res.data) {
            setLoading(false)
            dispatch(widgetActions.removeWidget(contentId))
            showSuccessToast({message: 'Report deleted'})
          }
        })
        .catch((err) => {
          setLoading(false)
          showErrorToast({message: getErrorMessage(err)})
        })
    }
  }
  return (
    <div
      ref={ref}
      key={contentId}
      className={`card card-flush shadow-sm grid-item ${className}`}
      style={style}
      {...rest}
    >
      <div className='card-header'>
        <h3 className='grid-item__title card-drag-area'>{widgetConfig.widgetName}</h3>
        <div className='card-toolbar'>
          {DRSaving ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <DateRange
              data={widgetConfig.gridConfig?.Report?.DateRange}
              onRangeChange={dateRangeChange}
              dateField={widgetConfig.gridConfig?.Report?.Chart?.DateField}
            />
          )}
          <button
            type='button'
            className='btn mx-2   btn-icon btn-bg-light btn-light-primary  btn-sm'
            onClick={() =>
              dispatch(
                widgetActions.SetCurrentEditingWidget({widgetId: contentId, type: 'WFCharts'})
              )
            }
            title='Configuration'
            id='kt_config_toggle'
          >
            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2x' />
          </button>
          <RootButtons.IconButton
            iconPath={IconPaths.delete}
            onClick={deleteReport}
            title={`Delete report`}
            iconClass='svg-icon-2x svg-icon-danger'
            iconColor='danger'
          />
        </div>
      </div>
      <div className='card-body grid-item__graph p-0'>
        <button
          type='button'
          className='btn mx-2   btn-icon btn-bg-light btn-light-primary  btn-sm toggle-panel-btn'
          onClick={() => setTogglePanel(!togglePanel)}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-2x' />
        </button>
        {togglePanel && <WidgetSidePanel closePanel={setTogglePanel} widgetId={contentId} />}
        {!loading && hasError !== '' && <>{hasError}</>}
        {!loading && hasError === '' && (
          <ChartContainer
            name={widgetConfig.widgetName}
            widgetConfig={widgetConfig.gridConfig.Report.Chart}
            contentId={widgetConfig.widgetId}
            height={heightTemp}
            width={widthTemp}
          />
        )}
        {loading && <ContentLoading />}
      </div>
      {children}
    </div>
  )
})

export default WFChartPanel
