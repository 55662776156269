import React from 'react'
import {getWidgetsInitialData} from '../../models/reportsInitialData'
import {IChartGrid, IGrid, IImportConfig, IPlugins, IWidgetModel} from '../../models/type'

export const defaultWidgetData: IWidgetModel = getWidgetsInitialData({plugin: 'WFCharts'})

export type IConfigurationContext = {
  widgetData: IWidgetModel | any
  configData: IGrid | IImportConfig | IChartGrid | any
  setConfigData: React.Dispatch<React.SetStateAction<IGrid | IImportConfig | IChartGrid | any>>
  isFormDirty: boolean
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>
  setWidgetData: React.Dispatch<React.SetStateAction<IWidgetModel>>
  widgetType: IPlugins | string
  configError: boolean
  setConfigError: React.Dispatch<React.SetStateAction<boolean>>
}
const initialContextData: IConfigurationContext = {
  configData: {},
  isFormDirty: false,
  setConfigData: () => {},
  setIsFormDirty: () => {},
  widgetData: {},
  setWidgetData: () => {},
  widgetType: '',
  configError: false,
  setConfigError: () => {},
}

export const validateChartConfig = (data: IChartGrid) => {
  if (data.ChartType === '') return true
  else if (data.Table === '' || data.CategoryField === '') return true
  else if (
    data.ExecuteQuery &&
    data.ExecuteQuery.toLowerCase() === 'true' &&
    data.SeriesQuery === ''
  )
    return true
  else if (data.AllowClickThrough.toLowerCase() === 'yes' && data.ClickThroughQuery === '')
    return true
  else return false
}
export const ConfigurationContext = React.createContext<IConfigurationContext>(initialContextData)
