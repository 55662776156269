import {lazy} from 'react'
import {MenuDataTypes} from '../modules/dashboard/models/type'
const ConfigFormWrapper = lazy(
  () => import('../modules/dashboard/utilities/import/components/ConfigFormWrapper')
)
const GridView = lazy(() => import('../modules/dashboard/utilities/import/components/GridView'))
const BatchUpdateForm = lazy(
  () => import('../modules/dashboard/utilities/batch-update/components/BatchUpdateForm')
)
const BatchUpdateView = lazy(
  () => import('../modules/dashboard/utilities/batch-update/components/BatchUpdateView')
)

const AuditReport = lazy(() => import('../modules/dashboard/common-utilities/audit/AuditReport'))
const PublishReportPanel = lazy(
  () => import('../modules/dashboard/common-utilities/publish-report/PublishReportPanel')
)
const EditableGridForm = lazy(
  () => import('../modules/dashboard/utilities/editable-grid/components/EditableGridForm')
)
const EditableGridView = lazy(
  () => import('../modules/dashboard/utilities/editable-grid/components/EditableGridView')
)
const BatchUpdatePanel = lazy(() => import('../modules/dashboard/utilities/batch-update'))
const ImportPanel = lazy(() => import('../modules/dashboard/utilities/import'))
const EditableGridPanel = lazy(() => import('../modules/dashboard/utilities/editable-grid'))

type IPlugins = 'editableGrid' | 'batchUpdate' | 'imports'
const obj = {
  editableGrid: {
    form: EditableGridForm,
    view: EditableGridView,
  },
  batchUpdate: {
    form: BatchUpdateForm,
    view: BatchUpdateView,
  },
  imports: {
    form: ConfigFormWrapper,
    view: GridView,
  },
}
const getCommonRoutes = (type: IPlugins): MenuDataTypes[] => {
  return [
    {
      linkPath: '/:action/:id',
      routePath: '/:action/:id',
      component: obj[type].form,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: true,
      key: `${type}Form`,
      isLazy: true,
      title: 'Configuration Form',
      icon: '',
      role: [],
      disabled: false,
      isMenuLink: false,
    },
    {
      linkPath: '/:id',
      routePath: '/:id',
      component: obj[type].view,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: true,
      key: `${type}View`,
      isLazy: true,
      title: `${type} view`,
      icon: '',
      role: [],
      disabled: false,
      isMenuLink: false,
    },
  ]
}
const getPublishRoute = (type: IPlugins): MenuDataTypes => {
  return {
    linkPath: '/publishReport/:id',
    routePath: '/publishReport/:id',
    component: PublishReportPanel,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: `${type}_publishReport`,
    isLazy: true,
    title: 'Publish report',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: false,
  }
}
const getAuditRoute = (type: IPlugins): MenuDataTypes => {
  return {
    linkPath: '/auditReport/:id',
    routePath: '/auditReport/:id',
    component: AuditReport,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: `${type}_auditReport`,
    isLazy: true,
    title: 'Audit report',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: false,
  }
}

export const administrationRoutes: MenuDataTypes[] = [
  {
    linkPath: '/editable-grids',
    routePath: '/editable-grids',
    component: EditableGridPanel,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'editable-grids',
    isLazy: true,
    title: 'Editable grids',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: false,
    subMenu: [
      ...getCommonRoutes('editableGrid'),
      getPublishRoute('editableGrid'),
      getAuditRoute('editableGrid'),
    ],
  },
  {
    linkPath: '/batch-updates',
    routePath: '/batch-updates',
    component: BatchUpdatePanel,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'batch-updates',
    isLazy: true,
    title: 'Batch updates',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: false,
    subMenu: [
      ...getCommonRoutes('batchUpdate'),
      getPublishRoute('batchUpdate'),
      getAuditRoute('batchUpdate'),
    ],
  },
  {
    linkPath: '/imports',
    routePath: '/imports',
    component: ImportPanel,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'imports',
    isLazy: true,
    title: 'Imports',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: false,
    subMenu: [...getCommonRoutes('imports'), getPublishRoute('imports')],
  },
]
