import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../setup/redux/RootReducer'
import {
  Dashboard,
  IWidgetModel,
  IGridConfig,
  ICoordinates,
  IwidgetDataModel,
  IDashboardTemplate,
  DashboardConfiguration,
  IUserCards,
  IPlugins,
} from '../dashboard/models/type'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {resetAllSlices} from './resetActions'
const initialDashboardState: Dashboard = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  widgets: [],
  allWidgets: [],
  isFiltered: false,
  isDraggable: true,
  isResizable: true,
  configurations: {
    libraries: [],
    tables: [],
    fields: [],
    dateFields: [],
    users: [],
    userGroupNames: [],
    tableFields: {},
    viewTables: [],
  },
  widgetsData: [],
  showCards: false,
  currentRole: 'CEO',
  asideMenu: true,
  asideCollapsed: false,
  dashboardConfig: {} as IDashboardTemplate,
  reportCounts: {
    BatchUpdate: 0,
    EditableGridReport: 0,
    ExportUtility: 0,
    GridReport: 0,
    ImportUtility: 0,
    KPIReport: 0,
    WFCharts: 0,
  },
  userCards: [],
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    catchError: (state: Dashboard, action: PayloadAction<Dashboard>) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state: Dashboard, action: PayloadAction<Dashboard>) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    widgetFetched: (state: Dashboard, action: PayloadAction<IWidgetModel[]>) => {
      state.listLoading = false
      state.actionsLoading = false

      const widgets: IWidgetModel[] = []
      const failedWidgets: IWidgetModel[] = []
      for (let widget of action.payload) {
        try {
          widget.gridConfig = widget.userConfig
            ? (JSON.parse(widget.userConfig) as IGridConfig)
            : ({} as IGridConfig)
          widget.coordinates = JSON.parse(widget.pfStream) as ICoordinates
          widgets.push(widget)
        } catch (e) {
          failedWidgets.push(widget)
        }
      }
      console.log('failedWidgets', failedWidgets)
      let counts = widgets.reduce((r: any, c) => {
        r[c['plugin']] = (r[c['plugin']] || 0) + 1
        return r
      }, {})
      console.log(counts)
      state.widgets = action.payload
      state.allWidgets = action.payload
      state.reportCounts = Object.assign(state.reportCounts, counts)
    },
    widgetDataFetched: (state: Dashboard, action: PayloadAction<IwidgetDataModel>) => {
      state.actionsLoading = false
      let tempId = action.payload.widgetId
      let index = state.widgetsData.findIndex((item: IwidgetDataModel) => item.widgetId === tempId)
      if (index > -1) {
        state.widgetsData[index] = action.payload
      } else {
        state.widgetsData.push(action.payload)
      }
    },
    updateWidgetList: (state: Dashboard, action: PayloadAction<IWidgetModel>) => {
      state.actionsLoading = false
      let dataToStore = action.payload
      let index = state.widgets.findIndex((item) => item.widgetId === dataToStore.widgetId)
      if (index > -1) {
        state.widgets[index] = {...dataToStore}
        if (state.allWidgets !== undefined) state.allWidgets[index] = {...dataToStore}
      } else {
        state.widgets.push({...dataToStore})
        state.allWidgets?.push({...dataToStore})
      }
    },

    widgetConfiguration: (state: Dashboard, action: PayloadAction<IWidgetModel>) => {
      state.actionsLoading = false

      let configData = action.payload
      let allData = state.containerList as IWidgetModel[]
      allData.forEach((item) => {
        if (item.widgetId === configData.widgetId) {
          item.gridConfig = configData.userConfig
            ? (JSON.parse(configData.userConfig) as IGridConfig)
            : ({} as IGridConfig)
          item.userConfig = configData.userConfig
        }
      })
      //state.containerList = allData
    },
    updateWidgetShortConfig: (state: Dashboard, action: PayloadAction<IWidgetModel>) => {
      state.actionsLoading = false

      let configData = action.payload
      let allData = state.containerList as IWidgetModel[]
      let i = allData.findIndex((item) => item.widgetId === configData.widgetId)
      if (i > -1) {
        allData[i].docGroup = configData.docGroup
        allData[i].library = configData.library
        allData[i].widgetName = configData.widgetName
      }

      //state.containerList = allData
    },
    wFChartConfiguration: (state: Dashboard, action: PayloadAction<IWidgetModel>) => {
      // state.actionsLoading = false
      // state.error = null
      // let finalData = action.payload
      // //let gridConfig = finalData.gridConfig
      // let index = state.containerList.findIndex((item: any) => item.widgetId === finalData.widgetId)
      // state.containerList[index] = finalData
    },
    setCurrentRole: (state: Dashboard, action: PayloadAction<Dashboard>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.widgets = []
      state.allWidgets = []
      state.currentRole = action.payload.currentRole
    },
    setDashboardConfig: (state: Dashboard, action: PayloadAction<IDashboardTemplate>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.dashboardConfig = {...action.payload}
    },
    toggleCollapsableMenu: (state: Dashboard, action: PayloadAction<boolean>) => {
      state.asideCollapsed = action.payload
    },
    toggleAsideMenu: (state: Dashboard, action: PayloadAction<boolean>) => {
      state.asideMenu = action.payload
    },
    setDashboardConfiguration: (
      state: Dashboard,
      action: PayloadAction<Partial<DashboardConfiguration>>
    ) => {
      state.actionsLoading = false
      if (state.configurations !== null && action.payload !== undefined) {
        const temp: DashboardConfiguration = Object.assign(state.configurations, action.payload)
        //debugger
        state.configurations = temp
      }
    },
    removeWidget: (state: Dashboard, action: PayloadAction<number>) => {
      const id = action.payload
      if (id) {
        state.widgets = state.widgets.filter((ele) => ele.widgetId !== id)
        if (state.allWidgets)
          state.allWidgets = state.allWidgets.filter((ele) => ele.widgetId !== id)
      }
    },
    SetCurrentEditingWidget: (
      state: Dashboard,
      action: PayloadAction<{widgetId?: number; type?: IPlugins} | undefined>
    ) => {
      if (action.payload) {
        state.currentEditingWidget = action.payload.widgetId
        state.currentWidgetType = action.payload.type
      } else {
        state.currentEditingWidget = undefined
        state.currentWidgetType = undefined
      }
    },
    setUserCards: (state: Dashboard, action: PayloadAction<IUserCards[]>) => {
      state.userCards = action.payload
    },
    updatePFStream: (state: Dashboard, action: PayloadAction<ICoordinates[]>) => {
      let data = action.payload

      if (data.length > 0) {
        const array2Map = new Map(data.map((item) => [parseInt(item.id), item]))

        const updatedArray1 = state.widgets.map((item1) => ({
          ...item1,
          pfStream: JSON.stringify(array2Map.get(item1.widgetId)) || item1.pfStream,
        }))
        state.widgets = updatedArray1
        state.allWidgets = updatedArray1
      }
    },
    setDefaultLibrary: (state: Dashboard, action: PayloadAction<string>) => {
      state.defaultLibrary = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAllSlices, () => initialDashboardState)
  },
})
export const r6DashboardReducer = persistReducer(
  {storage, key: 'r6Dashboard', whitelist: ['defaultLibrary']},
  dashboardSlice.reducer
)
export const {
  catchError,
  startCall,
  widgetFetched,
  widgetDataFetched,
  widgetConfiguration,
  updateWidgetShortConfig,
  wFChartConfiguration,
  setCurrentRole,
  setDashboardConfig,
  toggleCollapsableMenu,
  toggleAsideMenu,
  updateWidgetList,
  setDashboardConfiguration,
  removeWidget,
  SetCurrentEditingWidget,
  setUserCards,
  updatePFStream,
  setDefaultLibrary,
} = dashboardSlice.actions

export const widgetList = (state: RootState) => state.dashboard.widgets

export default dashboardSlice.reducer
