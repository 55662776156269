import React, {FC, ReactNode, useState} from 'react'
import {IAgedPayableSummaryData} from '../../../../models/type'
import {getAgedPayableSummaryData} from '../../../../../redux/kpi/api'
import {useQuery} from 'react-query'
import {getErrorMessage, showErrorToast} from '../../../../common-utilities/alerts/AlertModels'
export type FiltersContextProps = {
  filterValues: FiltersProps
  setFilterValues: React.Dispatch<React.SetStateAction<FiltersProps>>
  libraries: string[]
  detailedViewProp?: string[]
  setDetailedViewProp?: React.Dispatch<React.SetStateAction<string[]>>
  searchTextLevel1?: string
  setSearchTextLevel1?: React.Dispatch<React.SetStateAction<string>>
  searchTextLevel2?: string
  setSearchTextLevel2?: React.Dispatch<React.SetStateAction<string>>
  level2Filters: level2FiltersProps
  setLevel2Filters: React.Dispatch<React.SetStateAction<level2FiltersProps>>
  loading: boolean
  reportData?: IAgedPayableSummaryData
  isError?: boolean
  error?: any
}
export type IAgedPayableSlice = {
  filterValues: FiltersProps
  searchTextLevel1: string
  searchTextLevel2: string
  libraries: string[]
  detailedViewProp: string[]

  level2Filters: level2FiltersProps

  loading: boolean
  reportData?: IAgedPayableSummaryData
  isError?: boolean
  error?: any
}

// const getLibrary = () => {
//   const storeData = store.getState().dashboard
//   console.log('lib', storeData.defaultLibrary)
//   return storeData.defaultLibrary ? storeData.defaultLibrary : ''
// }
export const GROUPBY_ITEMS = ['Supplier', 'Approver', 'Entity', 'Warehouse']
export const AGEINGBY_ITEMS = ['Due date', 'Invoice date']
export const AGEING_PERIODS = ['Week', 'Day', 'Month']
// type IAgeingPeriods = 'Week' | 'Day' | 'Month'
// type IAgeingBy = 'Due date' | 'Invoice date'
// type IGroupBy = 'Supplier' | 'Approver' | 'Entity' | 'Warehouse'
export type FiltersProps = {
  library: string
  interval: number
  ageingPeriod: string
  ageingBy: string
  groupBy: string
  hiddenColumns: string[]
  columns: string[]
}

export const initialFiltersValues: FiltersProps = {
  library: '',
  ageingBy: 'Invoice date',
  ageingPeriod: 'Day',
  groupBy: 'Supplier',
  interval: 7,
  columns: ['Entity', 'Warehouse', 'Approver', 'Quantity'],
  hiddenColumns: [],
}
export const initialLevel2Values: level2FiltersProps = {
  columns: [
    'Supplier',
    'Entity',
    'Approver',
    'Status',
    'Warehouse',
    'Invoice number',
    'Invoice date',
    'Due date',
    'Purchase Order number',
    'Project code',
    'Shipment number',
    'Currency rate',
    'Filing date',
  ],
  hiddenColumns: [],
}
export const FiltersContext = React.createContext<FiltersContextProps>({
  filterValues: initialFiltersValues,
  setFilterValues: () => {},
  libraries: [],
  level2Filters: initialLevel2Values,
  setLevel2Filters: () => {},
  loading: false,
})

type IProps = {
  children: ReactNode
  reportId: number
  libraries: string[]
  defaultLibrary: string
}
export type level2FiltersProps = {
  columns: string[]
  hiddenColumns: string[]
  filters?: any[]
}

export const FilterProvider: FC<IProps> = ({
  reportId,
  children,
  libraries,
  defaultLibrary,
}: IProps) => {
  const [filters, setFilters] = useState<FiltersProps>({
    ...initialFiltersValues,
    library: defaultLibrary,
  })
  const [detailedViewProp, setDetailedViewProp] = useState<string[]>([])
  const [searchTextLevel1, setSearchTextLevel1] = useState<string>('')
  const [level2Filters, setLevel2Filters] = useState<level2FiltersProps>({
    ...initialLevel2Values,
  })

  const [searchTextLevel2, setSearchTextLevel2] = useState<string>('')

  const {data, isFetching, isError, error} = useQuery(
    `${filters.library}_${filters.interval}_${filters.ageingPeriod}_${filters.ageingBy}_${filters.groupBy}`,
    () => {
      return getAgedPayableSummaryData(filters)
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (err) => {
        showErrorToast({message: getErrorMessage(err)})
        return getErrorMessage(err)
      },
    }
  )

  return (
    <FiltersContext.Provider
      value={{
        filterValues: filters,
        setFilterValues: setFilters,
        libraries,
        detailedViewProp,
        setDetailedViewProp,
        searchTextLevel1,
        setSearchTextLevel1,
        level2Filters,
        setLevel2Filters,
        searchTextLevel2,
        setSearchTextLevel2,
        loading: isFetching,
        reportData: data?.data,
        isError,
        error,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
