import axios from 'axios'
import {IOldRuleConfig, IWhereClause} from '../../dashboard/models/oldRuleType'
import {
  IRules,
  IRuleSaveData,
  IValidatorResponse,
  IWidgetTables,
  IJoins,
  IGroupBy,
  IOrderBy,
  IActionQueryResponse,
  IRuleGroupList,
  IRuleGrpDefination,
  IRuleGroupPostData,
  ICloneRequestData,
  IRevisionResponseData,
} from '../../dashboard/models/type'
import {
  LIBRARY_CONFIG_URL,
  LIBRARY_RULES_URL,
  LIBRARY_RULE_GROUP_URL,
  LIBRARY_SQL_URL,
} from '../EndPoints'

export function getViewTables(libraryname: string) {
  return axios.get<IWidgetTables[]>(
    `${LIBRARY_CONFIG_URL}/GetTables?withViews=true&libraryname=${libraryname}`
  )
}

export function getAllRules(docGroupId: number, libraryname: string) {
  return axios.get<IRules[]>(`${LIBRARY_RULES_URL}/GetAll/${docGroupId}/${libraryname}`)
}

export function validateQuery(query: string, library: string) {
  return axios.get<boolean>(`${LIBRARY_RULES_URL}/SqlValidate/${query}/${library}`)
}

export function getRuleConfig(ruleId: number, docGroupId: number, libraryname: string) {
  return axios.get<IOldRuleConfig>(
    `${LIBRARY_RULES_URL}/GetbyId/${ruleId}/${docGroupId}/${libraryname}`
  )
}
export function validateSQLQuery(query: string, library: string, isExecute: boolean) {
  return axios.post<IValidatorResponse>(`${LIBRARY_SQL_URL}/SqlQueryExecuted`, {
    sql: query,
    libraryName: library,
    isExecute: isExecute,
  })
}

export function saveRuleConfig(data: IRuleSaveData) {
  return axios.post<IOldRuleConfig>(`${LIBRARY_RULES_URL}`, data)
}

export function generateActionQuery(itemid: number = 0, ruleId: number, library: string) {
  return axios.get<IActionQueryResponse>(
    `${LIBRARY_SQL_URL}/SqlGenerate/${itemid}/${ruleId}/${library}`
  )
}
export function UpdateRuleStatus(
  ruleId: number,
  docGroupId: number,
  library: string,
  status: 'Draft' | 'Published',
  comment: string
) {
  return axios.get<boolean>(
    `${LIBRARY_RULES_URL}/UpdateRuleStatus/${ruleId}/${docGroupId}/${library}/${status}?Comments=${comment}`
  )
}

export function getSelectQuery(data: {
  whereClause: IWhereClause[]
  sourceTable: string
  joinClause: IJoins[]
  groupBy: IGroupBy[]
  orderBy: IOrderBy[]
}) {
  return axios.post<{sql: string}>(`${LIBRARY_SQL_URL}/GenerateSQLQuery`, data)
}
export function getAllRuleGroups(docGrpId: number, library: string) {
  return axios.get<IRuleGroupList[]>(`${LIBRARY_RULE_GROUP_URL}/${docGrpId}/${library}`)
}
export function getRuleGroupDefination(grpId: number, library: string) {
  return axios.get<IRuleGrpDefination[]>(
    `${LIBRARY_RULE_GROUP_URL}/GetRuleGroupDefinition/${grpId}/${library}`
  )
}

export function saveRuleGroup(data: IRuleGroupPostData) {
  return axios.post<IRuleGroupList[]>(`${LIBRARY_RULE_GROUP_URL}`, data)
}
export function cloneRule(data: ICloneRequestData) {
  return axios.post<IRules[]>(`${LIBRARY_RULES_URL}/Clone`, data)
}
export function getRuleRevisions(id: number, library: string) {
  return axios.get<IRevisionResponseData[]>(`${LIBRARY_RULES_URL}/GetRuleHistory/${id}/${library}`)
}

export function exportAudit(id: number, library: string) {
  return axios.post<boolean>(`${LIBRARY_RULES_URL}/ExportAudit/${id}/${library}`)
}

export function getRuleGroupsByRuleId(ruleId: number, library: string) {
  return axios.get<string[]>(
    `${LIBRARY_RULE_GROUP_URL}/GetRuleGroupNamebyRuleId/${ruleId}/${library}`
  )
}

export function deleteRule(ruleId: number, library: string, comment: string) {
  return axios.delete<boolean>(
    `${LIBRARY_RULES_URL}?id=${ruleId}&libraryName=${library}&comments=${comment}`
  )
}
