let RoleWiseDashboard = [
  {
    id: 1,
    role: 'CEO',
    dashboardName: 'CEO Dashboard',
    status: true,
    defaultCards: JSON.stringify([1, 2, 3, 4]),
    defaultKpiList: JSON.stringify([562]),
    widgetTemplatesId: JSON.stringify([]),
    description: '',
  },

  {
    id: 2,
    role: 'CFO',
    dashboardName: 'CFO Dashboard',
    status: true,
    defaultCards: JSON.stringify([1, 2, 3, 4]),
    defaultKpiList: JSON.stringify([562]),
    widgetTemplatesId: JSON.stringify([]),
    description: '',
  },
]

export {RoleWiseDashboard}
