import axios from 'axios'
import {PRODUCTIVITY_URL} from '../EndPoints'
import {
  ProductivityConfigData,
  ProductivityDetailResponse,
} from '../../dashboard/widgets/productivity-report/ProductivityReportPanel'

export function getProductivitySnapshotData({
  libraryName,
  period,
}: {
  libraryName: string
  period: string
}) {
  return axios.get<ProductivityConfigData>(
    `${PRODUCTIVITY_URL}/BySnapshot?UserTimeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&LibraryName=${libraryName}&Period=${period}`
  )
}

export function getProductivityDetailData({
  libraryName,
  period,
  category,
}: {
  libraryName?: string
  period: string
  category?: string
}) {
  return axios.get<ProductivityDetailResponse>(
    `${PRODUCTIVITY_URL}/ByDetails?Category=${category}&UserTimeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&LibraryName=${libraryName}&Period=${period}`
  )
}
