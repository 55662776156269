//Widget
const WIDGET_API_URL = process.env.REACT_APP_API_BASE_URL
export const KPI_URL = `${WIDGET_API_URL}/KPIReport`
export const WIDGET_URL = `${WIDGET_API_URL}/widget`
export const WIDGET_CONFIG_URL = `${WIDGET_API_URL}/Configuration`
export const PUBLISH_WIDGET_CONFIG_URL = `${WIDGET_API_URL}/PublishedWidget`
export const BATCH_UPDATE_URL = `${WIDGET_API_URL}/BatchUpdate`
export const IMPORT_URL = `${WIDGET_API_URL}/ImportUtility`
export const EDITABLE_GRID_URL = `${WIDGET_API_URL}/EditableGridReport`
export const CARDS_URL = `${WIDGET_API_URL}/Cards`
export const TEMPLATE_URL = `${WIDGET_API_URL}/DashboardTemplate`
//Library
const LIBRARY_URL = process.env.REACT_APP_API_LIBRARY_URL
export const LIBRARY_CONFIG_URL = `${LIBRARY_URL}/Configurations`
export const LIBRARY_RULES_URL = `${LIBRARY_URL}/Rules`
export const LIBRARY_SQL_URL = `${LIBRARY_URL}/SQL`
export const LIBRARY_RULE_GROUP_URL = `${LIBRARY_URL}/RuleGroups`
export const INVOICE_PDF = `${LIBRARY_URL}/Documents`
export const SOLUTION_URL = `${LIBRARY_URL}/Solution`

//Metrics
const METRICS_URL = process.env.REACT_APP_API_METRICS_URL
export const AGEDPAYABLE_URL = `${METRICS_URL}/AgedPayable`
export const CARD_COUNT_URL = `${METRICS_URL}/Card`
export const PRODUCTIVITY_URL = `${METRICS_URL}/Productivity`
