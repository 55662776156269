import {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
import {USDollar, formatNumber} from '../sorting/SortingMethods'
import {IDonutChartConfig} from '../../models/chart'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
type IProps = {
  config: IDonutChartConfig
}

const AmDonutChart = ({config}: IProps) => {
  const {
    data,
    categoryField,
    valueField,
    centerLabel,
    startAngle,
    endAngle,
    isSemiDonut,
    height,
    containerId,
    seriesColors,
  } = config
  useLayoutEffect(() => {
    let root = am5.Root.new(containerId)
    root._logo?.dispose()
    let responsive = am5themes_Responsive.newEmpty(root)

    //responsive settings
    responsive.addRule({
      relevant: am5themes_Responsive.widthM,
      applying: function () {
        chart.set('radius', am5.percent(50))
        series.set('alignLabels', true)
        series.labels.template.set('fontSize', '0.8rem')

        label.set(
          'html',
          `<div class="d-flex flex-column justify-content-center align-items-center"><span class="fs-5">Total :</span>
          <div class="d-flex flex-stack align-items-center justify-content-center text-danger">
          <span class="fs-4 fw-bold text-gray-400 me-1 align-self-start">$</span>
          <span class="heading4">${
            centerLabel?.isAmount ? formatNumber.format(centerLabel.value) : centerLabel?.value
          }</span></div></div>`
        )
      },
      removing: function () {
        chart.set('radius', am5.percent(60))
        series.set('alignLabels', true)
        series.labels.template.set('fontSize', '1.2rem')
        label.set(
          'html',
          `<div class="d-flex flex-column justify-content-center align-items-center"><span class="fs-4">Total :</span>
          <div class="d-flex flex-stack align-items-center justify-content-center text-danger">
          <span class="fs-3 fw-bold text-gray-400 me-1 align-self-start">$</span>
          <span class="heading3">${
            centerLabel?.isAmount ? formatNumber.format(centerLabel.value) : centerLabel?.value
          }</span></div></div>`
        )
      },
    })

    //adding themes
    root.setThemes([am5themes_Animated.new(root), responsive])

    let angles = isSemiDonut ? {startAngle: startAngle || -200, endAngle: endAngle || 20} : {}

    //create chart container
    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        ...angles,
        radius: am5.percent(60),
        innerRadius: am5.percent(70),
        layout: root.verticalLayout,
      })
    )

    //chart center label
    if (centerLabel) {
      var label = chart.seriesContainer.children.push(
        am5.Label.new(root, {
          textAlign: 'center',
          centerY: am5.p100,
          centerX: am5.p50,
          html: `<div class="d-flex flex-column justify-content-center align-items-center"><span class="fs-4">Total :</span>
          <div class="d-flex flex-stack align-items-center justify-content-center text-danger">
          <span class="fs-3 fw-bold text-gray-400 me-1 align-self-start">$</span>
          <span class="heading3">${
            centerLabel.isAmount ? formatNumber.format(centerLabel.value) : centerLabel.value
          }</span></div></div>`,
        })
      )
    }

    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: valueField,
        categoryField: categoryField,
        ...angles,
      })
    )

    //hide dataitems which has 0 value
    series.events.on('datavalidated', function () {
      am5.array.each(series.dataItems, function (dataItem) {
        if (dataItem.get('value') === 0) {
          dataItem.hide()
        }
      })
    })

    //setting slice colors
    seriesColors && series.set('colors', am5.ColorSet.new(root, {colors: seriesColors}))
    //setting custom label
    series.labels.template.set('text', `[#1469b3 bold fontSize:1.2rem ] $ {${valueField}}`)

    //setting series data
    series.data.setAll(data)

    //legend config
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: root.gridLayout,
      })
    )

    //disable values from legend
    // legend.labels.template.adapters.add('fill', (fill, target) => {
    //   if (target.dataItem && target.dataItem.isHidden()) {
    //     return am5.color(getCSSVariableValue('--bs-gray-400')) // Change to your desired color
    //   }
    //   return fill
    // })
    legend.markerRectangles.template.states.create('disabled', {
      fill: am5.color(getCSSVariableValue('--bs-gray-300')),
    })
    legend.valueLabels.template.set('forceHidden', true)
    legend.data.setAll(series.dataItems)

    series.appear(1000, 400)
    return () => {
      root.dispose()
    }
  }, [config])
  return (
    <>
      <div id={containerId} className='py-3' style={{height: `${height ? height : 400}px`}}></div>
    </>
  )
}

export default AmDonutChart
