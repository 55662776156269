import React, {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5radar from '@amcharts/amcharts5/radar'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {ILineChartConfig} from '../../models/chart'
type IProps = {
  config: ILineChartConfig
}
function AmRadarChart({config}: IProps) {
  const {data, categoryField, containerId, height, width, valueField} = config
  useLayoutEffect(() => {
    let root = am5.Root.new(containerId)
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    )

    let xRenderer = am5radar.AxisRendererCircular.new(root, {})
    xRenderer.labels.template.setAll({
      radius: 10,
    })

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: categoryField,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {
          tooltipText: '{valueY}',
        }),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5radar.AxisRendererRadial.new(root, {}),
      })
    )
    xAxis.data.setAll(data)
    // Define data

    let series = chart.series.push(
      am5radar.RadarLineSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: valueField,
        categoryXField: categoryField,
        tooltip: am5.Tooltip.new(root, {
          tooltipText: '{valueY}',
        }),
      })
    )

    series.strokes.template.setAll({
      strokeWidth: 2,
    })

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series.get('fill'),
          tooltip: am5.Tooltip.new(root, {
            tooltipText: '{CategoryX} : {valueY}',
          }),
        }),
      })
    })
    series.data.setAll(data)

    series.appear(1000, 400)
    return () => {
      root.dispose()
    }
  }, [])
  return (
    <div
      id={containerId}
      className='py-3'
      style={{height: `${height}px`, width: width ? `${width}px` : `100%`}}
    ></div>
  )
}

export default AmRadarChart
