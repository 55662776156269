import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'
import {ColumnInstance} from 'react-table'
import {Divider} from '@mui/material'

const ITEM_HEIGHT = 48
type IProps = {
  column?: ColumnInstance
  setSortBy?: (value: any) => void
  setGroupBy?: (value: any) => void
  groupBy?: string[]
}

const ColumnFilterPanel: React.FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const {setSortBy, column} = props
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        className='columnMenu'
        style={{display: open ? 'flex' : 'none'}}
      >
        <KTSVG path={IconPaths.threeDots_V} className='svg-icon-dark svg-icon-2' />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '10%',
            padding: '1rem',
          },
        }}
      >
        {column && setSortBy && !column.disableSortBy && (
          <>
            <MenuItem
              disabled={column.isSorted && !column.isSortedDesc}
              onClick={() => {
                setSortBy([{id: column.id}])
                column.toggleSortBy(false)
              }}
            >
              Sort asc
            </MenuItem>

            <MenuItem
              disabled={column.isSorted && column.isSortedDesc}
              onClick={() => {
                setSortBy([{id: column.id, desc: true}])
                column.toggleSortBy(true)
              }}
            >
              Sort desc
            </MenuItem>
          </>
        )}
        {column && column.isSorted && (
          <MenuItem onClick={column.clearSortBy}>Reset sorting</MenuItem>
        )}
        {/* {column && setGroupBy && column.isGrouped && (
          <MenuItem
            onClick={() => {
              setGroupBy(toggleArrayValues(groupBy ? groupBy : [], column.id))
            }}
          >
            Remove group by
          </MenuItem>
        )}
        {column && setGroupBy && !column.isGrouped && (
          <MenuItem
            onClick={() => {
              setGroupBy(toggleArrayValues(groupBy ? groupBy : [], column.id))
            }}
          >
            Add group by
          </MenuItem>
        )} */}
        <Divider className='my-3' />
        {props.children}
      </Menu>
    </div>
  )
}
export default ColumnFilterPanel
