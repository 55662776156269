import {FC} from 'react'
import {CustomLink, H3Title} from '../../../app/modules/dashboard/common-utilities/ui/Headings'
import {LinkProps} from '../../../app/modules/dashboard/models/type'

interface Props {
  className?: string
  scroll?: boolean
  height?: number
  description?: string
  linkProps?: LinkProps
}

const KTCardHeader: FC<Props> = (props) => {
  const {className = '', children} = props
  return <div className={`card-header ${className}`}>{children}</div>
}

const KTCardTitle: FC<Props> = (props) => {
  const {className = '', children, description, linkProps} = props

  return (
    <div className={`card-title ${description ? 'd-flex flex-column' : ''} ${className}`}>
      {linkProps ? (
        <CustomLink {...linkProps}>
          <H3Title color={'gray-700'}>{children}</H3Title>
        </CustomLink>
      ) : (
        <H3Title color={'gray-700'}>{children}</H3Title>
      )}
      {description && <div className='my-3 body2-medium text-gray-500'>{description}</div>}
    </div>
  )
}

const KTCardToolbar: FC<Props> = (props) => {
  const {className = '', children} = props
  return <div className={`card-toolbar ${className}`}>{children}</div>
}

export {KTCardHeader, KTCardTitle, KTCardToolbar}
