import {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
import {IPieChartConfig} from '../../models/chart'
type IProps = {
  config: IPieChartConfig
}
const AmPieChart = ({config}: IProps) => {
  const {data, height, width, categoryField, containerId, valueField, legendConfig} = config
  useLayoutEffect(() => {
    let root = am5.Root.new(containerId)
    // console.log({height, width})
    root._logo?.dispose()
    let responsive = am5themes_Responsive.newEmpty(root)

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: am5.percent(80),
      })
    )

    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: valueField,
        categoryField: categoryField,
      })
    )

    //responsive settings
    responsive.addRule({
      relevant: function (w, h) {
        if (width) return width < 450
        else return w < 450
      },
      applying: function () {
        chart.set('radius', am5.percent(40))
        series.set('alignLabels', true)
        series.labels.template.set('fontSize', '0.8rem')
      },
      removing: function () {
        chart.set('radius', am5.percent(80))
        series.set('alignLabels', false)
        series.labels.template.set('fontSize', '1rem')
      },
    })

    //adding themes
    root.setThemes([am5themes_Animated.new(root), responsive])
    series.labels.template.set(
      'text',
      `[#1469b3 bold fontSize:1rem ] {${categoryField}}:{${valueField}}`
    )
    // series.slices.template.set('tooltipText', '{category}: {value}');
    series.data.setAll(data)
    if (legendConfig && legendConfig.isRequired) {
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          layout: root.gridLayout,
        })
      )

      legend.data.setAll(series.dataItems)
    }
    series.appear(1000, 400)
    return () => {
      root.dispose()
    }
  }, [height, width, data])
  return (
    <>
      <div
        id={containerId}
        className='py-3'
        style={{height: `${height}px`, width: `${width}px`}}
      ></div>
    </>
  )
}

export default AmPieChart
