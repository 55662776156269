import React, {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {IAreaChartConfig} from '../../models/chart'
import {getCommonXYChartConfigs} from './variables'
type IProps = {
  config: IAreaChartConfig
}
function AmAreaChart({config}: IProps) {
  const {data, categoryField, containerId, height, valueField, width} = config
  useLayoutEffect(() => {
    let root = am5.Root.new(containerId)
    root.setThemes([am5themes_Animated.new(root)])

    getCommonXYChartConfigs({categoryField, root, valueField}).then(({chart, xAxis, yAxis}) => {
      xAxis.data.setAll(data)

      // Create area series
      let series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: valueField,
          categoryXField: categoryField,
          sequencedInterpolation: true,
          sequencedDelay: 100,
        })
      )
      series.fills.template.setAll({
        fillOpacity: 0.2,
        visible: true,
      })
      series.strokes.template.setAll({
        strokeWidth: 3,
      })
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Circle.new(root, {
            radius: 4,
            stroke: root.interfaceColors.get('background'),
            strokeWidth: 2,
            fill: series.get('fill'),
            tooltipText: ` {categoryX} :  {valueY}`,
            tooltipY: am5.percent(90),
          }),
        })
      })

      series.data.setAll(data)
      series.appear(1000, 400)
    })
    return () => {
      root.dispose()
    }
  }, [])

  return (
    <div
      id={containerId}
      className='py-3'
      style={{height: `${height}px`, width: width ? `${width}px` : `100%`}}
    ></div>
  )
}

export default AmAreaChart
