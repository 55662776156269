import {FC, useEffect, useMemo, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {IWidgetModel} from '../../modules/dashboard/models/type'
import {setDashboardConfig} from '../../modules/redux/DashboardSlice'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../setup'
import {RoleWiseDashboard} from '../../../app/modules/dashboard/widgets/RoleWiseDashboard'
import {useAuthSlice} from '../../modules/dashboard/common-utilities/hooks/storeData'
import StaticLayout from '../../modules/dashboard/StaticLayout'
import DynamicLayout from '../../modules/dashboard/DynamicLayout'

const plugins = ['wfcharts', 'gridreport']
const RedmapDashboardPage2: FC = () => {
  const [data, setData] = useState<IWidgetModel[]>([])
  const dispatch = useDispatch()
  const {user} = useAuthSlice()
  const widgets: IWidgetModel[] = useSelector<RootState>(
    ({dashboard}) => dashboard.widgets,
    shallowEqual
  ) as IWidgetModel[] | []
  const role: string = useSelector<RootState>(
    ({dashboard}) => dashboard.currentRole,
    shallowEqual
  ) as string

  useEffect(() => {
    let temp = RoleWiseDashboard.find((ele) => ele.role === role)
    if (temp !== undefined) dispatch(setDashboardConfig(temp))
  }, [role])

  useEffect(() => {
    setData(widgets)
  }, [widgets])
  const filteredData = useMemo(() => {
    return data.filter((ele) => plugins.includes(ele.plugin.toLowerCase()))
  }, [data])
  return (
    <div className='fix_width_container'>
      <StaticLayout widgets={data} user={user?.preferred_username} isDraggable={true} />
      {user?.debugUser && filteredData && filteredData.length > 0 && (
        <DynamicLayout data={filteredData} />
      )}
    </div>
  )
}

const NewDashboardWrapper: FC = () => {
  // const intl = useIntl()
  const dispatch = useDispatch()
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Home',
            path: '/home',
            isSeparator: false,
            isActive: true,
            isLink: true,
          },
        ]}
      >
        Home
      </PageTitle>
      {/* <div
        className='engage-toolbar d-flex position-fixed px-5 fw-bolder top-50 end-0 transform-90 mt-20 gap-2'
        style={{zIndex: 100}}
      >
        <button
          type='button'
          className='engage-demos-toggle btn btn-flex h-35px btn-primary shadow-sm fs-6 px-4 rounded-top-0'
          onClick={() => dispatch(SetCurrentEditingWidget({widgetId: 0, type: 'GridReport'}))}
          title='Configuration'
          id='kt_config_toggle'
        >
          <span className='mx-2 fs-4 fw-bold'>Grid</span>
          <KTSVG path={IconPaths.AddNew} className='svg-icon-2x svg-icon-white' />
        </button>
        <button
          type='button'
          className='engage-demos-toggle btn btn-flex h-35px btn-primary shadow-sm fs-6 px-4 rounded-top-0'
          onClick={() => dispatch(SetCurrentEditingWidget({widgetId: 0, type: 'WFCharts'}))}
          title='Configuration'
          id='kt_config_toggle'
        >
          <span className='mx-2 fs-4 fw-bold'>Chart</span>
          <KTSVG path={IconPaths.AddNew} className='svg-icon-2x svg-icon-white' />
        </button>
      </div> */}
      <RedmapDashboardPage2 />
    </>
  )
}

export {NewDashboardWrapper}
