import {IImportConfig, IPlugins, IReport, IChartGrid, IGrid, IDateRange} from './type'

type IProps = {
  plugin: IPlugins
}
export const getWidgetsInitialData = ({plugin}: IProps) => {
  return {
    widgetId: 0,
    widgetName: '',
    library: '',
    docGroup: '',
    plugin: plugin,
    configFilePath: '',
    userConfig: '',
    isExisting: false,
    userName: '',
    owner: '',
    containerType: '',
    pfStream: '',
    permission: 0,
    gridConfig: defaultUserConfigData[plugin],
    type: 0,
  }
}

export const initialGridConfigData: IGrid = {
  DateField: '',
  DateTable: '',
  Fields: [],
  Predicate: '',
  SQL_Query: '',
  Table: '',
}
export const initialDateRangeData: IDateRange = {
  Type: 'All',
  Start: '',
  End: '',
}
export const initialChartConfigData: IChartGrid = {
  AllowClickThrough: 'No',
  CategoryColWidth: '',
  CategoryField: '',
  ChartType: '',
  ClickThroughQuery: '',
  DateField: '',
  DateTable: '',
  ExecuteQuery: 'False',
  Name: '',
  SeriesColWidth: '',
  SeriesQuery: '',
  Table: '',
}
const editableGridConfig: IReport = {
  Grid: initialGridConfigData,
  DateRange: initialDateRangeData,
  Controls: {},
}

export const initialImportConfig: IImportConfig = {
  ColumnHeaderName: false,
  ContinueOnError: false,
  Delimiter: ',',
  DateFormat: '',
  Fields: [],
  Format: '',
  PrimaryKey: 'itemid',
  Table: '',
  UploadFile: '',
  ColumnHeader: true,
  Columns: '',
  UDLFile: '',
}
export const defaultUserConfigData: {[key in Partial<IPlugins>]: any} = {
  EditableGridReport: {Report: editableGridConfig},
  BatchUpdate: {},
  ExportUtility: {},
  GridReport: {
    Report: {
      Grid: initialGridConfigData,
      DateRange: initialDateRangeData,
    },
  },
  WFCharts: {
    Report: {
      Chart: initialChartConfigData,
      DateRange: initialDateRangeData,
    },
  },
  ImportUtility: {Report: {Grid: initialImportConfig}},
  KPIReport: {},
}

export const defaultConfigData: {
  [key in IPlugins]: IGrid | IImportConfig | IChartGrid | any
} = {
  EditableGridReport: initialGridConfigData,
  BatchUpdate: {},
  ExportUtility: {},
  GridReport: initialGridConfigData,
  WFCharts: initialChartConfigData,
  ImportUtility: initialImportConfig,
  KPIReport: {},
}
