export const sortNumbers = (data: any[], sortOrder: string, sortField: string) => {
  let temp = [...data]
  if (sortOrder === 'asc') return temp.sort((a, b) => a[sortField] - b[sortField])
  if (sortOrder === 'desc') return temp.sort((a, b) => b[sortField] - a[sortField])
}
export const sortString = (data: any[], sortOrder: string, sortField: string) => {
  let temp = [...data]
  temp = temp.sort((a, b) =>
    a[sortField].toLowerCase() > b[sortField].toLowerCase()
      ? 1
      : a[sortField].toLowerCase() < b[sortField].toLowerCase()
      ? -1
      : 0
  )
  if (sortOrder === 'asc') return temp
  if (sortOrder === 'desc') return temp.reverse()
}
export const SortStringArray = (data: any[], sortOrder: 'asc' | 'desc'): any[] => {
  let temp = [...data]
  try {
    temp = temp.sort((a, b) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : a.toLowerCase() < b.toLowerCase() ? -1 : 0
    )
  } catch (err) {
    console.log('error in sorting array', err)
  } finally {
    if (sortOrder === 'asc') return temp
    if (sortOrder === 'desc') return temp.reverse()
    else return temp
  }
}
export const toggleArrayValues = <T extends unknown>(array: Array<T>, value: T) => {
  const newArray = array.filter((x) => x !== value)
  if (newArray.length === array.length) return newArray.concat(value)
  return newArray
}

export const arrayCRUD = <T extends unknown>(
  array: Array<T>,
  value: any,
  operation: 'update' | 'delete',
  key: string
) => {
  const newArray = array.filter((x: any) => x[key] !== value[key])
  if (operation === 'update') return newArray.concat(value)
  return newArray
}
export const arrayModdify = <T extends unknown>(
  array: Array<T>,
  value: any,
  operation: 'update' | 'delete' | 'addNew',
  key: string
) => {
  if (operation === 'delete') return array.filter((x: any) => x[key] !== value[key])
  else if (operation === 'addNew')
    return array.filter((x: any) => x[key] !== value[key]).concat(value)
  else
    return array.map((ele: any) => {
      if (ele[key] === value[key]) return {...value}
      else return {...ele}
    })
}

export const filterByText = (keyword: string, data: any) => {
  if (keyword.length > 0 && keyword !== undefined) {
    let filterData = data.filter((ele: any) => {
      return Object.keys(ele).some(function (key) {
        return ele[key] === null || ele[key] === undefined
          ? false
          : ele[key].toString().toLowerCase().includes(keyword.toLowerCase())
      })
    })
    return filterData
  } else {
    return data
  }
}

export const mergeArrays = <T extends unknown>(
  mainArray: Array<T>,
  updatingArray: Array<T>,
  key: string
) => {
  if (key !== '') {
    let newData = updatingArray.filter(
      (ele: any) => !mainArray.find((item: any) => item[key] === ele[key])
    )
    let existingData: any = mainArray.map((ele: any) => {
      let data = updatingArray.find((items: any) => items[key] === ele[key])
      if (data !== undefined && typeof data === 'object') return data
      else return ele
    })
    let temp = existingData.concat(newData)
    return temp
  } else return mainArray
}
export const toggleArrayOfObjects = <T extends unknown>(array: Array<T>, value: any, key: any) => {
  if (typeof value === 'object') {
    const newArray = array.filter((x: any) => x[key] !== value[key])
    if (newArray.length === array.length) return newArray.concat(value)
    return newArray
  } else return array
}
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
export const formatNumber = new Intl.NumberFormat(`en-US`)
type DataItem = Record<string, any>

export const genericSort = (
  data: DataItem[],
  columnId: string,
  isDescending: boolean
): DataItem[] => {
  return data.slice().sort((a, b) => {
    const valueA = a[columnId]
    const valueB = b[columnId]

    // Attempt to infer data type
    const typeA = typeof valueA
    const typeB = typeof valueB

    if (typeA === 'number' && typeB === 'number') {
      return isDescending ? valueB - valueA : valueA - valueB
    } else if (typeA === 'string' && typeB === 'string') {
      return isDescending ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB)
    } else if (
      typeA === 'object' &&
      valueA instanceof Date &&
      typeB === 'object' &&
      valueB instanceof Date
    ) {
      return isDescending
        ? valueB.getTime() - valueA.getTime()
        : valueA.getTime() - valueB.getTime()
    } else {
      // Handle other types or fallback to default comparison
      return isDescending ? valueB - valueA : valueA - valueB
    }
  })
}
export const durationSorting = (rowA: any, rowB: any, fieldName: string) => {
  const unitsOrder: any = {s: 0, m: 1, h: 2, d: 3, w: 4} // Define the order of units

  const parseDuration = (duration: any) => {
    try {
      const value = parseFloat(duration) // Extract numerical value
      const unit = duration.slice(-1) // Get the last character (unit)
      return {value, unit}
    } catch (err) {
      console.log('Sort parsing error', {rowA, rowB})
      return {value: 0, unit: ''}
    }
  }

  const {value: valueA, unit: unitA} = parseDuration(rowA.original[fieldName])
  const {value: valueB, unit: unitB} = parseDuration(rowB.original[fieldName])

  try {
    if (unitA !== unitB) {
      return unitsOrder[unitA] - unitsOrder[unitB] // Sort by unit order if units are different
    }

    // If units are the same, compare values
    return valueA - valueB // Sort by value within the same unit
  } catch (err) {
    console.log('error in comparing', err)
    return 0
  }
}
