import {useState} from 'react'
import {KTCard, KTCardBody, KTSVG} from '../../../../../_metronic/helpers'
import {H2Title} from './Headings'
import {IconPaths} from './icons/iconPaths'
import {Divider} from '@mui/material'
import {RootButtons} from '..'
type IProps = {
  innerItems?: JSX.Element
  outerItems: JSX.Element
  handleUpdate?: () => void
  disableSubmit?: boolean
}
const HeaderHoc = ({innerItems, outerItems, handleUpdate, disableSubmit = false}: IProps) => {
  const [open, setOpen] = useState(true)
  return (
    <KTCard className='mb-6 card-flush'>
      <KTCardBody>
        <div
          className='d-flex align-items-center'
          onClick={() => {
            setOpen(!open)
          }}
        >
          <H2Title className='hover-underline cursor-pointer' color={'dark'}>
            Search or refine report
          </H2Title>
          <span className='linkedTitle'>
            <KTSVG
              path={open ? IconPaths.closeArrow : IconPaths.openArrow}
              className='svg-icon-primary svg-icon-4x'
            />
          </span>
        </div>

        <div className='mt-4'>{outerItems}</div>
        {open && innerItems && (
          <>
            <Divider className='my-10' />
            {innerItems}
          </>
        )}
      </KTCardBody>

      {open && handleUpdate && (
        <div className='card-footer' style={{alignItems: 'flex-start'}}>
          {handleUpdate && (
            <RootButtons.NormalButton
              onClick={handleUpdate}
              title={'Update report'}
              buttonSize='lg'
              isDisabled={disableSubmit}
            />
          )}
          <RootButtons.NormalButton
            onClick={() => {
              setOpen(false)
            }}
            title={'Close'}
            varient='outlined'
            buttonSize='lg'
          />
        </div>
      )}
    </KTCard>
  )
}

export default HeaderHoc
