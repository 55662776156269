import {Fragment, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {RootState} from '../../setup'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import useRouteData from './routes'
import {getAllWidgets} from '../modules/redux/widgets/api'
import {getLibraryAndGroups} from '../modules/redux/configurations/api'
import {UserModel} from '../modules/auth/models/UserModel'
import * as dashboardActions from '../modules/redux/DashboardSlice'
import {MenuDataTypes} from '../modules/dashboard/models/type'
import {setFilterValues, setLibraries} from '../modules/redux/AgedPayableSlice'
import {initialFiltersValues} from '../modules/dashboard/widgets/detail-view/components/data-schema/FilterContext'
import {getAgedPayableLib} from '../modules/redux/kpi/api'
import {getErrorMessage} from '../modules/dashboard/common-utilities/alerts/AlertModels'
import {
  useAgedPayableSlice,
  useStoreData,
} from '../modules/dashboard/common-utilities/hooks/storeData'

const PrivateRoutesNew = () => {
  const dispatch = useDispatch()
  const RouteData = useRouteData() //Get all route data
  const {filterValues} = useAgedPayableSlice()
  const {defaultLibrary} = useStoreData()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  useEffect(() => {
    getAllWidgets() //Get all widgets
      .then((response) => {
        dispatch(dashboardActions.widgetFetched(response.data))
      })
      .catch((error) => {})
    getLibraryAndGroups() //Libraries for general use
      .then((response) => {
        dispatch(dashboardActions.setDashboardConfiguration({libraries: response.data}))
      })
      .catch((error) => {})
    getAgedPayableLib() //libraries for agedpayable & cards
      .then((response) => {
        dispatch(setLibraries(response.data.map((ele) => ele.name)))
        let storage = localStorage.getItem(`agedConfig_${user.email}`)
        let values = JSON.parse(storage || '{}')

        if (values.filterValues && values.filterValues.library !== '') {
          //set previous config from localStorage
          dispatch(dashboardActions.setDefaultLibrary(values.filterValues.library))
          dispatch(setFilterValues({...values.filterValues}))
        } else if (filterValues.library === '' && response.data[0]) {
          dispatch(dashboardActions.setDefaultLibrary(response.data[0].name))
          dispatch(setFilterValues({...initialFiltersValues, library: response.data[0].name}))
        } else if (defaultLibrary === undefined) {
          dispatch(dashboardActions.setDefaultLibrary(filterValues.library))
        }
      })
      .catch((err) => {
        console.log('AgedPayableLib', getErrorMessage(err))
      })
  }, [user])
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {BuildRoutes(RouteData)}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const BuildRoutes = (routes: MenuDataTypes[], basePath = ''): JSX.Element[] => {
  return routes
    .filter((ele) => {
      return ele.show
    })
    .map((ele, index) => {
      const {routePath, component: LazyComponent, subMenu, key} = ele
      const path = basePath + routePath
      return (
        <>
          {subMenu && subMenu.length > 0 ? (
            <Route key={key} path={path}>
              <Route key={key} index element={<LazyComponent />} />
              <Fragment>{BuildRoutes(subMenu, path)}</Fragment>
            </Route>
          ) : (
            <Route key={key} path={path} element={<LazyComponent />} />
          )}
        </>
      )
    })
}

// const SuspensedView: FC = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutesNew}
