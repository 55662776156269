import React, {FC} from 'react'
import {KTCard, KTCardBody} from '../../../../../../_metronic/helpers'
import {
  KTCardHeader,
  KTCardTitle,
  KTCardToolbar,
} from '../../../../../../_metronic/helpers/components/KTCardHeader'
import {LinkProps} from '../../../models/type'

type IProps = {
  header?: {
    titleClasses?: string
    headerClasses?: string
    toolbarClasses?: string
    title?: string | JSX.Element
    descriptions?: string
    toolbar?: JSX.Element
    linkProps?: LinkProps
  }

  cardClasses?: string

  cardBodyClasses?: string
  children: React.ReactNode
}
const ChartContainerHoc: FC<IProps> = (props: IProps) => {
  const {children, cardBodyClasses, cardClasses, header} = props
  return (
    <KTCard className={`card-flush overflow-hidden pt-8 px-0 ${cardClasses}`}>
      {header && (
        <KTCardHeader className={`px-5  ${header.headerClasses}`}>
          {header.title && (
            <KTCardTitle linkProps={header.linkProps} description={header.descriptions}>
              {header.title}
            </KTCardTitle>
          )}
          {header.toolbar && (
            <KTCardToolbar className={`${header.toolbarClasses ? header.toolbarClasses : ''}`}>
              {header.toolbar}
            </KTCardToolbar>
          )}
        </KTCardHeader>
      )}
      <KTCardBody className={`p-0 ${cardBodyClasses}`}>{children}</KTCardBody>
    </KTCard>
  )
}

export default ChartContainerHoc
