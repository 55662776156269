import React, {useEffect, useState} from 'react'
import {ErrorText, H1Title} from '../../common-utilities/ui/Headings'
import {ContentLoading} from '../../common-utilities/loading/ContentLoading'
import ProductivityChart from './ProductivityChart'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'
import moment from 'moment'
import {dateFormats, errorStatements} from '../../common-utilities/ui/Standards'
import {formatNumber} from '../../common-utilities/sorting/SortingMethods'
import ChartContainerHoc from '../../common-utilities/ui/containers/ChartContainerHoc'
import {getProductivitySnapshotData} from '../../../redux/productivity/api'
import {useStoreData} from '../../common-utilities/hooks/storeData'
import {getTrend} from '../cards/detail-view/CardDetailContext'
import {AgedPayableFieldsTypes, IScope} from '../../models/type'
export type TrendConfig = {
  trend: 'Increasing' | 'Decreasing'
  difference: 'Up' | 'Down' | 'Equal'
  trendValue: number
}
export interface ProductivityConfigData {
  totalInvoiceAmount: number
  totalItems: number
  amountTrend: TrendConfig
  itemTrend: TrendConfig
  period: IScope
  chartData: {category: string; amount: number; item: number}[]
}
export type ProductivityDetailResponse = {
  totalInvoiceAmount: number
  amountTrend: TrendConfig
  period: IScope
  columns: AgedPayableFieldsTypes[]
  productivityDataModel: any[]
  statusUpdated: boolean
  lastModifiedDate: string
}
// const sampleData: ProductivityConfigData = {
//   totalInvoiceAmount: 88895035,
//   totalItems: 46849,
//   amountTrend: {trendValue: 4.2, difference: 'Up', trend: 'Increasing'},
//   itemTrend: {trendValue: 4.2, difference: 'Up', trend: 'Increasing'},
//   period: {
//     from: '2024-05-03T01:02:25Z',
//     to: '2024-05-30T01:02:25Z',
//     range: '',
//   },
//   chartData: [
//     {
//       category: 'Automated',
//       amount: 49700,
//       item: 20,
//     },
//     {category: 'Exceptions', amount: 5088976, item: 40},
//     {category: 'PO', amount: 1075548, item: 10},
//     {category: 'Non-PO', amount: 30056, item: 50},
//     {category: 'Client', amount: 6569558, item: 80},
//     {category: 'Inactivity', amount: 4027854, item: 60},
//     {category: 'Posted', amount: 9650025, item: 20},
//   ],
// }
const ProductivityReportPanel = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [data, setData] = useState<ProductivityConfigData | undefined>()
  const {defaultLibrary} = useStoreData()
  useEffect(() => {
    if (defaultLibrary) {
      setLoading(true)
      getProductivitySnapshotData({libraryName: defaultLibrary, period: 'Monthly'})
        .then((res) => {
          setData(res.data)
        })
        .catch((err) => {
          setError(errorStatements.noRecords)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [defaultLibrary])
  const formatDate = (date: string) => {
    let formatedDate = moment(date).format(dateFormats.onTable)

    return formatedDate
  }
  return (
    <>
      <ChartContainerHoc
        header={{
          descriptions: data
            ? `${formatDate(data.period.from)} to ${formatDate(data.period.to)}`
            : undefined,
          title: `Productivity Report`,

          // linkProps: {
          //   link: '/productivityReport/All',
          //   linkTitle: 'View productivity summary',
          //   isDisabled: loading || error !== undefined,
          // },
        }}
        cardClasses='h-500px'
      >
        <>
          {loading && <ContentLoading />}
          {error && <ErrorText className='my-4'>{`${error}`}</ErrorText>}
          {data && (
            <>
              <div className='d-flex justify-content-center flex-wrap align-items-center pt-3'>
                <div className='d-flex align-items-center me-10'>
                  <span className='fs-2 fw-bold text-gray-400 me-1 align-self-start'>$</span>
                  <H1Title color={'gray-700'} className='me-3'>
                    {data && formatNumber.format(data?.totalInvoiceAmount)}
                  </H1Title>

                  <TrendComponent {...data.amountTrend} trend='Increasing' />
                </div>
                <div className='d-flex align-items-center'>
                  <H1Title color={'gray-700'} className='me-3'>
                    {data && formatNumber.format(data?.totalItems)}
                  </H1Title>

                  <TrendComponent {...data.itemTrend} trend='Increasing' />
                </div>
              </div>

              {data.chartData.length === 0 ? (
                <div className='w-100 h-400px pt-10 px-5'>
                  <ErrorText>No data available for chart</ErrorText>
                </div>
              ) : (
                <ProductivityChart chartData={data.chartData} />
              )}
            </>
          )}
        </>
      </ChartContainerHoc>
    </>
  )
}
export const TrendComponent = (props: TrendConfig) => {
  const {difference, trend, trendValue} = props
  let diff: any = difference.toLowerCase()
  const {border, trendClass} = getTrend({diff, trendValue: trend})
  return (
    <div className={`badge badge-light-${border} fs-base p-3 rounded-2 me-4`}>
      <KTSVG
        path={
          difference === 'Equal'
            ? IconPaths.rightArrow
            : difference === 'Up'
            ? IconPaths.upArrow
            : IconPaths.downArrow
        }
        className={`svg-icon-3 ms-n1 ${trendClass} `}
      />
      {`${trendValue} %`}
    </div>
  )
}
export default ProductivityReportPanel
