import React, {useContext, useEffect, useState} from 'react'
import MuiSelectField from '../../../common-utilities/ui/MuiSelectField'
import {IChartGrid, IWidgetModel, IWidgetTableFields} from '../../../models/type'
import {ConfigurationContext} from '../../drawer/ConfigurationSchema'
import {Grid} from '@mui/material'
import {getTableFields, getTables} from '../../../../redux/configurations/api'

import {getErrorMessage, showErrorToast} from '../../../common-utilities/alerts/AlertModels'
type IProps = {
  isFormDirty: boolean
  widgetData: IWidgetModel
  configData: IChartGrid
  setConfigData: React.Dispatch<React.SetStateAction<IChartGrid>>
}
const DateConfigForm = () => {
  const {configData, widgetData, setConfigData}: IProps = useContext(ConfigurationContext)

  const [tables, setTables] = useState<string[]>([])
  const [tableFields, setTableFields] = useState<IWidgetTableFields[]>([])
  useEffect(() => {
    if (widgetData.library === '') {
      // setConfigData({...initialData})
    } else {
      loadTables()
    }
  }, [widgetData.library])
  useEffect(() => {
    if (configData.Table !== '' && widgetData.library !== '') loadTableFields()
  }, [configData.Table])

  const loadTables = async () => {
    getTables(widgetData.library, true)
      .then((res) => {
        setTables(res.data.map((ele) => ele.name))
      })
      .catch((err) => {
        showErrorToast({message: 'Cannot load tables'})
      })
  }
  const loadTableFields = async () => {
    getTableFields(widgetData.library, configData.Table)
      .then((res) => {
        setTableFields(res.data.filter((ele) => ele.fieldType === 'DateTime'))
      })
      .catch((err) => {
        showErrorToast({message: 'Table Fields: \n' + getErrorMessage(err)})
      })
  }
  const onTableChange = (val: string) => {
    if (val !== '') {
      getTableFields(widgetData.library, configData.Table)
        .then((res) => {
          setTableFields(res.data.filter((ele) => ele.fieldType === 'DateTime'))
        })
        .catch((err) => {
          showErrorToast({message: 'Table Fields: \n' + getErrorMessage(err)})
        })
    }
    setConfigData({...configData, DateTable: val, DateField: ''})
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MuiSelectField
            changedValues={(value: string) => {
              onTableChange(value)
            }}
            errorText='Select a table'
            hasError={false}
            id='table'
            isRequired={false}
            label='Table'
            val={configData.DateTable}
            items={tables}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiSelectField
            changedValues={(value: string) => setConfigData({...configData, DateField: value})}
            errorText={'Select Field'}
            hasError={false}
            isRequired={false}
            id={'ParentField'}
            items={tableFields}
            displayField={'fieldName'}
            label={'Field'}
            val={configData.DateField}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DateConfigForm
