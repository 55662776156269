import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {Dashboard, IWidgetModel} from '../../models/type'
import {SetCurrentEditingWidget} from '../../../redux/DashboardSlice'
import {ConfigurationContextProvider} from './ConfigurationContext'
import ChartConfigForm from '../wf-charts/configuration/ChartConfigForm'
import GridConfigForm from '../grid-report/configuration/GridConfigForm'

const WidgetConfigDrawer = () => {
  const {allWidgets, currentEditingWidget, currentWidgetType}: Dashboard = useSelector<RootState>(
    ({dashboard}) => dashboard,
    shallowEqual
  ) as Dashboard
  const dispatch = useDispatch()
  const drawerRef = useRef<HTMLDivElement>(null)
  const closeButton = document.getElementById('kt_config_close')
  const [widget, setWidget] = useState<IWidgetModel | undefined>(undefined)
  // const handleOutsideClick = (event: MouseEvent) => {
  //   if (
  //     currentEditingWidget !== undefined &&
  //     drawerRef.current &&
  //     !drawerRef.current.contains(event.target as Node)
  //   ) {
  //     dispatch(SetCurrentEditingWidget())
  //     setWidget(undefined)
  //   }
  // }
  useEffect(() => {
    if (closeButton)
      closeButton.addEventListener('click', () => {
        dispatch(SetCurrentEditingWidget())
        setWidget(undefined)
      })
  }, [])

  const handleFormSubmit = () => {
    closeButton && closeButton.click()
  }
  useEffect(() => {
    // const handleDocumentClick = (event: MouseEvent) => {
    //   handleOutsideClick(event)
    // }
    // document.addEventListener('click', handleDocumentClick)

    let tempWidget = allWidgets?.find((ele) => ele.widgetId === currentEditingWidget)
    if (tempWidget) {
      setWidget(tempWidget)
    }

    // return () => {
    //   document.removeEventListener('click', handleDocumentClick)
    // }
  }, [currentEditingWidget])

  return (
    <>
      <div
        id='kt_config'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='config'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_config_toggle'
        data-kt-drawer-close='#kt_config_close'
        ref={drawerRef}
      >
        <div className='card w-100 shadow-none rounded-0'>
          <div className='card-header' id='kt_config_header'>
            <h3 className='card-title fw-bolder text-dark'>
              {currentEditingWidget === 0 ? `Add new report` : `Edit ${widget?.widgetName}`}
            </h3>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='kt_config_close'
                onClick={() => {
                  dispatch(SetCurrentEditingWidget())
                  setWidget(undefined)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </button>
            </div>
          </div>
          <div className='card-body position-relative' id='kt_config_body'>
            {currentEditingWidget !== undefined && currentWidgetType === 'WFCharts' && (
              <ConfigurationContextProvider
                action={currentEditingWidget === 0 ? 'Add' : 'Edit'}
                widgetId={currentEditingWidget}
                type='WFCharts'
              >
                <ChartConfigForm handleFormSubmit={handleFormSubmit} />
              </ConfigurationContextProvider>
            )}
            {currentEditingWidget !== undefined && currentWidgetType === 'GridReport' && (
              <ConfigurationContextProvider
                action={currentEditingWidget === 0 ? 'Add' : 'Edit'}
                widgetId={currentEditingWidget}
                type='GridReport'
              >
                <GridConfigForm handleFormSubmit={handleFormSubmit} />
              </ConfigurationContextProvider>
            )}
          </div>
          <div className='card-footer py-5 text-center' id='kt_config_footer'></div>
        </div>
      </div>
    </>
  )
}

export default WidgetConfigDrawer
