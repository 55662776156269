import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from './icons/iconPaths'
import {useAsyncDebounce} from 'react-table'
type IProps = {
  value: string | undefined
  handleSearch: (value: string) => void
  placeHolder?: string
}
const SearchBox = ({handleSearch, value, placeHolder}: IProps) => {
  const handleChange = useAsyncDebounce((val: string) => {
    handleSearch(val)
  }, 500)
  return (
    <div className='d-flex align-items-center position-relative my-1 px-3 '>
      <span className='svg-icon svg-icon-1 position-absolute ms-4'>
        <KTSVG path={IconPaths.search} className='svg-icon-2hx svg-icon-primary' />
      </span>
      <input
        type='search'
        data-kt-filter='search'
        className='form-control customInputs border-primary border-3 w-lg-550px w-sm-100 w-md-100 ps-14'
        placeholder={placeHolder ? placeHolder : 'Search term...'}
        onChange={(e) => {
          handleChange(e.target.value)
        }}
        value={value}
      />
    </div>
  )
}

export default SearchBox
