import React, {useEffect, useState} from 'react'
import {IDateRange, IWidgetModel} from '../../models/type'
import * as widgetAPIS from '../../../redux/widgets/api'
import * as widgetActions from '../../../redux/DashboardSlice'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {KTSVG} from '../../../../../_metronic/helpers'
import GridReport from './GridReport'
import {DateRange} from '../../common-utilities/ui/DateRange'
import {
  deleteWArning,
  getErrorMessage,
  showErrorToast,
  showSuccessToast,
} from '../../common-utilities/alerts/AlertModels'
import {ContentLoading} from '../../common-utilities/loading/ContentLoading'
import {H4Title} from '../../common-utilities/ui/Headings'
import {updateDateRange} from '../../../redux/configurations/api'
import {RootButtons} from '../../common-utilities'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'
type IProps = {
  contentId: number
}
const GridReportPanel = React.forwardRef((props: IProps, ref) => {
  const {className, style, width, height, children, contentId, ...rest}: any = props

  const widthTemp = parseInt(style.width, 10)
  const heightTemp = parseInt(style.height, 10) - 90

  const widgetConfig: IWidgetModel = useSelector<RootState>(
    ({dashboard}) => dashboard.widgets.find((item: IWidgetModel) => item.widgetId === contentId),
    shallowEqual
  ) as IWidgetModel
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<undefined | string>(undefined)
  const [DRSaving, setDRSaving] = useState<boolean>(false)
  const dateRangeChange = (data: IDateRange) => {
    setDRSaving(true)
    updateDateRange(data, contentId)
      .then((res) => {
        setDRSaving(false)
        let result = res.data
        dispatch(
          widgetActions.updateWidgetList({...result, gridConfig: JSON.parse(result.userConfig)})
        )
      })
      .catch((err) => {
        setDRSaving(false)
        showErrorToast({message: getErrorMessage(err)})
      })
  }
  const dispatch = useDispatch()
  useEffect(() => {
    setError(undefined)
    fetchWidgetData()
    return () => {
      setLoading(false)
      setError(undefined)
      setDRSaving(false)
    }
  }, [widgetConfig])

  const fetchWidgetData = () => {
    setLoading(true)
    widgetAPIS
      .getWidgetDataById(contentId, 1, 100)
      .then((response) => {
        setLoading(false)
        dispatch(widgetActions.widgetDataFetched({widgetId: contentId, content: response.data}))
      })
      .catch((err) => {
        setLoading(false)
        setError(getErrorMessage(err))
        console.log('No data found for widget', contentId)
      })
  }
  const deleteReport = async () => {
    let res = await deleteWArning()
    if (res) {
      setLoading(true)
      widgetAPIS
        .deleteWidget(contentId)
        .then((res) => {
          if (res.data) {
            setLoading(false)
            dispatch(widgetActions.removeWidget(contentId))
            showSuccessToast({message: 'Report deleted'})
          }
        })
        .catch((err) => {
          setLoading(false)
          showErrorToast({message: getErrorMessage(err)})
        })
    }
  }
  return (
    <div
      ref={ref}
      key={contentId}
      className={`card card-flush shadow-sm grid-item ${className}`}
      style={style}
      {...rest}
    >
      <div className='card-header'>
        <h3 className='grid-item__title card-drag-area'>{widgetConfig.widgetName}</h3>
        <div className='card-toolbar'>
          {DRSaving ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <DateRange
              data={widgetConfig.gridConfig.Report.DateRange}
              onRangeChange={dateRangeChange}
              dateField={widgetConfig.gridConfig.Report.Grid?.DateField}
            />
          )}
          <button
            type='button'
            className='btn mx-2   btn-icon btn-bg-light btn-light-primary  btn-sm'
            onClick={() =>
              dispatch(
                widgetActions.SetCurrentEditingWidget({widgetId: contentId, type: 'GridReport'})
              )
            }
            title='Configuration'
            id='kt_config_toggle'
          >
            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2x' />
          </button>
          <RootButtons.IconButton
            iconPath={IconPaths.delete}
            onClick={deleteReport}
            title={`Delete report`}
            iconClass='svg-icon-2x svg-icon-danger'
            iconColor='danger'
          />
        </div>
      </div>
      <div className='card-body grid-item__graph p-0'>
        {loading && <ContentLoading />}
        {!loading && error !== undefined && <H4Title color={'danger'}>{error}</H4Title>}
        {!loading && error === undefined && (
          <GridReport contentId={contentId} height={heightTemp} width={widthTemp} />
        )}
      </div>
      {children}
    </div>
  )
})

export default GridReportPanel
