import axios from 'axios'
import {
  IWidgetTables,
  IWidgetTableFields,
  Libraries,
  IAllUsers,
  IWidgetUserGroupNames,
  ITableFieldsPK,
  ICoordinates,
  IDateRange,
  IWidgetModel,
} from '../../dashboard/models/type'
import {WIDGET_CONFIG_URL} from '../EndPoints'

export function getTables(libraryname: string, withViews?: boolean) {
  return axios.get<IWidgetTables[]>(
    `${WIDGET_CONFIG_URL}/GetTables?withViews=${true}&libraryname=${libraryname}`
  )
}

export function getTableFields(library: string, tableName: string) {
  return axios.get<IWidgetTableFields[]>(
    `${WIDGET_CONFIG_URL}/GetFieldsByLibrary?library=${library}&tableName=${tableName}`
  )
}

export function getLibraryAndGroups() {
  return axios.get<Libraries[]>(`${WIDGET_CONFIG_URL}/GetLibraries`)
}

export function getUsersByLibrary(library: string) {
  return axios.get<IAllUsers[]>(`${WIDGET_CONFIG_URL}/GetUsers?libraryname=${library}`)
}
export function getUsersByLibraryAndGroup(library: string, group: string) {
  return axios.get<IAllUsers[]>(
    `${WIDGET_CONFIG_URL}/GetUsers?libraryname=${library}&GroupName=${group}`
  )
}
export function getUserGroupNames(library: string) {
  return axios.get<IWidgetUserGroupNames[]>(
    `${WIDGET_CONFIG_URL}/GetUserGroupNames?libraryname=${library}`
  )
}
export function getTableFieldsPK(library: string, tableName: string) {
  return axios.get<ITableFieldsPK[]>(
    `${WIDGET_CONFIG_URL}/GetFieldsWithPrimaryKey?library=${library}&tableName=${tableName}`
  )
}
export function getPrimaryKey(library: string, tableName: string) {
  return axios.get<string>(
    `${WIDGET_CONFIG_URL}/GetPrimaryKey?library=${library}&tableName=${tableName}`
  )
}

export function updatePFLayout(data: ICoordinates[]) {
  return axios.post<any>(`${WIDGET_CONFIG_URL}/UpdatePFLayout`, data)
}

export function updateDateRange(data: IDateRange, widgetId: number) {
  return axios.post<IWidgetModel>(`${WIDGET_CONFIG_URL}/UpdateDateRange?widgetId=${widgetId}`, data)
}
