import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  svgClassName?: string
  onClick?: () => void
  title?: string
  alt?: string
}

const KTSVG: React.FC<Props> = ({
  className = '',
  path,
  svgClassName = 'mh-50px',
  onClick,
  alt,
  title,
}) => {
  return (
    <span
      {...(onClick && {onClick: onClick})}
      style={{cursor: onClick ? 'pointer' : ''}}
      className={`svg-icon ${className}`}
    >
      <SVG src={toAbsoluteUrl(path)} {...(title && {title: title})} className={svgClassName}>
        {alt && <img src={toAbsoluteUrl(path)} alt={alt} className='bg-white text-dark' />}
      </SVG>
    </span>
  )
}

export {KTSVG}
