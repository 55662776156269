import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
type IProps = {
  categoryField: string
  valueField: string
  root: am5.Root
}
const getCommonXYChartConfigs = async ({categoryField, root}: IProps) => {
  root._logo?.dispose()
  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panY: false,
      layout: root.verticalLayout,
    })
  )

  // Create Y-axis
  let yRenderer = am5xy.AxisRendererY.new(root, {
    strokeOpacity: 0.3,
    strokeWidth: 1,
    strokeDasharray: [2, 2],
  })
  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: yRenderer,
    })
  )
  yAxis.set('numberFormat', `[bold  fontSize: 1.1rem]#,###`)
  yRenderer.grid.template.setAll({strokeDasharray: [2, 2]})

  //create X-axis
  // Create X-axis (category axis)
  let xRenderer = am5xy.AxisRendererX.new(root, {
    strokeOpacity: 0.3,
    strokeWidth: 1,
    strokeDasharray: [2, 2],
    cellStartLocation: 0.1,
    cellEndLocation: 0.9,
    minGridDistance: 10,
  })
  let xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      renderer: xRenderer,
      categoryField: categoryField,
      // height: 50,
    })
  )

  xRenderer.labels.template.setAll({
    oversizedBehavior: 'truncate',
    maxWidth: 60,
    ellipsis: '...',
    tooltip: am5.Tooltip.new(root, {
      tooltipText: '{categoryX}',
      labelText: '{categoryX}',
    }),
  })
  xRenderer.grid.template.set('visible', false)
  xAxis.onPrivate('cellWidth', function (cellWidth) {
    let rotated = false
    xRenderer.labels.each(function (label) {
      if (cellWidth && label.width() > cellWidth) {
        rotated = true
      }
    })

    if (rotated) {
      xRenderer.labels.template.setAll({
        rotation: -45,
        centerX: am5.p100,
      })
    } else {
      xRenderer.labels.template.setAll({
        rotation: 0,
        centerX: am5.p50,
      })
    }
  })

  return {chart, yRenderer, yAxis, xRenderer, xAxis}
}

export {getCommonXYChartConfigs}
