import {Autocomplete, TextField} from '@mui/material'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from './icons/iconPaths'

type IProps = {
  label?: string
  id: string
  hasError: boolean
  val: string
  changedValues: (value: string) => void
  isRequired: boolean
  errorText: string
  placeHolder?: string
  items: any[]
  isDisabled?: boolean
  displayField?: string
  isFullWidth?: boolean
  disableClear?: boolean
}
const MuiSelectField = ({
  hasError,

  label,
  val = '',
  changedValues,
  errorText,
  isRequired,

  items,
  isDisabled,
  displayField,
  isFullWidth,
  disableClear,
}: IProps) => {
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   changedValues(e.target.value)
  // }
  return (
    <Autocomplete
      {...(isFullWidth && {fullWidth: true})}
      {...(disableClear && {disableClearable: true})}
      value={val}
      onChange={(event: any, newValue: string | null) => {
        newValue !== null && changedValues(newValue)
      }}
      id='controllable-states-demo'
      {...(displayField ? {options: items.map((ele) => ele[displayField])} : {options: items})}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{shrink: true}}
          InputProps={{...params.InputProps}}
          required={isRequired}
          {...(label && {label: label})}
          helperText={val === '' && hasError ? errorText : ''}
          error={val === '' && hasError ? true : false}
        />
      )}
      {...(isDisabled && {disabled: isDisabled})}
      onInputChange={(e: React.SyntheticEvent, value: string, reason: string) => {
        if (reason === 'clear') {
          changedValues('')
        }
      }}
      popupIcon={
        <KTSVG
          className='svg-icon-2x svg-icon-primary rotate-item-180'
          path={IconPaths.closeArrow}
        />
      }
    />
  )
}

export default MuiSelectField
