/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {RootState} from '../../setup'
import {App} from '../App'
import {PrivateRoutesNew} from './PrivateRoutesNew'
// import { AuthProvider } from 'oidc-react'

// const oidcConfig = {
//   onSignIn: (user: any) => {
//     console.log(user);
//   },
//   authority: process.env.REACT_APP_ID_AUTHORITY_URL,
//   clientId: 'RedmapUI6',
//   responseType: 'code',
//   scope: 'openid',
//   redirectUri: 'http://localhost:3011/signin-oidc',
// }

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  // const isAuthorized = true
  return (
    <>
      {/* <AuthProvider {...oidcConfig}>
      </AuthProvider> */}
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            {/* <Route path='directLogin/:access_token' element={<DirectLogin />} /> */}
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />

            {isAuthorized ? (
              <>
                <Route path='/*' element={<PrivateRoutesNew />} />
                <Route index element={<Navigate to='/home' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export {AppRoutes}
