import React, {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {IBarChartConfig} from '../../models/chart'
type IProps = {
  config: IBarChartConfig
}
function AmBarChart({config}: IProps) {
  const {data, categoryField, containerId, height, valueField, legendConfig, width} = config
  useLayoutEffect(() => {
    let root = am5.Root.new(containerId)
    root._logo?.dispose()
    let myTheme = am5.Theme.new(root)
    myTheme.rule('Grid', ['base']).setAll({
      strokeOpacity: 1,
      visible: true,
    })
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {panY: false, layout: root.verticalLayout})
    )
    var xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.3,
      strokeWidth: 1,
      strokeDasharray: [2, 2],
    })
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
      })
    )
    xRenderer.grid.template.set('visible', false)
    var yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.3,
      strokeWidth: 1,
      strokeDasharray: [2, 2],
    })
    yRenderer.labels.template.setAll({
      oversizedBehavior: 'truncate',
      ellipsis: '...',
      maxWidth: 50,
      tooltip: am5.Tooltip.new(root, {
        tooltipText: `{categoryY}`,
        showTooltipOn: 'hover',
      }),
      showTooltipOn: 'hover',
    })
    yRenderer.grid.template.set('visible', false)

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: categoryField,
        renderer: yRenderer,
      })
    )

    yAxis.data.setAll(data)

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',

        xAxis: xAxis,
        yAxis: yAxis,
        baseAxis: yAxis,
        valueXField: valueField,
        categoryYField: categoryField,
      })
    )
    series.columns.template.setAll({
      tooltipText: ` {categoryY} :  {valueX}`,
      tooltipY: am5.percent(90),
      cornerRadiusTR: 5,
      cornerRadiusBR: 5,
    })
    series.data.setAll(data)

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: '[#000  fontSize:1.2rem ]  {valueX}',
          fill: root.interfaceColors.get('alternativeText'),
          centerY: am5.p50,
          centerX: am5.p0,
          populateText: true,
        }),
      })
    })

    // Add legend
    if (legendConfig && legendConfig.isRequired) {
      let legend = chart.children.push(am5.Legend.new(root, {}))
      legend.data.setAll(chart.series.values)
    }
    series.appear(1000, 400)
    return () => {
      root.dispose()
    }
  }, [])

  return (
    <div
      id={containerId}
      className='py-3'
      style={{height: `${height}px`, width: width ? `${width}px` : `100%`}}
    ></div>
  )
}

export default AmBarChart
