import {createAction} from '@reduxjs/toolkit'

export const resetAllSlices = createAction<void>('reset/allSlices')

export const removeCardSessionData = () => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.includes('card')) {
      sessionStorage.removeItem(key)
    }
  })
}
