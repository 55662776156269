import {FC, ReactNode, useEffect, useState} from 'react'

import {IChartGrid, IGrid, IImportConfig, IPlugins, IReport, IWidgetModel} from '../../models/type'
import {
  getWidgetsInitialData,
  initialChartConfigData,
  initialGridConfigData,
} from '../../models/reportsInitialData'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import _ from 'lodash'
import {ConfigurationContext, validateChartConfig} from './ConfigurationSchema'
type IProps = {
  children: ReactNode
  widgetId: number
  action: 'Edit' | 'Add' | undefined
  type: IPlugins
}
type configProperties = keyof IReport
type initialConfigType = {
  [key in IPlugins]: {property: configProperties; data: IGrid | IImportConfig | IChartGrid | any}
}

const config: initialConfigType = {
  BatchUpdate: {property: 'Grid', data: {}},
  EditableGridReport: {property: 'Grid', data: {...initialGridConfigData}},
  ExportUtility: {property: 'Grid', data: {}},
  GridReport: {property: 'Grid', data: {...initialGridConfigData}},
  ImportUtility: {property: 'Grid', data: {}},
  KPIReport: {property: 'Grid', data: {}},
  WFCharts: {property: 'Chart', data: {...initialChartConfigData}},
}
export const ConfigurationContextProvider: FC<IProps> = ({
  children,
  action,
  widgetId,
  type,
}: IProps) => {
  const [widgetData, setWidgetData] = useState<IWidgetModel>(getWidgetsInitialData({plugin: type}))
  const [configData, setConfigData] = useState<IGrid | IImportConfig | IChartGrid | any>(
    config[type].data
  )

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const [configError, setConfigError] = useState<boolean>(false)
  const allWidgets: IWidgetModel[] = useSelector<RootState>(
    ({dashboard}) => dashboard.allWidgets,
    shallowEqual
  ) as IWidgetModel[]
  // const innitialWidgetData = getWidgetsInitialData({plugin: type})
  useEffect(() => {
    if (action === 'Edit') {
      let widget = allWidgets.find((ele: IWidgetModel) => ele.widgetId === widgetId)

      if (widget) {
        setWidgetData({...widget})
      }
      if (widget?.gridConfig && widget.gridConfig.Report) {
        let tempData = widget.gridConfig.Report[config[type].property]
        console.log(tempData)
        setConfigData({...tempData})
      }
    }
    setConfigError(false)
  }, [widgetId, action])
  useEffect(() => {
    let widget = allWidgets.find((ele: IWidgetModel) => ele.widgetId === widgetId)
    if (action === 'Edit' && widget && widget.gridConfig.Report) {
      let tempData = widget.gridConfig.Report[config[type].property]
      if (!_.isEqual(configData, tempData) && !isFormDirty) setIsFormDirty(true)
      if (_.isEqual(configData, tempData) && isFormDirty) setIsFormDirty(false)
    }
    if (type === 'WFCharts') {
      let res = validateChartConfig(configData)
      setConfigError(res)
    } else if (type === 'GridReport') setConfigError(configData.Table === '')
    else setConfigError(false)
  }, [configData])
  useEffect(() => {
    let widget = allWidgets.find((ele: IWidgetModel) => ele.widgetId === widgetId)
    if (action === 'Edit' && widget) {
      let temp = checkMainDataChanged(widget, widgetData)
      if (temp && !isFormDirty) setIsFormDirty(true)
      if (temp && isFormDirty) setIsFormDirty(false)
    }

    if (
      widgetData.widgetName.trim() === '' ||
      widgetData.library === '' ||
      widgetData.docGroup === ''
    )
      setConfigError(true)
    else {
      if (type === 'WFCharts') setConfigError(validateChartConfig(configData))
      else if (type === 'GridReport') setConfigError(configData.Table === '')
      else setConfigError(false)
    }
  }, [widgetData])

  const checkMainDataChanged = (initialData: IWidgetModel, newData: IWidgetModel): boolean => {
    if (
      initialData.widgetName !== newData.widgetName ||
      initialData.library !== newData.library ||
      initialData.docGroup !== newData.docGroup
    )
      return true
    else return false
  }

  return (
    <ConfigurationContext.Provider
      value={{
        widgetData,
        configData,
        setConfigData,
        isFormDirty,
        setIsFormDirty,
        setWidgetData,
        widgetType: type,
        configError,
        setConfigError,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  )
}
