import React, {useEffect, useState, useRef} from 'react'
import {RootButtons} from '../../common-utilities'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'

type IProps = {
  tableRef: any
}

const HorizontalScrollControllers = ({tableRef}: IProps) => {
  const [isScrollRequired, setScrollRequired] = useState<boolean>(false)
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false)
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false)

  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  const updateScrollButtons = () => {
    if (tableRef.current) {
      const {scrollLeft, scrollWidth, clientWidth} = tableRef.current
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth)
    }
  }

  const scrollLeft = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft = 0
      updateScrollButtons()
    }
  }

  const scrollRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft = tableRef.current.scrollWidth
      updateScrollButtons()
    }
  }

  useEffect(() => {
    const table = tableRef.current
    if (table) {
      setScrollRequired(table.scrollWidth > table.clientWidth)
      table.scrollLeft = table.scrollWidth
      updateScrollButtons()
    }

    if (!resizeObserverRef.current && table) {
      resizeObserverRef.current = new ResizeObserver(() => {
        setScrollRequired(table.scrollWidth > table.clientWidth)
        if (table.scrollWidth > table.clientWidth) table.scrollLeft = table.scrollWidth
        updateScrollButtons()
      })

      resizeObserverRef.current.observe(table)
    }

    return () => {
      if (resizeObserverRef.current && table) {
        resizeObserverRef.current.unobserve(table)
      }
    }
  }, [tableRef])

  return (
    <>
      {isScrollRequired && (
        <span className='d-flex'>
          <RootButtons.IconButton
            iconPath={IconPaths.leftArrow}
            onClick={scrollLeft}
            isDisabled={!canScrollLeft}
            title={'Scroll left'}
            iconClass={`svg-icon-3hx ${canScrollLeft ? 'svg-icon-gray-700' : 'svg-icon-gray-300'}`}
          />
          <RootButtons.IconButton
            iconPath={IconPaths.rightArrow}
            onClick={scrollRight}
            isDisabled={!canScrollRight}
            title={'Scroll right'}
            iconClass={`svg-icon-3hx ${canScrollRight ? 'svg-icon-gray-700' : 'svg-icon-gray-300'}`}
          />
        </span>
      )}
    </>
  )
}

export default HorizontalScrollControllers
