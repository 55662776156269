import React, {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {useNavigate} from 'react-router-dom'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
type IProps = {
  chartData: {category: string; amount: number; item: number}[]
}
const ProductivityChart = ({chartData}: IProps) => {
  const navigate = useNavigate()
  useLayoutEffect(() => {
    let root = am5.Root.new('productivityChart')

    let responsive = am5themes_Responsive.newEmpty(root)

    //responsive settings
    responsive.addRule({
      relevant: am5themes_Responsive.widthM,
      applying: function () {
        series.bullets.clear()
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: '[#000  fontSize:1.2rem ] {categoryX}',
              fill: root.interfaceColors.get('alternativeText'),
              centerX: am5.p0,
              centerY: am5.p50,
              populateText: true,
              rotation: 90,
            }),
          })
        })
        xAxis.set('height', 55)
      },
      removing: function () {
        series.bullets.clear()
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: '[#000  fontSize:1.2rem ] {categoryX}',
              fill: root.interfaceColors.get('alternativeText'),

              centerX: am5.p50,
              textAlign: 'center',
              centerY: am5.p0,
              populateText: true,
            }),
          })
        })
        xAxis.set('height', 30)
      },
    })

    root.setThemes([am5themes_Animated.new(root), responsive])
    const itemSeriesColor = am5.color('#00B295')
    const amountSeriesColor = am5.color('#0077CC')
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {panY: false, layout: root.verticalLayout})
    )

    // Define data

    // Create Y-axis
    let itemAxisRender = am5xy.AxisRendererY.new(root, {})
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: itemAxisRender,
      })
    )
    itemAxisRender.grid.template.setAll({strokeDasharray: [2, 2]})
    yAxis.set('numberFormat', `[bold ${itemSeriesColor} fontSize: 1.1rem]#,###`)
    // Create X-Axis
    let categoryAxisRender = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    })
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: categoryAxisRender,
        categoryField: 'category',
        //forceHidden: true,
        height: 30,
      })
    )

    categoryAxisRender.labels.template.setAll({
      inside: true,
      text: '',
    })
    categoryAxisRender.labels.template.adapters.add('dy', function (y) {
      return -chart.plotContainer.height()
    })
    categoryAxisRender.grid.template.set('forceHidden', true)
    xAxis.data.setAll(chartData)

    //amount series
    let amountAxisRenderer = am5xy.AxisRendererY.new(root, {opposite: true})
    let amountAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: amountAxisRenderer,
      })
    )

    amountAxisRenderer.grid.template.set('forceHidden', true)
    amountAxis.set('numberFormat', `[bold ${amountSeriesColor} fontSize: 1.1rem]$#a`)

    // Create item series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Total items',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'item',
        categoryXField: 'category',
        fill: itemSeriesColor,
        maskBullets: false,
      })
    )
    series.columns.template.setAll({
      tooltipText: ` {categoryX} :  {valueY}`,
      tooltipY: am5.percent(90),
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    series.columns.template.events.on('click', function (ev) {
      if (ev.target.dataItem && ev.target.dataItem.dataContext) {
        const {category}: any = ev.target.dataItem.dataContext
        navigate(`/productivityReport/${category}`)
      }
    })
    // series.bullets.push(function () {
    //   return am5.Bullet.new(root, {
    //     locationY: 0,
    //     sprite: am5.Label.new(root, {
    //       text: '[#000  fontSize:1.2rem ] {categoryX}',
    //       fill: root.interfaceColors.get('alternativeText'),
    //       centerX: am5.p0,
    //       centerY: am5.p50,
    //       populateText: true,
    //       rotation: 90,
    //     }),
    //   })
    // })

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Label.new(root, {
          text: '[#000  fontSize:1.2rem ] {categoryX}',
          fill: root.interfaceColors.get('alternativeText'),

          centerX: am5.p50,
          textAlign: 'center',
          centerY: am5.p0,
          populateText: true,
        }),
      })
    })
    //create amount series
    let amountSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Value',
        xAxis: xAxis,
        yAxis: amountAxis,
        valueYField: 'amount',
        categoryXField: 'category',
        stroke: amountSeriesColor,
      })
    )
    amountSeries.strokes.template.setAll({
      strokeWidth: 2,
    })

    amountSeries.bullets.push(function () {
      var bulletCircle = am5.Circle.new(root, {
        radius: 5,
        fill: am5.color('#fff'),
        tooltipText: ` {categoryX} : $ {valueY}`,
        tooltipY: am5.percent(90),
        stroke: amountSeriesColor,
        strokeWidth: 2,
      })
      return am5.Bullet.new(root, {
        sprite: bulletCircle,
      })
    })
    series.data.setAll(chartData)
    amountSeries.data.setAll(chartData)
    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
      })
    )
    legend.data.setAll(chart.series.values)
    series.appear(1000, 400)
    return () => {
      root.dispose()
    }
  }, [])
  return (
    <div className='w-100 h-400px'>
      <div id='productivityChart' className='py-3 px-0' style={{height: '380px'}}></div>
    </div>
  )
}

export default ProductivityChart
