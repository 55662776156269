import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'

interface IComonButtonProps {
  title: string | JSX.Element
  classes?: string
  varient?: 'outlined' | 'filled'
  color?: 'primary' | 'success' | 'info' | 'danger' | 'warning'
  isDisabled?: boolean
  type?: 'submit' | 'button'
  buttonSize?: 'sm' | 'lg' | 'md'
  [x: string]: any
}

type LinkedButtonProps = {
  to: string
  isExternalLink?: boolean
}

const LinkedButton = ({
  title,
  to,
  classes = '',
  varient = 'filled',
  color = 'primary',
  isDisabled = false,
  isExternalLink,
  buttonSize = 'lg',
}: LinkedButtonProps & IComonButtonProps) => {
  return isExternalLink ? (
    <a
      href={to}
      className={`${classes} ${
        varient === 'outlined'
          ? `btn btn-outline btn-outline-${color} text-${color}`
          : `btn-lg btn-${color} text-white`
      } btn-active-light-${color}  fw-bolder py-4 ps-8 me-5  `}
      target='_blank'
      rel='noreferrer'
    >
      {title}
    </a>
  ) : (
    <Link
      to={to}
      className={`${classes} ${
        varient === 'outlined'
          ? `btn btn-outline btn-outline-${color} text-${color}`
          : `btn-lg btn-${color} text-white`
      } btn-active-light-${color}  fw-bolder py-4 ps-8 me-5  `}
    >
      {title}
    </Link>
  )
}

type INormalButtonProps = {
  onClick: (e: any) => void
}
const NormalButton = ({
  title,
  classes = '',
  varient = 'filled',
  color = 'primary',
  onClick,
  isDisabled,
  type = 'button',
  buttonSize = 'lg',
  ...rest
}: INormalButtonProps & IComonButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`btn btn-${buttonSize} ${
        varient === 'outlined'
          ? `btn-outline btn-outline-${color} text-${color} btn-active-light-${color}`
          : `btn-${color} text-white`
      }   fw-bolder py-4 ps-8 me-5  `}
      disabled={isDisabled ? isDisabled : false}
      type={type}
      {...rest}
    >
      {title}
    </button>
  )
}

interface IconButtonProps extends IComonButtonProps, INormalButtonProps {
  iconClass?: string
  iconPath: string
  iconColor?: 'danger' | 'primary' | 'success' | 'dark'
}
const IconButton = ({
  iconPath,
  onClick,
  title,
  iconClass = 'svg-icon-2x svg-icon-primary',
  isDisabled,
  type = 'button',
  iconColor,
}: IconButtonProps) => {
  return (
    <button
      type={type}
      className={`btn   btn-icon  btn-light-${iconColor} btn-sm mx-3`}
      onClick={onClick}
      title={typeof title === 'string' ? title : ''}
      disabled={isDisabled ? isDisabled : false}
    >
      <KTSVG path={iconPath} className={`svg-icon ${iconClass}`} />
    </button>
  )
}
export {LinkedButton, NormalButton, IconButton}
