import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  badgeCount?: number
  mainClass?: string
  iconTitle?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  badgeCount,
  mainClass,
  iconTitle,
}) => {
  const {pathname} = useLocation()

  return (
    <div className={`menu-item  me-lg-1 ${mainClass ? mainClass : ''}`}>
      {badgeCount !== undefined && (
        <Link
          className={clsx('menu-link py-3  tex-decoration-none position-relative', {
            active: checkIsActive(pathname, to),
          })}
          to={to}
        >
          {icon && (
            <span className='menu-icon position-relative'>
              <KTSVG
                path={icon}
                className='svg-icon-white svg-icon-2hx'
                alt={iconTitle}
                title={iconTitle}
              />
            </span>
          )}
          <span className='badge badge-danger border border-white position-absolute top-0 end-0'>
            {badgeCount}
          </span>
        </Link>
      )}
      {badgeCount === undefined && (
        <Link
          className={clsx('menu-link py-3', {
            active: checkIsActive(pathname, to),
          })}
          to={to}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}

          {icon && (
            <span className='menu-icon'>
              <KTSVG
                path={icon}
                className='svg-icon-2hx svg-icon-white'
                alt={iconTitle}
                title={iconTitle}
              />
            </span>
          )}

          {fontIcon && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-3', fontIcon)}></i>
            </span>
          )}

          <span className='menu-title'>{title}</span>

          {hasArrow && <span className='menu-arrow'></span>}
        </Link>
      )}
    </div>
  )
}

const MenuItemExternalLink: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  badgeCount,
  mainClass,
  iconTitle,
}) => {
  const {pathname} = useLocation()

  return (
    <div className={`menu-item me-lg-1 ${mainClass ? mainClass : ''}`}>
      {badgeCount !== undefined && (
        <a
          className={clsx('menu-link py-3', {
            active: checkIsActive(pathname, to),
          })}
          href={to}
          target='_blank'
          rel='noreferrer'
        >
          {icon && (
            <span className='menu-icon'>
              <KTSVG
                path={icon}
                className='svg-icon-white svg-icon-2hx'
                alt={iconTitle}
                title={iconTitle}
              />
            </span>
          )}
          <span className='top-0 start-100 translate-middle fs-5 badge badge-circle badge-danger'>
            {badgeCount}
          </span>
        </a>
      )}
      {badgeCount === undefined && (
        <a
          className={clsx('menu-link py-3', {
            active: checkIsActive(pathname, to),
          })}
          href={to}
          target='_blank'
          rel='noreferrer'
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}

          {icon && (
            <span className='menu-icon'>
              <KTSVG
                path={icon}
                className='svg-icon-2hx svg-icon-white'
                alt={iconTitle}
                title={iconTitle}
              />
            </span>
          )}

          {fontIcon && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-3', fontIcon)}></i>
            </span>
          )}

          <span className='menu-title'>{title}</span>

          {hasArrow && <span className='menu-arrow'></span>}
        </a>
      )}
    </div>
  )
}

export {MenuItem, MenuItemExternalLink}
