import {FC} from 'react'

interface CommonTableProps {
  classes?: string
  [x: string]: any
}
export const TableContainer: FC<CommonTableProps> = (props) => {
  const {children, classes, ...rest} = props
  return (
    <div className={`table-responsive ${classes ? classes : ''}`} {...rest}>
      {children}
    </div>
  )
}

export const CustomTable: FC<CommonTableProps> = (props) => {
  const {children, classes, ...rest} = props
  return (
    <table
      className={`table align-middle  table-row-bordered table-hover fs-5 fw-bolder gy-5 dataTable no-footer bg-white ${
        classes ? classes : ''
      }`}
      {...rest}
    >
      {children}
    </table>
  )
}

export const TableHead: FC<CommonTableProps> = (props) => {
  const {children, classes, ...rest} = props
  return (
    <thead className={`${classes ? classes : ''}`} {...rest}>
      {children}
    </thead>
  )
}
export const TableBody: FC<CommonTableProps> = (props) => {
  const {children, classes, ...rest} = props
  return (
    <tbody className={`${classes ? classes : ''}`} {...rest}>
      {children}
    </tbody>
  )
}

export const TableHeaderRow: FC<CommonTableProps> = (props) => {
  const {children, classes, ...rest} = props
  return (
    <tr
      className={`text-start text-gray-500 fw-bolder fs-3  gs-0 ${classes ? classes : ''}`}
      {...rest}
    >
      {children}
    </tr>
  )
}
