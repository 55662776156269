import {useMemo, useState} from 'react'
import {KTCard, KTCardBody, KTSVG} from '../../../../../../../_metronic/helpers'

import {H2Title} from '../../../../common-utilities/ui/Headings'
import {Divider, Grid} from '@mui/material'
import {MuiSelectField, MuiTextField} from '../../../../common-utilities/ui'
import {RootButtons} from '../../../../common-utilities'
import MuiCustomSelect from '../MuiCustomSelect'
import {
  AGEINGBY_ITEMS,
  AGEING_PERIODS,
  GROUPBY_ITEMS,
  initialLevel2Values,
} from '../data-schema/FilterContext'
import {
  SortStringArray,
  toggleArrayValues,
} from '../../../../common-utilities/sorting/SortingMethods'
import {IconPaths} from '../../../../common-utilities/ui/icons/iconPaths'
import {useAsyncDebounce} from 'react-table'
import _ from 'lodash'
import {useAgedPayableSlice, useAuthSlice} from '../../../../common-utilities/hooks/storeData'
import {
  setFilterValues,
  setLevel2Filters,
  setSearchTextLevel1,
} from '../../../../../redux/AgedPayableSlice'
import {useDispatch} from 'react-redux'

import {setDefaultLibrary} from '../../../../../redux/DashboardSlice'
import SearchBox from '../../../../common-utilities/ui/SearchBox'

const Header = () => {
  // const {selected} = useListView()

  const {filterValues, libraries, loading} = useAgedPayableSlice()
  const {user} = useAuthSlice()
  const dispatch = useDispatch()
  const handleSearch = useAsyncDebounce((value: string) => {
    dispatch(setSearchTextLevel1(value))
  }, 700)

  const [filters, setFilters] = useState(filterValues)
  useMemo(() => {
    setFilters(filterValues)
  }, [filterValues])
  const [open, setOpen] = useState<boolean>(true)
  const handleGroupChange = (value: string) => {
    if (value === '') {
      filters.groupBy !== '' &&
        setFilters({
          ...filters,
          groupBy: value,
          columns: filters.columns.concat(filters.groupBy),
          hiddenColumns: [],
        })
    } else {
      let newCols = toggleArrayValues(filters.columns, value)
      if (filters.groupBy !== '') newCols = newCols.concat(filters.groupBy)

      setFilters({
        ...filters,
        groupBy: value,
        columns: [...newCols],
        hiddenColumns: [],
      })
    }
  }
  const checkEmpty = (): boolean => {
    const {ageingBy, ageingPeriod, groupBy, interval, library} = filters
    if (ageingBy === '' || ageingPeriod === '' || groupBy === '' || interval <= 0 || library === '')
      return true
    else return false
  }
  return (
    <>
      <KTCard className='mb-6'>
        <KTCardBody>
          <div
            className='d-flex align-items-center'
            onClick={() => {
              setOpen(!open)
            }}
          >
            <H2Title className='hover-underline cursor-pointer' color={'dark'}>
              Search or refine report
            </H2Title>
            <span className='linkedTitle'>
              <KTSVG
                path={open ? IconPaths.closeArrow : IconPaths.openArrow}
                className='svg-icon-primary svg-icon-4x'
              />
            </span>
          </div>

          <Grid className='mt-4' container xs={12} md={6} xl={6}>
            <Grid item>
              <div className='d-flex align-items-center position-relative my-1 px-3 '>
                <span className='svg-icon svg-icon-1 position-absolute ms-4'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-2 svg-icon-primary'
                  />
                </span>
                <SearchBox
                  handleSearch={(value: string) => {
                    handleSearch(value)
                  }}
                  value={undefined}
                  placeHolder='Refine list by supplier, approver, warehouse or entity'
                />
              </div>
            </Grid>
          </Grid>
          {open && (
            <>
              <Divider className='my-10' />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} xl={2}>
                  <MuiSelectField
                    changedValues={(value: string) => {
                      setFilters({...filters, library: value})
                    }}
                    errorText={'Select Previous Condition'}
                    hasError={false}
                    isRequired={true}
                    id={'customerSelect'}
                    items={SortStringArray(libraries, 'asc')}
                    label={'Select customer'}
                    val={filters.library}
                    isDisabled={libraries.length === 1 || loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className='d-flex'>
                  <MuiTextField
                    changedValues={(value: string) =>
                      setFilters({...filters, interval: parseInt(value)})
                    }
                    errorText=''
                    hasError={false}
                    id='interval'
                    isRequired={true}
                    val={filters.interval.toString()}
                    label='4 periods every'
                    type='number'
                    isDisabled={loading}
                  />
                  <MuiSelectField
                    changedValues={(value: string) => setFilters({...filters, ageingPeriod: value})}
                    errorText={'Select Previous Condition'}
                    hasError={false}
                    isRequired={true}
                    id={'ageingPeriods'}
                    items={AGEING_PERIODS}
                    label={'Select ageing periods'}
                    val={filters.ageingPeriod}
                    isFullWidth={true}
                    isDisabled={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={2}>
                  <MuiSelectField
                    changedValues={(value: string) => setFilters({...filters, ageingBy: value})}
                    errorText={'Select Previous Condition'}
                    hasError={false}
                    isRequired={true}
                    id={'ageingBy'}
                    items={AGEINGBY_ITEMS}
                    label={'Select ageing by'}
                    val={filters.ageingBy}
                    isDisabled={loading}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} xl={2}>
                  <MuiSelectField
                    changedValues={(value: string) => {
                      handleGroupChange(value)
                    }}
                    errorText={'Select Previous Condition'}
                    hasError={false}
                    isRequired={true}
                    id={'groupBy'}
                    items={GROUPBY_ITEMS}
                    label={'Show grouping by'}
                    val={filters.groupBy}
                    isDisabled={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <MuiCustomSelect
                    items={filters.columns}
                    onFinish={(hid, order) => {
                      setFilters({
                        ...filters,
                        columns: order,
                        hiddenColumns: hid,
                      })
                    }}
                    selectedData={filters.columns.filter(
                      (ele) => !filters.hiddenColumns.includes(ele)
                    )}
                    uniqueProp='FieldName'
                  />
                </Grid>

                <Grid item xs={12}>
                  <RootButtons.NormalButton
                    onClick={() => {
                      dispatch(setDefaultLibrary(filters.library))
                      dispatch(setFilterValues(filters))
                      dispatch(setLevel2Filters(initialLevel2Values))
                      localStorage.setItem(
                        `agedConfig_${user?.email}`,
                        JSON.stringify({filterValues: filters})
                      )
                    }}
                    title={loading ? 'loading please wait...' : 'Update report'}
                    buttonSize='lg'
                    isDisabled={loading || _.isEqual(filterValues, filters) || checkEmpty()}
                  />
                  <RootButtons.NormalButton
                    onClick={() => {
                      setOpen(false)
                    }}
                    title={'Close'}
                    varient='outlined'
                    buttonSize='lg'
                  />
                </Grid>
              </Grid>{' '}
            </>
          )}
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {Header}
