import React, {useMemo} from 'react'
import {Row} from 'react-table'
type IProps = {
  rows: Row[]
  startIndex: number
  endIndex: number
  prepareRow: (row: Row) => void
}
const VirtualRows = ({endIndex, rows, startIndex, prepareRow}: IProps) => {
  let data = useMemo(() => {
    return rows.slice(startIndex, endIndex)
  }, [endIndex, startIndex, rows])
  return (
    <>
      {data.map((row, i) => {
        prepareRow(row)
        if (row.isGrouped) {
          return (
            <>
              <tr className='bg-white' {...row.getRowProps()}>
                <td colSpan={row.cells.length - 1}>{row.cells[0].render('Cell')}</td>
              </tr>
              {/* {groupBy.length === 1 &&
                    row.subRows.map((ele) => {
                      prepareRow(ele)
                      return (
                        <>
                          <tr {...ele.getRowProps()}>
                            {ele.cells.map((cell) => {
                              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                          </tr>
                        </>
                      )
                    })} */}
            </>
          )
        } else {
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        }
      })}
    </>
  )
}

export default React.memo(VirtualRows)
