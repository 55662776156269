import React, {useContext, useEffect, useState} from 'react'
import {DashboardConfiguration, IGrid, IWidgetModel} from '../../../models/type'

import {RootState} from '../../../../../../setup'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getLibraryAndGroups} from '../../../../redux/configurations/api'
import {setDashboardConfiguration, updateWidgetList} from '../../../../redux/DashboardSlice'
import _ from 'lodash'

import {KTSVG} from '../../../../../../_metronic/helpers'

import {saveGridConfiguration} from '../../../../redux/widgets/api'
import {
  getErrorMessage,
  showErrorToast,
  showSuccessToast,
} from '../../../common-utilities/alerts/AlertModels'
import {ConfigurationContext} from '../../drawer/ConfigurationSchema'

import DataConfigForm from './DataConfigForm'

import {initialDateRangeData} from '../../../models/reportsInitialData'
import BasicConfigForm from '../../wf-charts/configuration/BasicConfigForm'
type IProps = {
  isFormDirty: boolean
  widgetData: IWidgetModel
  configData: IGrid
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>
  configError: boolean
}
type ContainerProps = {
  handleFormSubmit: () => void
}
const GridConfigForm = ({handleFormSubmit}: ContainerProps) => {
  const dispatch = useDispatch()
  const {libraries}: DashboardConfiguration = useSelector<RootState>(
    ({dashboard}) => dashboard.configurations,
    shallowEqual
  ) as DashboardConfiguration
  const username: string = useSelector<RootState>(
    ({auth}) => auth.user?.preferred_username,
    shallowEqual
  ) as string

  const {setIsFormDirty, isFormDirty, widgetData, configData, configError}: IProps =
    useContext(ConfigurationContext)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (libraries.length === 0) {
      getLibraryAndGroups().then((response) => {
        // debugger
        dispatch(setDashboardConfiguration({libraries: response.data}))
      })
    }
  }, [])

  const submit = async () => {
    setLoading(true)
    let dateRange = widgetData.gridConfig.Report.DateRange

    let grid = {
      Grid: {...configData},
      DateRange: dateRange === undefined ? {...initialDateRangeData} : {...dateRange},
    }
    let finalData: IWidgetModel = {
      ...widgetData,
      userConfig: JSON.stringify({Report: {...grid}}),
      gridConfig: {Report: {...grid}},
      owner: widgetData.widgetId === 0 ? username : widgetData.owner,
      userName: widgetData.widgetId === 0 ? username : widgetData.userName,
    }

    await saveGridConfiguration([finalData])
      .then((res) => {
        let result = res.data[0]
        dispatch(updateWidgetList({...result, gridConfig: JSON.parse(result.userConfig)}))

        setIsFormDirty(false)

        setTimeout(() => {
          setLoading(false)
          showSuccessToast({message: 'Report has been saved successfully'})
          handleFormSubmit()
        }, 1000)
      })
      .catch((err) => {
        showErrorToast({message: getErrorMessage(err)})
        setLoading(false)
        console.log(err)
      })
  }
  return (
    <>
      {!_.isEmpty(widgetData) && !_.isEmpty(configData) && (
        <>
          <div className='pb-5 current'>
            <div className='w-100'>
              <div className='d-flex mb-3 p-3 bg-light-dark'>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs030.svg'
                  className='svg-icon-2x svg-icon-dark'
                />
                <span className='fs-4 fw-boldest mx-2'>Basic information </span>
              </div>
              {/* <label className='d-flex p-3 align-items-center text-dark bg-light-dark fs-4 fw-boldest mb-4'>
                Basic Information
              </label> */}
              <BasicConfigForm />
            </div>
          </div>
          <div className='pb-5'>
            <div className='w-100'>
              <div className='d-flex mb-3 p-3 bg-light-dark'>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs027.svg'
                  className='svg-icon-2x svg-icon-dark'
                />
                <span className='fs-4 fw-boldest mx-2'>Data configuration</span>
              </div>
              {/* <label className='d-flex p-3 align-items-center text-dark bg-light-dark fs-4 fw-boldest mb-4'>
                Basic Information
              </label> */}
              <DataConfigForm />
            </div>
          </div>

          <div className='pb-5' style={{textAlign: 'right'}}>
            <button
              type='button'
              className='btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3'
              disabled={(widgetData.widgetId !== 0 && !isFormDirty) || configError}
              onClick={submit}
              title={
                widgetData.widgetId !== 0 && !isFormDirty
                  ? 'No chages'
                  : configError
                  ? 'configerror'
                  : 'Submit'
              }
            >
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Saving...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              {!loading && (
                <>
                  {' '}
                  {'Submit '}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2'
                  />
                </>
              )}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default GridConfigForm
