import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {IRulesConfig} from '../../models/type'

export const useStoreData = () => {
  const store = useSelector<RootState>((store) => store, shallowEqual) as RootState
  const {auth, dashboard} = store
  const {configurations, defaultLibrary, widgets} = dashboard
  return {auth, dashboard, dashboardConfig: configurations, defaultLibrary, widgets}
}

export const useAgedPayableSlice = () => {
  const store = useSelector<RootState>((store) => store, shallowEqual) as RootState
  const {agedPayable} = store

  return {...agedPayable}
}

export const useAuthSlice = () => {
  const store = useSelector<RootState>((store) => store, shallowEqual) as RootState
  const {auth} = store

  return {...auth}
}
export const useRuleSlice = () => {
  const ruleConfig: IRulesConfig = useSelector<RootState>(
    ({ruleConfig}) => ruleConfig,
    shallowEqual
  ) as IRulesConfig
  return {...ruleConfig}
}
