import {lazy} from 'react'
import {MenuDataTypes} from '../modules/dashboard/models/type'
const ImportRulePanel = lazy(
  () => import('../modules/dashboard/settings/rules/components/ImportRulePanel')
)
const RevisionPanel = lazy(
  () => import('../modules/dashboard/settings/rules/components/RevisionPanel')
)

const RulesSetup = lazy(() => import('../modules/dashboard/settings/rules/RulesSetup'))
const RuleConfigPanel = lazy(
  () => import('../modules/dashboard/settings/rules/components/RuleConfigPanel')
)
const UnderConstruction = lazy(
  () => import('../modules/dashboard/common-utilities/ui/UnderConstruction')
)
const ruleBuilderRoutes: MenuDataTypes[] = [
  {
    linkPath: '/:action/:id',
    routePath: '/:action/:id',
    component: RuleConfigPanel,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'ruleConfigForm',
    isLazy: true,
    title: 'Configuration Form',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
  },
  {
    linkPath: '/revisions/:id',
    routePath: '/revisions/:id',
    component: RevisionPanel,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'ruleRevisions',
    isLazy: true,
    title: 'Rule Revisions',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
  },
  {
    linkPath: '/import',
    routePath: '/import',
    component: ImportRulePanel,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'ruleImport',
    isLazy: true,
    title: 'Import rule',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
  },
]
export const configurationRoutes: MenuDataTypes[] = [
  {
    linkPath: '/rule-builder',
    routePath: '/rule-builder',
    component: RulesSetup,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'rule-builder',
    isLazy: true,
    title: 'Rule Builder',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
    subMenu: [...ruleBuilderRoutes],
  },
  {
    linkPath: '/designer',
    routePath: '/designer',
    component: UnderConstruction,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'designer',
    isLazy: true,
    title: 'Designer',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
    subMenu: [],
  },
  {
    linkPath: '/validations',
    routePath: '/validations',
    component: UnderConstruction,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'validations',
    isLazy: true,
    title: 'Validations',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
    subMenu: [],
  },
  {
    linkPath: '/jobs',
    routePath: '/jobs',
    component: UnderConstruction,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'jobs',
    isLazy: true,
    title: 'Jobs',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
    subMenu: [],
  },
  {
    linkPath: '/field-mapping',
    routePath: '/field-mapping',
    component: UnderConstruction,
    navigatePath: undefined,
    isForDirectLogin: false,
    show: true,
    key: 'field-mapping',
    isLazy: true,
    title: 'Field Mapping',
    icon: '',
    role: [],
    disabled: false,
    isMenuLink: true,
    subMenu: [],
  },
]
