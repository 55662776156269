import {useState, useEffect} from 'react'
import {getAgedPayableSummaryCardData} from '../../../redux/kpi/api'
import {IAgedPayableSummaryCard} from '../../models/type'
import {useAgedPayableSlice} from '../../common-utilities/hooks/storeData'
import {getErrorMessage} from '../../common-utilities/alerts/AlertModels'
import {SortStringArray} from '../../common-utilities/sorting/SortingMethods'
import {ErrorText} from '../../common-utilities/ui/Headings'
import {ContentLoading} from '../../common-utilities/loading/ContentLoading'
import {MuiSelectField} from '../../common-utilities/ui'
import {setDefaultLibrary} from '../../../redux/DashboardSlice'
import {useDispatch} from 'react-redux'
import {FiltersProps} from '../detail-view/components/data-schema/FilterContext'
import {setLibrary} from '../../../redux/AgedPayableSlice'
import {setUserDefaultLibrary} from '../../../redux/CommonApiCalls'
import AgedPayableChart from './AgedPayableChart'
import ChartContainerHoc from '../../common-utilities/ui/containers/ChartContainerHoc'
const AgedPayableReport = () => {
  const [reportData, setReportData] = useState<IAgedPayableSummaryCard | undefined>()

  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const {filterValues, libraries} = useAgedPayableSlice()
  useEffect(() => {
    getData(filterValues)
    return () => {
      setReportData(undefined)
      setLoading(false)
      setError('')
    }
  }, [filterValues])
  const getData = (data: FiltersProps) => {
    const {library} = data
    if (library !== '') {
      setLoading(true)
      setError('')
      getAgedPayableSummaryCardData({
        ...filterValues,
        ageingBy: filterValues.ageingBy === 'Invoice date' ? 'InvoiceDate' : 'DueDate',
      })
        .then((res) => {
          setLoading(false)
          if (res && res.data) setReportData(res.data)

          //setUserDefaultLibrary(library)
        })
        .catch((err) => {
          setLoading(false)
          setError(getErrorMessage(err))
        })
    } else {
      setError('No library selected')
    }
  }

  return (
    <>
      <ChartContainerHoc
        cardClasses='h-500px'
        header={{
          title: <span>{`Aged payables summary`}</span>,
          descriptions: 'Updates on your aged payables summary page will reflect below.',
          linkProps: {
            link: '/agedPayableSummary',
            linkTitle: 'View aged payable summary',
            isDisabled: loading || error !== '',
          },
        }}
      >
        {loading && <ContentLoading />}
        {error !== '' && (
          <div className='d-inline-block'>
            <ErrorText className='my-4'>{`${error}`}</ErrorText>

            {libraries.length > 1 && (
              <MuiSelectField
                changedValues={(value: string) => {
                  dispatch(setLibrary(value))
                  dispatch(setDefaultLibrary(value))
                }}
                errorText={'Select Previous Condition'}
                hasError={false}
                isRequired={true}
                id={'defaultLib'}
                items={SortStringArray(libraries, 'asc')}
                label={'Select library'}
                val={filterValues.library}
              />
            )}
          </div>
        )}

        {reportData && <AgedPayableChart mainData={reportData} />}
      </ChartContainerHoc>
    </>
  )
}

export default AgedPayableReport
