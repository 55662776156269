import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../setup/redux/RootReducer'
import storage from 'redux-persist/lib/storage'
import {
  FiltersProps,
  IAgedPayableSlice,
  level2FiltersProps,
} from '../dashboard/widgets/detail-view/components/data-schema/FilterContext'
import {IAgedPayableSummaryData} from '../dashboard/models/type'
import {persistReducer} from 'redux-persist'
import {resetAllSlices} from './resetActions'
const initialFiltersValues: FiltersProps = {
  library: '',
  ageingBy: 'Invoice date',
  ageingPeriod: 'Day',
  groupBy: 'Supplier',
  interval: 7,
  columns: ['Entity', 'Warehouse', 'Approver', 'Quantity'],
  hiddenColumns: [],
}
const initialLevel2Values: level2FiltersProps = {
  columns: [
    'Supplier',
    'Entity',
    'Approver',
    'Status',
    'Warehouse',
    'Invoice number',
    'Invoice date',
    'Due date',
    'Purchase Order number',
    'Project code',
    'Shipment number',
    'Currency rate',
    'Filing date',
  ],
  hiddenColumns: [],
}
export const initialAgedPayableSlice: IAgedPayableSlice = {
  filterValues: initialFiltersValues,
  detailedViewProp: [],
  libraries: [],
  level2Filters: initialLevel2Values,

  loading: false,
  searchTextLevel1: '',
  searchTextLevel2: '',
}
export const agedPayableSlice = createSlice({
  name: 'agedPayable',
  initialState: initialAgedPayableSlice,
  reducers: {
    startCall: (state: IAgedPayableSlice, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setFilterValues: (state: IAgedPayableSlice, action: PayloadAction<FiltersProps>) => {
      state.filterValues = action.payload
    },
    setLevel2Filters: (state: IAgedPayableSlice, action: PayloadAction<level2FiltersProps>) => {
      state.level2Filters = action.payload
    },
    setDetailedViewProp: (state: IAgedPayableSlice, action: PayloadAction<string[]>) => {
      state.detailedViewProp = action.payload
    },
    setReportData: (
      state: IAgedPayableSlice,
      action: PayloadAction<IAgedPayableSummaryData | undefined>
    ) => {
      state.reportData = action.payload
    },
    setError: (state: IAgedPayableSlice, action: PayloadAction<string>) => {
      state.error = action.payload
      if (action.payload.length > 0) state.isError = true
      else state.isError = false
    },
    setSearchTextLevel1: (state: IAgedPayableSlice, action: PayloadAction<string>) => {
      state.searchTextLevel1 = action.payload
    },
    setSearchTextLevel2: (state: IAgedPayableSlice, action: PayloadAction<string>) => {
      state.searchTextLevel2 = action.payload
    },
    setLibraries: (state: IAgedPayableSlice, action: PayloadAction<string[]>) => {
      state.libraries = action.payload
    },
    setLibrary: (state: IAgedPayableSlice, action: PayloadAction<string>) => {
      state.filterValues = {...state.filterValues, library: action.payload}
    },
    resetAgedPayableSlice: () => initialAgedPayableSlice,
  },
  extraReducers(builder) {
    builder.addCase(resetAllSlices, () => initialAgedPayableSlice)
  },
})

export const agedPayableReducer = persistReducer(
  {storage, key: 'AgedPayable'},
  agedPayableSlice.reducer
)

export const {
  startCall,
  setDetailedViewProp,
  setFilterValues,
  setLevel2Filters,
  setReportData,
  setError,
  setSearchTextLevel1,
  setSearchTextLevel2,
  setLibraries,
  setLibrary,
} = agedPayableSlice.actions

export const agedPayable = (state: RootState) => state.agedPayable

export default agedPayableSlice.reducer
