import React, {useEffect} from 'react'
import {IWidgetModel} from './models/type'
import CardPanel from './widgets/cards/CardPanel'
import AgedPayableReport from './widgets/kpi-report/AgedPayableReport'
import './layouts.scss'
import {Grid} from '@mui/material'
import {useDispatch} from 'react-redux'
import {removeCardSessionData, resetAllSlices} from '../redux/resetActions'
import {useAgedPayableSlice, useAuthSlice} from './common-utilities/hooks/storeData'
import ProductivityReportPanel from './widgets/productivity-report/ProductivityReportPanel'
type LayoutProps = {
  widgets: IWidgetModel[]
  isDraggable: boolean
  user?: string
}
const StaticLayout = ({widgets, isDraggable}: LayoutProps) => {
  const dispatch = useDispatch()
  const {user} = useAuthSlice()
  const {filterValues, level2Filters} = useAgedPayableSlice()
  const resetAllStates = () => {
    if (filterValues.library !== '')
      localStorage.setItem(
        `agedConfig_${user?.email}`,
        JSON.stringify({filterValues, level2Filters})
      )
    dispatch(resetAllSlices)
    removeCardSessionData()
  }
  useEffect(() => {
    window.addEventListener('beforeunload', resetAllStates)
    return () => {
      window.removeEventListener('beforeunload', resetAllStates)
    }
  }, [])
  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <CardPanel />
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <AgedPayableReport />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <ProductivityReportPanel />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default StaticLayout
