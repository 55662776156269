import React, {useMemo} from 'react'
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from '@progress/kendo-react-excel-export'
import {CSVLink} from 'react-csv'
import {ExcelColumns} from '../level-1'
import {LabelKeyObject} from 'react-csv/components/CommonPropTypes'
import {GridPDFExport} from '@progress/kendo-react-pdf'
import {Grid, GridCellProps, GridColumn, GridHeaderCellProps} from '@progress/kendo-react-grid'
import {FiltersProps} from '../data-schema/FilterContext'
import {USDollar} from '../../../../common-utilities/sorting/SortingMethods'
import moment from 'moment'
import {dateFormats} from '../../../../common-utilities/ui/Standards'
import {IPDFPaperSize} from '../../../../models/type'
type ExportProps = {
  seriesData: any
  reference: React.LegacyRef<ExcelExport>
  columns: ExcelColumns[]
  name: string | undefined
}
const AgedPayableExcel = ({seriesData, reference, columns, name = 'report'}: ExportProps) => {
    const CustomFooter = (FieldName: string) => {
        let unique: any = [];
        let total: number = 0;
        seriesData.forEach((item: any) => {
            if (!unique.includes(item["itemId"])) {
                unique.push(item["itemId"]);
                total += item[FieldName];
            }
        });

        return total;
  }
  const TotalFooter = (FieldName: string) => {
    const total = seriesData.reduce((acc: number, current: any) => acc + current[FieldName], 0)
    return total
  }

  return (
    <div>
      <ExcelExport data={seriesData} fileName={`${name}.xlsx`} ref={reference}>
        <ExcelExportColumnGroup title={name} headerCellOptions={{textAlign: 'center', bold: true}}>
          {columns?.map((item, index) => {
            if (index === 0) {
              return (
                <ExcelExportColumn
                  field={item.FieldName}
                  title={item.caption ? item.caption.toUpperCase() : item.FieldName.toUpperCase()}
                  key={index}
                  locked={true}
                  footerCellOptions={{
                    background: '#7A7A7A',
                    textAlign: 'left',
                    color: '#fff',
                    fontFamily: 'bold',
                  }}
                  footer={() => `Totals AUD - including tax`}
                />
              )
            } else if (item.isCurrency) {
              return (
                <ExcelExportColumn
                  field={item.FieldName}
                  title={item.caption ? item.caption.toUpperCase() : item.FieldName.toUpperCase()}
                  key={index}
                  footerCellOptions={{
                    background: '#feffb8',
                    textAlign: 'right',
                    format: '$#,##0.00',
                  }}
                  footer={() => CustomFooter(item.FieldName)}
                  cellOptions={{format: '$#,##0.00'}}
                />
              )
            } else {
              return (
                <ExcelExportColumn
                  field={item.FieldName}
                  title={item.caption ? item.caption.toUpperCase() : item.FieldName.toUpperCase()}
                  key={index}
                  footerCellOptions={{
                    background: '#feffb8',
                    textAlign: 'right',
                  }}
                  {...(item.totalRequired && {footer: () => TotalFooter(item.FieldName)})}
                />
              )
            }
          })}
        </ExcelExportColumnGroup>
      </ExcelExport>
    </div>
  )
}
type ExportPropsCSV = {
  seriesData: any
  reference: React.LegacyRef<CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}>
  gridData: ExcelColumns[]
  name: string | undefined
}
const CSVExportFile = ({seriesData, reference, name, gridData}: ExportPropsCSV) => {
  const header: LabelKeyObject[] = useMemo(() => {
    return gridData?.map(
      (item, index): LabelKeyObject => ({
        label: item.caption ? item.caption.toUpperCase() : item.FieldName?.toUpperCase(),
        key: item.FieldName,
      })
    )
  }, [gridData])
  return (
    <>{header && <CSVLink data={seriesData} ref={reference} filename={name} headers={header} />}</>
  )
}
type ExportPropsPdf = {
  reference: React.LegacyRef<GridPDFExport>
  gridData: ExcelColumns[]
  name: string | undefined
  seriesData: any
  totalAmount?: number
  landscape?: boolean
  paperSize?: IPDFPaperSize
}
const PDFExports = ({
  reference,
  gridData,
  name,
  seriesData,
  totalAmount,
  landscape = false,
  paperSize,
}: ExportPropsPdf) => {
  const TotalFooter = (FieldName: string) => {
    const total = seriesData.reduce((acc: number, current: any) => acc + current[FieldName], 0)
    return total
  }
  return (
    <GridPDFExport
      fileName={name}
      ref={reference}
      landscape={landscape}
      margin={'0.5cm'}
      scale={0.7}
      repeatHeaders={true}
      paperSize={paperSize}
    >
      <Grid data={seriesData} rowHeight={50}>
        <GridColumn
          headerCell={(props) => {
            return (
              <th
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '1.2rem',
                }}
              >
                {name}
              </th>
            )
          }}
        >
          {gridData.map((ele, index) => {
            return (
              <GridColumn
                id={`${index}`}
                key={index}
                field={ele.FieldName}
                title={ele.caption ? ele.caption.toUpperCase() : ele.FieldName.toUpperCase()}
                headerCell={(e: GridHeaderCellProps) => <th>{e.title}</th>}
                cell={(props: GridCellProps) => {
                  let {dataItem, field = ''} = props
                  let val = ele.isCurrency
                    ? USDollar.format(dataItem[field].toFixed(2))
                    : dataItem[field] !== null && dataItem[field]
                    ? dataItem[field]
                    : ''
                  if (ele.isCurrency) return <td style={{textAlign: 'end'}}>{val}</td>
                  else if (ele.FieldType === 'datetime' && val !== '') {
                    let date = moment(val)
                    return <td>{date.format(dateFormats.onTable)}</td>
                  } else return <td>{val}</td>
                }}
                footerCell={() => {
                  if (ele.isCurrency && totalAmount) {
                    let tot = TotalFooter(ele.FieldName)
                    let p = (tot / totalAmount) * 100
                    return (
                      <td style={{textAlign: 'end', fontSize: '1rem'}}>
                        <div>{p.toFixed(2)}%</div>
                        <div>{USDollar.format(tot.toFixed(2))}</div>
                      </td>
                    )
                  } else return <td></td>
                }}
              />
            )
          })}
        </GridColumn>
      </Grid>
    </GridPDFExport>
  )
}
type PDFProps = {
  data: any[]
  columns: ExcelColumns[]
  filters: FiltersProps
}
const PDFData = ({columns, data}: PDFProps) => {
  return (
    <div className='table-responsive'>
      <table
        className={`table align-middle  table-row-bordered  fs-6 gy-5 dataTable no-footer firstColSticky`}
      >
        <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 mainTableHeaderRow'>
            {columns.map((ele, index) => {
              return <th key={`${ele.FieldName.trim()}_${index}`}>{ele.FieldName}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((ele, index) => {
            return (
              <tr>
                {columns.map((item, childIndex) => {
                  return (
                    <td key={`${item.FieldName}_${index}_${childIndex}`}>{ele[item.FieldName]}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
export {AgedPayableExcel, CSVExportFile, PDFExports, PDFData}
