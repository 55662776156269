import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../setup/redux/RootReducer'

import {arrayCRUD, arrayModdify} from '../dashboard/common-utilities/sorting/SortingMethods'
import {IWhereClause} from '../dashboard/models/oldRuleType'
import {
  IActionFields,
  IConditions,
  IGroupBy,
  IJoins,
  IMappingData,
  IOrderBy,
  IRuleActions,
  IRuleBasics,
  IRuleConfigData,
  IRulesConfig,
  IRulesWhere,
  ISourceTable,
  IRules,
  IQueryValidation,
  IAliasData,
} from '../dashboard/models/type'

const initialState: IRulesConfig = {
  currentDocGroup: '',
  currentLibrary: '',
  joins: [],
  ruleList: [],
  selectedRule: undefined,
  ruleBasics: {
    context: '',
    join: '',
    ruleName: '',
    type: 'Rule',
  },
  where: [],
  recordFound: [],
  recordNotFound: [],
  isLoading: false,
  groupBy: [],
  orderBy: [],
  mappingData: [],
  currentMapping: {
    condition: [],
    joins: [],
    selectedFields: [],
    sourceTable: '',
    targetTable: '',
    type: 'recordFound',
    id: 0,
  },
  ruleConfigData: [],
  isFormDirty: false,
  finalQuery: '',
  whereClause: [],
  aliasData: [],
  auditOnFailed: '',
  auditOnPassed: '',
  commentOnFailed: '',
  commentOnPassed: '',
  msgPromptOnFailed: '',
  msgPromptOnPassed: '',
  ruleRevisions: [],
  isDraftVersion: false,
  globalSearch: '',
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const ruleConfigSlice = createSlice({
  name: 'ruleConfig',
  initialState: initialState,
  reducers: {
    startCall: (state: IRulesConfig, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    resetConfig: (state: IRulesConfig, action: PayloadAction<{resetAll: boolean}>) => {
      const {resetAll} = action.payload
      if (resetAll) return initialState
      else {
        let prevState = state
        return {
          ...initialState,
          currentDocGroup: prevState.currentDocGroup,
          currentLibrary: prevState.currentLibrary,
          currentDocGroupId: prevState.currentDocGroupId,
          ruleConfigData: prevState.ruleConfigData,
          ruleList: prevState.ruleList,
        }
      }
    },
    updateConfig: (state: any, action: PayloadAction<any>) => {
      let temp = state
      let data = action.payload

      let finalData = {...temp, ...data}

      return {...finalData}
    },
    setCurrentLibraryAndDocGrp: (
      state: IRulesConfig,
      action: PayloadAction<{library?: string; docGroup?: string; docGroupId?: number}>
    ) => {
      const {docGroup, docGroupId, library} = action.payload
      if (docGroup !== undefined) state.currentDocGroup = docGroup
      if (library !== undefined) state.currentLibrary = library

      if (docGroupId !== undefined) state.currentDocGroupId = docGroupId
    },
    setRuleBasics: (state: IRulesConfig, action: PayloadAction<Partial<IRuleBasics>>) => {
      const temp: IRuleBasics = Object.assign(state.ruleBasics, action.payload)
      state.ruleBasics = temp
    },
    setRuleList: (state: IRulesConfig, action: PayloadAction<IRules[]>) => {
      state.ruleList = action.payload
    },
    setJoinsData: (state: IRulesConfig, action: PayloadAction<IJoins[]>) => {
      state.joins = action.payload
    },
    updateJoinConditionData: (
      state: IRulesConfig,
      action: PayloadAction<{id: number; data: IJoins}>
    ) => {
      const {data} = action.payload

      let newData = arrayModdify(state.joins, data, 'update', 'id')
      state.joins = [...newData]
    },
    updateWhere: (
      state: IRulesConfig,
      action: PayloadAction<{data: IRulesWhere; operation: 'update' | 'delete'}>
    ) => {
      let {data, operation} = action.payload

      let temp = arrayCRUD(state.where, data, operation, 'field')
      state.where = [...temp]
    },
    updateActionData: (
      state: IRulesConfig,
      action: PayloadAction<{
        type: 'recordFound' | 'recordNotFound'
        data: IRuleActions
        operation: 'update' | 'delete' | 'addNew'
        targetFields?: Array<string>
        autoPopulate?: boolean
      }>
    ) => {
      const {data, type, operation, targetFields, autoPopulate} = action.payload
      let finalData = data
      let sourceData = data.tableSources

      if (
        operation !== 'delete' &&
        autoPopulate &&
        sourceData.length > 0 &&
        targetFields !== undefined
      ) {
        let sourceObj: ISourceTable = sourceData[0]
        let actionFields: IActionFields[] = sourceObj.fieldList
          .filter((item) => targetFields.includes(item.fieldName.toLowerCase()))
          .map((ele, index) => {
            return {
              fieldName: ele.fieldName,
              sourceTableName: sourceObj.tableName,
              sourceFieldName: ele.fieldName,
              isAppend: false,
              isConstantValue: false,
              constantValue: '',
              separator: '',
              isConstantQuery: false,
              eventID: index + 1,
              id: index + 1,
            }
          })
        finalData = {...finalData, targetFields: actionFields}
      }
      let newData = arrayModdify(state[type], finalData, operation, 'eventID')
      state[type] = [...newData]
    },
    updateActionFields: (
      state: IRulesConfig,
      action: PayloadAction<{
        type: 'recordFound' | 'recordNotFound'
        data: IActionFields
        operation: 'update' | 'delete' | 'addNew'
        mainEventId: number
      }>
    ) => {
      const {data, type, operation, mainEventId} = action.payload

      let temp = state[type].find((ele) => ele.eventID === mainEventId)
      if (temp) {
        let fields = temp.targetFields
        let lastindex = fields.length === 0 ? 1 : fields[temp.targetFields.length - 1].id + 1
        if (operation === 'addNew') {
          let datatoUpdate = {...data, eventID: lastindex, id: lastindex}
          temp.targetFields = arrayModdify(fields, datatoUpdate, operation, 'id')
        } else temp.targetFields = arrayModdify(fields, data, operation, 'id')

        let newData = arrayModdify(state[type], temp, 'update', 'eventID')
        state[type] = [...newData]
      }
    },
    updateRuleList: (
      state: IRulesConfig,
      action: PayloadAction<{
        configData?: IRuleConfigData
        listData?: IRules
        operation: 'update' | 'addNew' | 'delete'
        isOldRule?: boolean
      }>
    ) => {
      const {configData, listData, operation, isOldRule} = action.payload

      let existingList = state.ruleList
      let existing = state.ruleConfigData
      if (listData) {
        let newList = arrayModdify(existingList, listData, operation, 'id')
        state.ruleList = [...newList]
      }
      if (configData) {
        let newData = arrayCRUD(existing, configData, 'update', 'id')
        state.ruleConfigData = [...newData]
      }
      if (isOldRule !== undefined) state.isOldRule = isOldRule
      state.isFormDirty = false
    },
    setRuleForEdit: (state: IRulesConfig, action: PayloadAction<number>) => {
      const id = action.payload
      let data = state.ruleConfigData.find((ele) => ele.id === id)
      if (data) {
        state.ruleBasics = data.ruleBasics
        state.joins = data.joinClause ? data.joinClause : []
        state.recordFound = data.recordFound
        state.recordNotFound = data.recordNotFound
        state.where = data.where
        state.selectedRule = id
        state.groupBy = data.groupBy
        state.orderBy = data.orderBy
        state.mappingData = data.mappingData
        state.useCustomQuery = data.useCustomQuery
        state.finalQuery = data.finalQuery
        state.recordFoundQuery = data.recordFoundQuery
        state.recordNotFoundQuery = data.recordNotFoundQuery
        state.customCondition = data.customCondition
        state.whereClause = data.whereClause ? data.whereClause : []
        state.auditOnFailed = data.auditOnFailed ? data.auditOnFailed : ''
        state.auditOnPassed = data.auditOnPassed ? data.auditOnPassed : ''
        state.msgPromptOnFailed = data.msgPromptOnFailed ? data.msgPromptOnFailed : ''
        state.msgPromptOnPassed = data.msgPromptOnPassed ? data.msgPromptOnPassed : ''
        state.commentOnFailed = data.commentOnFailed ? data.commentOnFailed : ''
        state.commentOnPassed = data.commentOnPassed ? data.commentOnPassed : ''
      }
    },
    updateConditions: (
      state: IRulesConfig,
      action: PayloadAction<{data: IConditions[]; operation: 'update' | 'delete'}>
    ) => {
      let {data} = action.payload

      // let temp = arrayCRUD(state.where, data, operation, 'field')
      state.where = [...data]
    },
    updateGroupBy: (
      state: IRulesConfig,
      action: PayloadAction<{data: IGroupBy[]; operation: 'update' | 'delete'}>
    ) => {
      let {data} = action.payload

      // let temp = arrayCRUD(state.where, data, operation, 'field')
      state.groupBy = [...data]
    },
    updateOrderBy: (
      state: IRulesConfig,
      action: PayloadAction<{data: IOrderBy[]; operation: 'update' | 'delete'}>
    ) => {
      let {data} = action.payload

      // let temp = arrayCRUD(state.where, data, operation, 'field')
      state.orderBy = [...data]
    },
    updateSourceData: (
      state: IRulesConfig,
      action: PayloadAction<{
        data: ISourceTable[]
        mainIndex: number
        dataType: 'recordFound' | 'recordNotFound'
      }>
    ) => {
      let {data, mainIndex, dataType} = action.payload

      let temp = state[dataType].map((ele, index) => {
        if (mainIndex === index) return {...ele, sourceData: data}
        else return {...ele}
      })
      state[dataType] = [...temp]
    },
    updateActionFieldsAll: (
      state: IRulesConfig,
      action: PayloadAction<{
        type: 'recordFound' | 'recordNotFound'
        targetTable: string
        data: IActionFields[]
        operation: 'update' | 'delete'
      }>
    ) => {
      const {data, targetTable, type} = action.payload

      let temp = state[type].find((ele) => ele.targetTable === targetTable)
      if (temp) {
        // let fields = temp.targetFields
        temp.targetFields = data

        let newData = [...state[type].filter((obj) => obj.targetTable !== targetTable), {...temp}]
        state[type] = [...newData]
      }
    },
    UpdateCurrentMappingData: (
      state: IRulesConfig,
      action: PayloadAction<Partial<IMappingData>>
    ) => {
      if (state.currentMapping !== null && action.payload !== undefined) {
        const temp: IMappingData = Object.assign(state.currentMapping, action.payload)
        //debugger
        state.currentMapping = temp
      }
    },
    updateMappingData: (
      state: IRulesConfig,
      action: PayloadAction<{
        data: IMappingData
        mainIndex?: number | undefined
        operation: 'update' | 'delete' | 'addNew'
      }>
    ) => {
      let {data, operation} = action.payload

      let temp: IMappingData[] = []
      temp = arrayModdify(state.mappingData, data, operation, 'id')

      state.mappingData = [...temp]
    },
    deleteActionFields: (
      state: IRulesConfig,
      action: PayloadAction<{
        type: 'recordFound' | 'recordNotFound'
        targetTable?: string
        items: number[]
        mainEventId: number
      }>
    ) => {
      const {type, items, mainEventId} = action.payload

      let temp: IRuleActions | undefined = state[type].find((ele) => ele.eventID === mainEventId)
      if (temp !== undefined) {
        let fields = temp.targetFields
        temp.targetFields = fields.filter((ele, ind) => !items.includes(ind))

        let newData: IRuleActions[] = state[type].map((obj: IRuleActions) => {
          if (obj.eventID === mainEventId && temp !== undefined) return {...temp}
          else return {...obj}
        })

        state[type] = [...newData]
      }
    },
    updateUseCustomCondition: (state: IRulesConfig, action: PayloadAction<boolean>) => {
      state.useCustomQuery = action.payload

      if (action.payload) {
        state.finalQuery = `Select * from ${state.currentDocGroup} as a \n Where (a.itemid = '#itemID#')`
      } else {
        state.finalQuery = ''
        state.recordFoundQuery = ''
        state.recordNotFoundQuery = ''
      }
    },
    updateCustomQuery: (
      state: IRulesConfig,
      action: PayloadAction<{
        field: 'finalQuery' | 'recordFoundQuery' | 'recordNotFoundQuery'
        value: string
      }>
    ) => {
      const {field, value} = action.payload
      state[field] = value
    },
    updateFormStatus: (state: IRulesConfig, action: PayloadAction<boolean>) => {
      state.isFormDirty = action.payload
    },
    updateQueryValidationStatus: (
      state: IRulesConfig,
      action: PayloadAction<{
        field: 'isCustomConditionValid' | 'isRFQueryValid' | 'isRNFQueryValid'
        data: IQueryValidation | undefined
      }>
    ) => {
      const {field, data} = action.payload
      state[field] = data
    },
    updateCurrentCondition: (
      state: IRulesConfig,
      action: PayloadAction<{data: IWhereClause; id?: number}>
    ) => {
      const {data} = action.payload
      state.currentEditingCondition = data
    },
    updateWhereClause: (
      state: IRulesConfig,
      action: PayloadAction<{
        data: IWhereClause
        id?: number
        operation: 'addNew' | 'update' | 'delete'
      }>
    ) => {
      const {data, operation} = action.payload

      state.whereClause = arrayModdify(state.whereClause, data, operation, 'index')
    },
    setWhereClause: (state: IRulesConfig, action: PayloadAction<{data: IWhereClause[]}>) => {
      const {data} = action.payload
      state.whereClause = data
    },
    setAliasData: (state: IRulesConfig, action: PayloadAction<{data: IAliasData[]}>) => {
      const {data} = action.payload
      state.aliasData = data
    },
    setActionData: (
      state: IRulesConfig,
      action: PayloadAction<{
        type: 'recordFound' | 'recordNotFound'
        data: IRuleActions[]
      }>
    ) => {
      const {data, type} = action.payload
      state[type] = [...data]
    },
    setMessages: (
      state: IRulesConfig,
      action: PayloadAction<{
        field:
          | 'commentOnPassed'
          | 'commentOnFailed'
          | 'auditOnPassed'
          | 'auditOnFailed'
          | 'msgPromptOnPassed'
          | 'msgPromptOnFailed'
        value: string
      }>
    ) => {
      const {field, value} = action.payload
      state[field] = value
    },
    updateRuleId: (state: IRulesConfig, action: PayloadAction<number>) => {
      state.selectedRule = action.payload
    },
    setRuleRevisions: (state: IRulesConfig, action: PayloadAction<IRuleConfigData[]>) => {
      state.ruleRevisions = action.payload
    },
    setRuleVersionForEdit: (state: IRulesConfig, action: PayloadAction<string>) => {
      const revisionNo = action.payload
      let data = state.ruleRevisions.find((ele) => ele.revisionNo === revisionNo)
      if (data) {
        state.ruleBasics = data.ruleBasics
        state.joins = data.joinClause ? data.joinClause : []
        state.recordFound = data.recordFound
        state.recordNotFound = data.recordNotFound
        state.where = data.where
        state.selectedRule = data.id
        state.groupBy = data.groupBy
        state.orderBy = data.orderBy
        state.mappingData = data.mappingData
        state.useCustomQuery = data.useCustomQuery
        state.finalQuery = data.finalQuery
        state.recordFoundQuery = data.recordFoundQuery
        state.recordNotFoundQuery = data.recordNotFoundQuery
        state.customCondition = data.customCondition
        state.whereClause = data.whereClause ? data.whereClause : []
        state.auditOnFailed = data.auditOnFailed ? data.auditOnFailed : ''
        state.auditOnPassed = data.auditOnPassed ? data.auditOnPassed : ''
        state.msgPromptOnFailed = data.msgPromptOnFailed ? data.msgPromptOnFailed : ''
        state.msgPromptOnPassed = data.msgPromptOnPassed ? data.msgPromptOnPassed : ''
        state.commentOnFailed = data.commentOnFailed ? data.commentOnFailed : ''
        state.commentOnPassed = data.commentOnPassed ? data.commentOnPassed : ''
      }
    },
    setDraftVersionStatus: (state: IRulesConfig, action: PayloadAction<boolean>) => {
      state.isDraftVersion = action.payload
    },
    setGlobalSearch: (state: IRulesConfig, action: PayloadAction<string>) => {
      state.globalSearch = action.payload
    },
  },
})

export const {
  startCall,
  resetConfig,
  updateConfig,
  setCurrentLibraryAndDocGrp,
  setRuleList,
  setRuleBasics,
  setJoinsData,
  updateJoinConditionData,
  updateWhere,
  updateActionData,
  updateActionFields,
  updateRuleList,
  setRuleForEdit,
  updateConditions,
  updateGroupBy,
  updateOrderBy,
  updateSourceData,
  updateActionFieldsAll,
  UpdateCurrentMappingData,
  updateMappingData,
  deleteActionFields,
  updateCustomQuery,
  updateUseCustomCondition,
  updateFormStatus,
  updateQueryValidationStatus,
  updateCurrentCondition,
  updateWhereClause,
  setWhereClause,
  setAliasData,
  setActionData,
  setMessages,
  updateRuleId,
  setRuleRevisions,
  setRuleVersionForEdit,
  setDraftVersionStatus,
  setGlobalSearch,
} = ruleConfigSlice.actions

export const ruleConfig = (state: RootState) => state.ruleConfig

export default ruleConfigSlice.reducer
