import usePagination from '@mui/material/usePagination/usePagination'

type IProps = {
  currentPage: number
  goBack: () => void
  goNext: () => void
  goFirst?: () => void
  goLast?: () => void
  totalPages: number
  goToPage: (page: number) => void
}
const Pagination = ({goBack, goNext, goToPage, totalPages}: IProps) => {
  const {items} = usePagination({
    count: totalPages,
  })
  return (
    <div className='d-flex align-items-center flex-wrap'>
      {items.map(({page, type, selected, disabled, ...item}, index) => {
        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          return <div>...</div>
        } else if (type === 'previous') {
          return (
            <button
              onClick={(e) => {
                goBack()
                item.onClick(e)
              }}
              className='btn btn-lg btn-outline btn-outline-primary page-btn page-btn-back'
              disabled={disabled}
            >
              Back
            </button>
          )
        } else if (type === 'next') {
          return (
            <button
              onClick={(e) => {
                goNext()
                item.onClick(e)
              }}
              className='btn btn-lg btn-outline btn-outline-primary page-btn page-btn-next'
              disabled={disabled}
            >
              Next
            </button>
          )
        } else if (type === 'first' || type === 'last') {
          return <div>{type}</div>
        } else {
          return (
            <button
              onClick={(e) => {
                page && goToPage(page - 1)
                item.onClick(e)
              }}
              className={`btn btn-lg mx-1 ${
                selected ? 'btn-primary text-white' : 'btn-outline btn-outline-primary'
              } page-item-btn`}
              disabled={disabled}
            >
              {page}
            </button>
          )
        }
      })}
    </div>
  )
}

export default Pagination
