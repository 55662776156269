import React from 'react'
import {ColumnInstance} from 'react-table'
import {MuiSelectField, MuiTextField} from '../../common-utilities/ui'
type IProps = {
  column: ColumnInstance
}
const SelectColumnFilter = ({column}: IProps): JSX.Element => {
  const {filterValue, setFilter, preFilteredRows, id} = column
  // Calculate the options for filtering
  // using the preFilteredRows

  const options: any[] = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <MuiSelectField
      changedValues={(value: string) => setFilter(value || undefined)}
      errorText={'Select Previous Condition'}
      hasError={false}
      isRequired={false}
      id={'columnFilter'}
      items={options}
      label={'Select filter'}
      val={filterValue}
    />
  )
}

const DefaultColumnFilter = ({column: {filterValue, preFilteredRows, setFilter}}: IProps) => {
  const count = preFilteredRows.length

  return (
    <MuiTextField
      changedValues={(value: string) => setFilter(value || undefined)}
      errorText='Rule name already exist'
      hasError={false}
      id='columnFilterDefault'
      isRequired={false}
      label='Search'
      val={filterValue || ''}
      placeHolder={`Search ${count} records...`}
      isFullWidth={true}
    />
  )
}

const NumberRangeColumnFilter = ({
  column: {filterValue = [], preFilteredRows, setFilter, id},
}: IProps) => {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0

    preFilteredRows.forEach((row) => {
      min = Math.min(parseInt(row.values[id]), min)
      max = Math.max(parseInt(row.values[id]), max)
    })

    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <MuiTextField
        changedValues={(value: string) => {
          setFilter((old = []) => [value && value !== '' ? parseInt(value, 10) : undefined, old[1]])
        }}
        errorText={''}
        hasError={false}
        isRequired={false}
        id={`minFilter`}
        label={'Min'}
        val={filterValue[0] || ''}
        type={'number'}
        placeHolder={`Min (${min})`}
      />
      <div>to</div>
      <MuiTextField
        changedValues={(value: string) => {
          setFilter((old = []) => [old[0], value && value !== '' ? parseInt(value, 10) : undefined])
        }}
        errorText={''}
        hasError={false}
        isRequired={false}
        id={`maxFilter`}
        label={'Max'}
        val={filterValue[1] || ''}
        type={'number'}
        placeHolder={`Max (${max})`}
      />
    </>
  )
}

export {SelectColumnFilter, DefaultColumnFilter, NumberRangeColumnFilter}
