import {FC, useEffect} from 'react'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../_metronic/layout/core'
import Level1DataTable from './components/level-1'
import {Header} from './components/level-1/Header'
import Level2DataTable from './level-2'
import * as Header2 from './level-2/Header'
import {useAgedPayableSlice} from '../../common-utilities/hooks/storeData'
import {getAgedPayableSummaryData} from '../../../redux/kpi/api'
import {useDispatch} from 'react-redux'
import {
  setDetailedViewProp,
  setError,
  setReportData,
  setSearchTextLevel1,
  setSearchTextLevel2,
  startCall,
} from '../../../redux/AgedPayableSlice'
import {checkForAgedData, getErrorMessage} from '../../common-utilities/alerts/AlertModels'
import {useNavigate} from 'react-router'

const SummaryView: FC = () => {
  const {filterValues} = useAgedPayableSlice()

  useEffect(() => {
    getData()
    return () => {
      dispatch(startCall(false))
      dispatch(setError(''))
      dispatch(setSearchTextLevel1(''))
    }
  }, [filterValues])

  const dispatch = useDispatch()

  const getData = () => {
    if (filterValues.library !== '') {
      dispatch(startCall(true))
      dispatch(setReportData(undefined))
      getAgedPayableSummaryData({
        ...filterValues,
        ageingBy: filterValues.ageingBy === 'Invoice date' ? 'InvoiceDate' : 'DueDate',
      })
        .then((res) => {
          dispatch(setSearchTextLevel1(''))
          dispatch(setReportData(res.data))
          dispatch(setError(''))
          checkForAgedData(res.data.statusUpdated, res.data.lastModifiedDate)
        })
        .catch((err) => {
          dispatch(setError(getErrorMessage(err)))
        })
        .finally(() => {
          dispatch(startCall(false))
          //setUserDefaultLibrary(filterValues.library).then((res) => {})
        })
    }
  }

  return (
    <>
      <PageTitle>Aged payables summary</PageTitle>
      <Header />

      <KTCard>
        <KTCardBody className='py-4'>
          <Level1DataTable />
        </KTCardBody>
      </KTCard>
    </>
  )
}

const DrillDownView: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {detailedViewProp, reportData} = useAgedPayableSlice()
  useEffect(() => {
    if (reportData === undefined || detailedViewProp.length === 0) {
      navigate('/agedPayableSummary')
    }
    return () => {
      dispatch(setDetailedViewProp([]))
      dispatch(setSearchTextLevel2(''))
    }
  }, [])
  return (
    <>
      <PageTitle>Aged payables details</PageTitle>
      <Header2.Header />
      <KTCard>
        <KTCardBody>
          <Level2DataTable />
        </KTCardBody>
      </KTCard>
    </>
  )
}
export {DrillDownView, SummaryView}
