import React, {useContext, useEffect, useState} from 'react'
import {IChartGrid, IWidgetModel, IWidgetTableFields} from '../../../models/type'
import {Grid} from '@mui/material'
import MuiSelectField from '../../../common-utilities/ui/MuiSelectField'
import {getTableFields, getTables} from '../../../../redux/configurations/api'
import {getErrorMessage, showErrorToast} from '../../../common-utilities/alerts/AlertModels'
import MuiCheckBox from '../../../common-utilities/ui/MuiCheckBox'
import {ConfigurationContext} from '../../drawer/ConfigurationSchema'
import ReactCodeMirror from '@uiw/react-codemirror'
import {langs} from '@uiw/codemirror-extensions-langs'
import {EditorView} from '@codemirror/view'
type IProps = {
  isFormDirty: boolean
  widgetData: IWidgetModel
  configData: IChartGrid
  setConfigData: React.Dispatch<React.SetStateAction<IChartGrid>>
  configError: boolean
}
const DataConfigForm = () => {
  const {configData, widgetData, setConfigData, configError}: IProps =
    useContext(ConfigurationContext)

  const [tables, setTables] = useState<string[]>([])
  const [tableFields, setTableFields] = useState<IWidgetTableFields[]>([])

  useEffect(() => {
    if (widgetData.library === '') {
      // setConfigData({...initialData})
    } else {
      loadTables()
    }
  }, [widgetData.library])
  useEffect(() => {
    if (configData.Table !== '' && widgetData.library !== '') loadTableFields()
  }, [configData.Table])

  const onTableChange = (value: string) => {
    if (value !== '') {
      getTableFields(widgetData.library, value)
        .then((res) => {
          setTableFields(res.data)

          setConfigData({...configData, Table: value, CategoryField: ''})
        })
        .catch((err) => {
          showErrorToast({message: getErrorMessage(err)})
        })
    } else {
      setConfigData({...configData, Table: value, CategoryField: ''})
      setTableFields([])
    }
  }
  const loadTables = async () => {
    getTables(widgetData.library, true)
      .then((res) => {
        setTables(res.data.map((ele) => ele.name))
      })
      .catch((err) => {
        showErrorToast({message: 'Cannot load tables'})
      })
  }
  const loadTableFields = async () => {
    getTableFields(widgetData.library, configData.Table)
      .then((res) => {
        setTableFields(res.data)
      })
      .catch((err) => {
        showErrorToast({message: 'Table Fields: \n' + getErrorMessage(err)})
      })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MuiSelectField
            changedValues={(value: string) => {
              setConfigData({...configData, ChartType: value})
            }}
            errorText='Select chart type'
            hasError={false}
            id='chartType'
            isRequired={true}
            label='Chart type'
            val={configData.ChartType}
            items={['pie', 'area', 'bar', 'column', 'line', 'radarLine']}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiSelectField
            changedValues={(value: string) => {
              onTableChange(value)
            }}
            errorText='Select a table'
            hasError={false}
            id='table'
            isRequired={true}
            label='Table'
            val={configData.Table}
            items={tables}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiSelectField
            changedValues={(value: string) => setConfigData({...configData, CategoryField: value})}
            errorText={'Select Field'}
            hasError={false}
            isRequired={true}
            id={'ParentField'}
            items={tableFields}
            displayField={'fieldName'}
            label={'Field'}
            val={configData.CategoryField}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiCheckBox
            changedValues={(val: boolean) =>
              setConfigData({...configData, ExecuteQuery: val ? 'True' : 'False'})
            }
            id='executeQuery'
            label={'Execute custom query'}
            val={
              configData?.ExecuteQuery
                ? configData?.ExecuteQuery.toLowerCase() === 'false'
                  ? false
                  : true
                : false
            }
          />
        </Grid>
        {configData.ExecuteQuery && configData.ExecuteQuery.toLowerCase() === 'true' && (
          <>
            <Grid item xs={12}>
              <ReactCodeMirror
                className='mb-4 text-wrap form-control'
                key={`customQuery`}
                value={configData.SeriesQuery}
                extensions={[langs.sql(), EditorView.lineWrapping]}
                onChange={(val) => {
                  setConfigData({...configData, SeriesQuery: val})
                }}
              />
              {configError &&
                configData.ExecuteQuery.toLowerCase() === 'true' &&
                configData.SeriesQuery === '' && (
                  <div className='text-danger'>Please enter query</div>
                )}
            </Grid>
            <Grid item xs={12}>
              <MuiCheckBox
                changedValues={(val: boolean) =>
                  setConfigData({...configData, AllowClickThrough: val ? 'Yes' : 'No'})
                }
                id='allowClickThrough'
                label={'Allow click through query'}
                val={configData.AllowClickThrough.toLowerCase() === 'no' ? false : true}
              />
            </Grid>
            {configData.AllowClickThrough.toLowerCase() === 'yes' && (
              <Grid item xs={12}>
                <ReactCodeMirror
                  className='mb-4 text-wrap form-control'
                  key={`clickThroughQuery`}
                  value={configData.ClickThroughQuery}
                  extensions={[langs.sql(), EditorView.lineWrapping]}
                  onChange={(val) => {
                    setConfigData({...configData, ClickThroughQuery: val})
                  }}
                />
                {configError &&
                  configData.AllowClickThrough.toLowerCase() === 'yes' &&
                  configData.ClickThroughQuery === '' && (
                    <div className='text-danger'>Please enter query</div>
                  )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

export default DataConfigForm
