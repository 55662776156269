import {useContext, useEffect, useState} from 'react'
import {Dashboard, DashboardConfiguration} from '../../../models/type'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../../setup'
import {Grid} from '@mui/material'
import MuiTextField from '../../../common-utilities/ui/MuiTextField'
import MuiSelectField from '../../../common-utilities/ui/MuiSelectField'
import {discardWArning} from '../../../common-utilities/alerts/AlertModels'
import {ConfigurationContext} from '../../drawer/ConfigurationSchema'
import {initialChartConfigData} from '../../../models/reportsInitialData'

const BasicConfigForm = () => {
  const {libraries}: DashboardConfiguration = useSelector<RootState>(
    ({dashboard}) => dashboard.configurations,
    shallowEqual
  ) as DashboardConfiguration
  const {allWidgets}: Dashboard = useSelector<RootState>(
    ({dashboard}) => dashboard,
    shallowEqual
  ) as Dashboard

  const {widgetData, setWidgetData, widgetType, setConfigData} = useContext(ConfigurationContext)

  const [hasError, setHasError] = useState<boolean>(false)
  const [docGroupList, setDocGroupList] = useState<string[]>([])
  const loadDocGroups = async (library: string) => {
    if (libraries.length !== 0 || libraries !== undefined) {
      let temp = libraries.find((ele) => ele.name === library)
      if (temp !== undefined && temp.documentGroups !== undefined) {
        setDocGroupList(
          temp.documentGroups.map((item) => {
            return item.name
          })
        )
      }
    }
  }
  useEffect(() => {
    loadDocGroups(widgetData.library)
  }, [widgetData.library])
  const handleLibraryChange = async (value: string) => {
    if (widgetData.widgetId !== 0 && widgetData.library !== '') {
      let res = await discardWArning(
        undefined,
        'Changing the library will discarded the existing configuration . Do you want to continue ?'
      )
      if (res) {
        setWidgetData({...widgetData, library: value, docGroup: ''})
        setConfigData({...initialChartConfigData})
        // loadDocGroups(value)
      }
    } else {
      setWidgetData({...widgetData, library: value})
      setConfigData({...initialChartConfigData})
      // loadDocGroups(value)
    }
  }
  const checkForDuplicate = () => {
    if (allWidgets && allWidgets.length !== 0) {
      if (
        allWidgets.find(
          (ele) =>
            ele.widgetName === widgetData.widgetName &&
            ele.widgetId !== widgetData.widgetId &&
            ele.plugin === widgetType
        )
      )
        return true
      else return false
    } else return false
  }

  return (
    <>
      <Grid container spacing={3} className='p-4'>
        <Grid item xs={12}>
          <MuiTextField
            changedValues={(value: string) => {
              setWidgetData({...widgetData, widgetName: value})

              setHasError(false)
            }}
            errorText='Report name already exist'
            hasError={hasError}
            id='widgetName'
            isRequired={true}
            label='Report name'
            val={widgetData.widgetName}
            placeHolder='Enter report name'
            onBlur={() => {
              setHasError(checkForDuplicate())
            }}
            isFullWidth={true}
          />
        </Grid>
        {/*end::Form Group */}

        {/*begin :: form group */}

        <Grid item xs={12} sm={12}>
          <MuiSelectField
            changedValues={(value: string) => {
              handleLibraryChange(value)
            }}
            errorText='Select a library'
            hasError={false}
            id='library'
            isRequired={true}
            label='Library'
            val={widgetData.library}
            items={libraries.map((ele) => ele.name)}
            isDisabled={widgetData.widgetId !== 0}
          />
        </Grid>

        {/*end :: form group */}

        <Grid item xs={12} sm={12}>
          <MuiSelectField
            changedValues={(value: string) => {
              setWidgetData({...widgetData, docGroup: value})
            }}
            errorText='Select a docGroup'
            hasError={false}
            id='docGroup'
            isRequired={true}
            label='DocGroup'
            val={widgetData.docGroup}
            items={docGroupList}
            isDisabled={widgetData.widgetId !== 0}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default BasicConfigForm
