import {Checkbox, FormControlLabel} from '@mui/material'
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IconPaths} from './icons/iconPaths'
type IProps = {
  label: string | JSX.Element
  id: string
  val: boolean | undefined
  changedValues: (value: boolean) => void
  name?: string
  isDisabled?: boolean
  styleClass?: string
}
const MuiCheckBox = ({
  changedValues,
  id,
  label,
  val = false,
  name,
  isDisabled,
  styleClass = '',
}: IProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changedValues(e.target.checked)
  }
  return (
    <>
      <FormControlLabel
        label={label}
        className={styleClass}
        control={
          <Checkbox
            checked={val}
            onChange={handleChange}
            id={id}
            {...(name && {name: name})}
            {...(isDisabled && {disabled: isDisabled})}
            checkedIcon={<KTSVG path={IconPaths.checked} className='svg-icon-2x svg-icon-white' />}
            icon={<KTSVG path={IconPaths.unchecked} className='svg-icon-2x svg-icon-white' />}
          />
        }
      />
    </>
  )
}

export default MuiCheckBox
