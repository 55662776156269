import React, {useState, useEffect} from 'react'
import {checkIsActive, KTSVG} from '../../../helpers'
import {Link, useLocation} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as action from '../../../../app/modules/redux/DashboardSlice'
import {RootState} from '../../../../setup'
// import {MenuRouteData} from '../../../../app/modules/dashboard/common-utilities/menu/MenuRouteData'
import {MenuDataTypes} from '../../../../app/modules/dashboard/models/type'
import {useLayout} from '../../core'
import useRouteData from '../../../../app/routing/routes'
import {Tooltip, TooltipProps, Zoom, styled, tooltipClasses} from '@mui/material'
import {IconPaths} from '../../../../app/modules/dashboard/common-utilities/ui/icons/iconPaths'
const CustomAsideMenu = () => {
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const {pathname} = useLocation()
  const RouteData = useRouteData()
  const directLogin: boolean = useSelector<RootState>(
    ({auth}) => auth.directLoggedIn,
    shallowEqual
  ) as boolean
  const asideCollapsed: boolean = useSelector<RootState>(
    ({dashboard}) => dashboard.asideCollapsed,
    shallowEqual
  ) as boolean
  const {classes} = useLayout()

  // const {aside} = config
  //Code to hide aside menu from specific pages
  // const urls = ['/utilities/editableGrid/:id']
  // useEffect(() => {
  //   let temp = urls.filter((ele) => {
  //     return matchPath(ele, pathname) != null
  //   })
  //   if (temp.length > 0) setDisplay(false)
  //   else setDisplay(true)
  // }, [pathname])

  const [isMobile, setIsMobile] = useState<boolean>(false)
  // const [mainMenuTitle, setMainMenuTitle] = useState<string>('Home')
  // const [currentLevel, setCurrentLevel] = useState<number>(0)
  // const [levelIndex, setLevelIndex] = useState<number[]>([])
  // const [usersBreadcrumbs, setUsersBreadcrumbs] = useState<PageLink[]>([
  //   {
  //     title: 'Home',
  //     path: '/home',
  //     isSeparator: false,
  //     isActive: true,
  //     isLink: true,
  //   },
  // ])
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200 ? true : false)
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize() // Set initial class based on the screen size

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // const handleSubMenu = (index: number) => {
  //   if (currentMenu !== undefined) {
  //     const data = currentMenu[index]

  //     setMainMenuTitle(data.title)
  //     setCurrentMenu(data.subMenu)
  //     setLevelIndex([...levelIndex, index])
  //     setUsersBreadcrumbs([
  //       ...usersBreadcrumbs,
  //       {title: data.title, path: data.path, isSeparator: true, isActive: true, isLink: false},
  //     ])
  //     setCurrentLevel((state) => state + 1)
  //     if (data.path !== '') navigate(data.path)
  //   }
  // }
  const handleWidth = () => {
    dispatch(action.toggleCollapsableMenu(!asideCollapsed))
  }
  // const handleBack = () => {
  //   let level = currentLevel - 1
  //   if (level === 0) {
  //     setMainMenuTitle('Home')
  //     setCurrentMenu(menuData)
  //     setCurrentLevel(0)
  //     setLevelIndex([])
  //     setUsersBreadcrumbs([
  //       {
  //         title: 'Home',
  //         path: '/home',
  //         isSeparator: false,
  //         isActive: true,
  //         isLink: true,
  //       },
  //     ])
  //     navigate('/home')
  //   } else {
  //     let tempIndex = levelIndex
  //     tempIndex.pop()

  //     let tempMenu = menuData
  //     let menutitle = ''
  //     tempIndex.forEach((element) => {
  //       let temp = tempMenu[element]
  //       tempMenu = temp.subMenu
  //       menutitle = temp.title
  //     })
  //     setMainMenuTitle(menutitle)
  //     setCurrentMenu(tempMenu)
  //     setLevelIndex(tempIndex)
  //     setCurrentLevel(level)
  //     let te = usersBreadcrumbs
  //     te.pop()
  //     setUsersBreadcrumbs([...te])
  //   }
  // }
  const MenuItem = ({item}: {item: MenuDataTypes}) => {
    const {disabled, icon, isForDirectLogin, linkPath, title, redirectLink} = item
    const isActive = checkIsActive(pathname, linkPath) ? 'active' : ''
    const isDisabled = directLogin ? (isForDirectLogin ? false : true) : disabled ? true : false

    return (
      <div className={`menu-item ${isActive} ${isDisabled ? 'disabled' : ''}`}>
        {isDisabled ? (
          <span className='menu-link'>
            <span className='menu-icon'>
              <KTSVG
                path={icon}
                className='svg-icon-2hx svg-icon-white'
                alt={title}
                title={title}
              />
            </span>
            <span className='menu-title text-white'>{title}</span>
          </span>
        ) : (
          <>
            {redirectLink ? (
              <a className='menu-link' href={`${redirectLink}`} target='_blank' rel='noreferrer'>
                <LightTooltip title={title} disableHoverListener={!asideCollapsed}>
                  <span className='menu-icon'>
                    <KTSVG
                      path={icon}
                      className='svg-icon-2hx svg-icon-white'
                      alt={title}
                      title={title}
                    />
                  </span>
                </LightTooltip>
                <span className='menu-title text-white'>{title}</span>
              </a>
            ) : (
              <Link className='menu-link' to={linkPath}>
                <LightTooltip title={title} disableHoverListener={!asideCollapsed}>
                  <span className='menu-icon'>
                    <KTSVG
                      path={icon}
                      className='svg-icon-2hx svg-icon-white'
                      alt={title}
                      title={title}
                    />
                  </span>
                </LightTooltip>
                <span className='menu-title text-white'>{title}</span>
              </Link>
            )}
          </>
        )}
      </div>
    )
  }
  const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{popper: className}}
      placement='right'
      TransitionComponent={Zoom}
    />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#054988',
      color: '#fff',
      border: '2px solid #fff',
      fontSize: '1.2rem',
      borderRadius: '7%',
    },
  }))
  return (
    <>
      <div
        id='kt_aside'
        className={`aside-light ${isMobile ? 'drawer' : ''} ${classes.aside.join(' ')} ${
          asideCollapsed ? 'w-50px' : 'w-200px'
        }`}
        style={{
          background: '#054988',
          color: '#ffffff',
          height: '100vh',
          display: 'flex',
          flexShrink: 0,
          flexDirection: 'column',
          padding: '0px',
          transition: 'width 0.3s',
        }}
        data-kt-drawer='true'
        data-kt-drawer-name='aside'
        data-kt-drawer-activate='{default: true, xl: false}'
        data-kt-drawer-overlay='true'
        data-kt-drawer-direction='start'
        data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      >
        {/*begin::Brand*/}
        <div
          className={`aside-logo d-flex flex-stack ${
            asideCollapsed ? 'justify-content-center' : ''
          } px-4 py-10`}
          id='kt_aside_logo'
        >
          {/*begin::Logo*/}
          <div
            className={`fw-boldest aside-menuTitle  fs-2 text-white ${
              asideCollapsed ? 'd-none' : 'd-block'
            }`}
            onClick={isMobile ? undefined : handleWidth}
          >
            Menu
          </div>
          {/*end::Logo*/}
          {/*begin::Aside toggler*/}
          {isMobile ? (
            <div className='btn btn-icon  btn-active-color-primary ' id='kt_aside_mobile_toggle'>
              <KTSVG
                className='svg-icon svg-icon-white svg-icon-1'
                title='Close menu'
                alt='Close'
                path={IconPaths.crossClose}
              />
            </div>
          ) : (
            <div className='btn btn-icon  btn-active-color-primary' onClick={handleWidth}>
              <span className='svg-icon svg-icon-white svg-icon-1'>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr079.svg*/}
                {asideCollapsed ? (
                  <KTSVG path='/media/icons/duotune/arrows/arr023.svg' />
                ) : (
                  <KTSVG path='/media/icons/duotune/arrows/arr022.svg' />
                )}
              </span>
              {/*end::Svg Icon*/}
            </div>
          )}
          {/*end::Aside toggler*/}
        </div>
        {/*end::Brand*/}
        {/*begin::Aside menu*/}
        <div
          className={`${asideCollapsed ? 'aside-menu-collapsed' : 'aside-menu'} flex-column-fluid`}
        >
          {/*begin::Aside Menu*/}
          <div
            className='hover-scroll-overlay-y my-5 my-lg-5'
            id='kt_aside_menu_wrapper'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-offset={0}
          >
            {/*begin::Menu*/}
            <div
              className='menu menu-column menu-title-gray-800 menu-state-title-primary  menu-state-bullet-primary menu-arrow-gray-500'
              id='#kt_aside_menu'
              data-kt-menu='true'
              data-kt-menu-expand='false'
            >
              {RouteData !== undefined &&
                RouteData.filter((ele) => ele.show && ele.isMenuLink).map((ele, index) => {
                  return (
                    <React.Fragment key={`${ele.title.trim()}_${index}`}>
                      <MenuItem item={ele} />
                    </React.Fragment>
                  )
                })}
            </div>
            {/*end::Menu*/}
          </div>
          {/*end::Aside Menu*/}
        </div>
        {/*end::Aside menu*/}
        {!asideCollapsed && (
          <div
            className='aside-footer d-flex flex-column align-items-center justify-content-center pt-5 pb-7 px-5 fs-4'
            id='kt_aside_footer'
          >
            <div className='text-white order-2 order-md-1'>
              <span className='text-white fw-bold me-2'>
                {` @ `}
                {new Date().getFullYear()}{' '}
              </span>
              <span className='text-white'>redmap</span>
            </div>
            <div className='text-white order-2 order-md-1'>
                          <span>{`${process.env.REACT_APP_VERSION}`}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CustomAsideMenu
