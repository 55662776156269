export const IconPaths = {
  delete: '/media/svg/general/trashCan.svg',
  dragIcon: '/media/svg/general/dragIcon.svg',
  infoIcon: '/media/svg/general/info.svg',
  closeArrow: '/media/icons/duotune/arrows/arr073.svg',
  openArrow: '/media/icons/duotune/arrows/arr072.svg',
  taskMenuIcon: '/media/svg/general/tasks.svg',
  uploadMenuIcon: '/media/svg/general/uploads.svg',
  adminMenuIcon: '/media/svg/general/administrationNew.svg',
  notificationIcon: '/media/svg/general/notificationNew.svg',
  reportsIcon: '/media/svg/general/reportNew.svg',
  requestsIcon: '/media/svg/general/requests.svg',
  invalidIcon: '/media/icons/duotune/general/gen044.svg',
  validIcon: '/media/icons/duotune/general/gen043.svg',
  clockIcon: '/media/icons/duotune/general/gen013.svg',
  ascIcon: '/media/svg/general/sort_asc.svg',
  descIcon: '/media/svg/general/sort_desc.svg',
  threeDots_H: '/media/icons/duotune/general/gen052.svg',
  threeDots_V: '/media/icons/duotune/general/gen053.svg',
  configWheel: '/media/icons/duotune/coding/cod001.svg',
  homeIcon: '/media/svg/general/homeNew.svg',
  search: '/media/icons/duotune/general/gen021.svg',
  helpIcon: '/media/icons/duotune/general/gen046.svg',
  configMenuIcon: '/media/svg/general/configurationNew.svg',
  AddNew: '/media/icons/duotune/abstract/abs011.svg',
  profile: '/media/svg/general/profileNew.svg',
  faq: '/media/svg/general/faqNew.svg',
  searchMenuIcon: '/media/svg/general/search.svg',
  filledCircle: '/media/icons/duotune/abstract/abs050.svg',
  excelImageIcon: '/media/svg/general/excelExpt.svg',
  pdfIcon: '/media/svg/general/pdfExpt.svg',
  excelSvgIcon: '/media/svg/general/xlsx_Export.svg',
  csvIcon: '/media/svg/general/csvExpt.svg',
  pdfFileIcon: '/media/svg/general/pdfFile.svg',
  crossClose: '/media/icons/duotune/arrows/arr061.svg',
  toastSuccess: '/media/svg/general/toastSuccess.svg',
  toastWarning: '/media/svg/general/toastWarning.svg',
  toastDanger: '/media/svg/general/toastDanger.svg',
  toastPrimary: '/media/svg/general/toastPrimary.svg',
  trendIndicator: '/media/svg/general/trendIndicator.svg',
  agedPayableCardGraphic: '/media/svg/general/agedPayablecard.svg',
  configWheelNew: '/media/svg/general/configWheelNew.svg',
  feedback: '/media/svg/general/feedback.svg',
  checked: '/media/svg/general/checked.svg',
  unchecked: '/media/svg/general/unchecked.svg',
  hmburger: '/media/icons/duotune/abstract/abs015.svg',
  pieChart: '/media/icons/duotune/graphs/gra007.svg',
  columnChart: '/media/icons/duotune/graphs/gra005.svg',
  donutChart: '/media/icons/duotune/graphs/gra006.svg',
  barChart: '/media/icons/duotune/text/txt001.svg',
  lineChart: '/media/icons/duotune/abstract/abs017.svg',
  areaChart: '/media/icons/duotune/graphs/gra003.svg',
  cardInfoIcon: '/media/icons/duotune/general/gen045.svg',
  expandIcon: '/media/icons/duotune/arrows/arr076.svg',
  shrinkIcon: '/media/icons/duotune/arrows/arr092.svg',
  upArrow: '/media/icons/duotune/arrows/arr066.svg',
  downArrow: '/media/icons/duotune/arrows/arr065.svg',
  rightArrow: '/media/icons/duotune/arrows/arr064.svg',
  leftArrow: '/media/icons/duotune/arrows/arr063.svg',
  chainLink: '/media/icons/duotune/coding/cod007.svg',
}

export const OPERATORS = ['=', '<>', '>', '<', '<=', '>=', 'LIKE', 'IN', 'NOT LIKE']
