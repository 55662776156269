import {useRef} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {IconPaths} from '../../../../common-utilities/ui/icons/iconPaths'
import {AgedPayableExcel, CSVExportFile, PDFExports} from './AgedPayableExcel'
import {ExcelColumns} from '../level-1'
import {CSVLink} from 'react-csv'
import {ExcelExport} from '@progress/kendo-react-excel-export'
import {showLoadingToast, showWarningToast} from '../../../../common-utilities/alerts/AlertModels'
import {left, right} from '@popperjs/core'
import {GridPDFExport} from '@progress/kendo-react-pdf'
import {IPDFPaperSize, PDFRequestProps, PdfBodyKnownProps} from '../../../../models/type'

import axios from 'axios'
export type IPropsExports = {
  isExcelRequired?: boolean
  isCsvRequired?: boolean
  isPdfRequired?: boolean
  data: any[]
  columns: ExcelColumns[]
  totalForPerRow?: number
  fileName: string
  excelExtraConfig?: {
    freezRow?: number
    freezCol?: number
  }
  pdfLandscape?: boolean
  pdfPaperSize?: IPDFPaperSize
  serverPdf?: {
    url: string
    body: PDFRequestProps
  }
  serverPdfExtraProps?: PdfBodyKnownProps
}
const ExportHoc = ({
  columns,
  data,
  totalForPerRow,
  isCsvRequired = true,
  isExcelRequired = true,
  fileName,
  excelExtraConfig,
  isPdfRequired = true,
  pdfLandscape = false,
  pdfPaperSize = 'auto',
  serverPdf,
  serverPdfExtraProps,
}: IPropsExports) => {
  const _csvExport = useRef<CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}>(null)
  const _export = useRef<ExcelExport>(null)

  const handleCsvExport = async () => {
    if (_csvExport.current !== null) {
      const processing = showLoadingToast({message: 'Downloading...'})
      try {
        _csvExport.current.link.click()
        setTimeout(() => {
          showLoadingToast({
            message: 'Successfully downloaded',
            toastId: processing,
            type: 'success',
          })
        }, 400)
      } catch (err) {
        showLoadingToast({message: 'Download failed', toastId: processing, type: 'error'})
      }
    }
  }

  const handleExcelExport = async () => {
    //   setIsLoading(true)
    const processing = showLoadingToast({message: 'Downloading...'})
    setTimeout(() => {
      if (_export.current !== null) {
        let options = _export.current.workbookOptions()
        let sheets = options.sheets
        try {
          if (sheets) {
            let sheet = sheets[0]
            if (sheet && sheet.rows) {
              let length = sheet.rows.length
              let lastRow = sheet.rows[length - 1]
              if (excelExtraConfig && excelExtraConfig.freezCol)
                sheet.frozenColumns = excelExtraConfig.freezCol

              if (excelExtraConfig && excelExtraConfig.freezRow)
                sheet.frozenRows = excelExtraConfig.freezRow

              if (lastRow && lastRow.cells && totalForPerRow) {
                let cells = columns.map((ele, index) => {
                  if (index === 0) {
                    return {
                      background: '#7A7A7A',
                      color: '#fff',
                      value: 'Percentage of total',
                      textAlign: left,
                    }
                  } else if (ele.isCurrency) {
                    let tot = lastRow.cells ? lastRow.cells[index].value : '$ 0.0'
                    let val = tot ? parseFloat(tot.toString().replace('$', '')) : 0.0

                    let p = (val / totalForPerRow) * 100
                    return {
                      background: '7E8299',
                      color: '#333',
                      value: `${p.toFixed(2)} %`,
                      textAlign: right,
                    }
                  } else {
                    return {
                      background: '7E8299',
                      color: '#333',
                      value: ``,
                      textAlign: right,
                    }
                  }
                })

                let newRow = {
                  type: 'data',
                  cells,
                }
                sheet.rows.splice(sheet.rows.length - 1, 0, newRow)
                // console.log('newSheet', sheet)
              }
              _export.current.save(options)
              showLoadingToast({
                message: 'Successfully downloaded',
                toastId: processing,
                type: 'success',
              })
            }
          }
        } catch (err) {
          showLoadingToast({message: 'Download failed', toastId: processing, type: 'error'})
        }
      }
    }, 400)

    // setTimeout(() => {
    //   setIsLoading(false)
    // }, 3000)
  }

  const gridPDFExport = useRef<GridPDFExport>(null)
  const exportPDF = async () => {
    if (data.length > 1999) {
      showWarningToast({
        message:
          'PDF download not available for over 2K records. Please choose Excel or CSV downloads for the full dataset. Thank you!',
      })
      return false
    }
    const processing = showLoadingToast({message: 'Downloading...'})

    if (serverPdf) {
      try {
        // Call your PDF stream endpoint using Axios
        const response = await axios.post<any>(
          `${serverPdf.url}`,
          {...serverPdf.body, ...serverPdfExtraProps},
          {
            responseType: 'blob',
          }
        )

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(response.data)

        // Create a temporary anchor element
        const a = document.createElement('a')
        a.href = url
        a.download = `${fileName}.pdf` // Set the desired file name for the downloaded PDF
        document.body.appendChild(a)

        // Trigger the download
        a.click()
        showLoadingToast({
          message: 'Successfully downloaded',
          toastId: processing,
          type: 'success',
        })
        // Cleanup
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      } catch (error) {
        showLoadingToast({message: 'Download failed', toastId: processing, type: 'error'})
      }
    } else {
      setTimeout(() => {
        if (gridPDFExport && gridPDFExport.current) {
          try {
            gridPDFExport.current?.save(data, () => {
              showLoadingToast({
                message: 'Successfully downloaded',
                toastId: processing,
                type: 'success',
              })
            })
          } catch (err) {
            showLoadingToast({message: 'Download failed', toastId: processing, type: 'error'})
          }
        }
      }, 400)
    }
  }

  return (
    <>
      {isExcelRequired && (
        <AgedPayableExcel reference={_export} seriesData={data} columns={columns} name={fileName} />
      )}
      {isCsvRequired && (
        <CSVExportFile
          reference={_csvExport}
          seriesData={data}
          gridData={columns}
          name={fileName}
        />
      )}
      {isPdfRequired && (
        <PDFExports
          reference={gridPDFExport}
          seriesData={data}
          gridData={columns}
          name={fileName}
          landscape={pdfLandscape}
          totalAmount={totalForPerRow}
          paperSize={pdfPaperSize}
        />
      )}

      <div className='d-flex mb-5'>
        {isExcelRequired && (
          <span
            style={{cursor: 'pointer'}}
            className='icon-hover-scale-1 me-3'
            onClick={handleExcelExport}
          >
            <KTSVG
              path={IconPaths.excelImageIcon}
              className='svg-icon-success svg-icon-4hx'
              alt='Excel export'
              title='Excel export'
            />
          </span>
        )}
        {isPdfRequired && (
          <span style={{cursor: 'pointer'}} className='icon-hover-scale-1 me-3' onClick={exportPDF}>
            <KTSVG
              path={IconPaths.pdfIcon}
              className='svg-icon-danger svg-icon-4hx'
              alt='PDF export'
              title='PDF export'
            />
          </span>
        )}
        {isCsvRequired && (
          <span
            style={{cursor: 'pointer'}}
            className='icon-hover-scale-1'
            onClick={handleCsvExport}
          >
            <KTSVG
              path={IconPaths.csvIcon}
              className='svg-icon-primary svg-icon-4hx'
              alt='CSV export'
              title='CSV export'
            />
          </span>
        )}
      </div>
    </>
  )
}

export default ExportHoc
