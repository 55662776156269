import React, {SetStateAction} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IChartData, IwidgetDataModel} from '../../models/type'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
type IProps = {
  widgetId: number
  closePanel: React.Dispatch<SetStateAction<boolean>>
}
const WidgetSidePanel = ({widgetId, closePanel}: IProps) => {
  const widgetData: IwidgetDataModel = useSelector<RootState>(
    ({dashboard}) =>
      dashboard.widgetsData.find((item: IwidgetDataModel) => item.widgetId === widgetId),
    shallowEqual
  ) as IwidgetDataModel
  const {data}: IChartData = widgetData.content
  return (
    <div className='side-panel'>
      <button
        type='button'
        className='btn mx-2   btn-icon btn-bg-light btn-light-primary  btn-sm toggle-panel-close-btn'
        onClick={() => closePanel(false)}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr024.svg' className='svg-icon-2x' />
      </button>
      <div className='side-panel-content table-responsive'>
        <table className='table table-rounded table-striped border gy-7 gs-7'>
          <thead>
            <tr className='fw-bolder fs-4 text-gray-600'>
              <th>Category</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((ele, index) => {
                return (
                  <tr key={`${widgetId}_${index}`}>
                    <td>{ele.category}</td>
                    <td>{ele.series}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default WidgetSidePanel
