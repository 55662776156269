import {Middleware} from '@reduxjs/toolkit'
import {setCurrentLibraryAndDocGrp, setRuleList} from '../../app/modules/redux/RulesConfigSlice'

export const syncPropertiesMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action)

  if (action.type === setCurrentLibraryAndDocGrp.type) {
    localStorage.setItem('ruleLibraryDocGroup', JSON.stringify(action.payload))
  }

  if (action.type === setRuleList.type) {
    localStorage.setItem('ruleList', JSON.stringify(action.payload))
  }

  return result
}
