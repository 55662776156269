/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Route, Routes, Link} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {LoginNew} from './components/LoginNew'
import {Logout} from './Logout'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'

const AuthLayout = () => {
  const currentYear = new Date().getFullYear()
  const [showSplashScreen, setShowSplashScreen] = useState(false)
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <>
      {process.env.NODE_ENV === 'production' && showSplashScreen ? <LayoutSplashScreen /> : <></>}
      {process.env.NODE_ENV === 'development' && (
        <div className='row h-100' style={{marginRight: 0}}>
          <div className='col-xl-4 d-flex flex-row-auto' style={{background: '#1469b3'}}>
            {/*begin: Aside Container*/}
            <div className='d-flex flex-row-fluid flex-column justify-content-between px-10 py-2'>
              {/* start:: Aside header */}
              <Link to='/' className='flex-column-auto mt-5'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/newLogo.png')}
                  className='h-70px'
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className='flex-column-fluid d-flex flex-column  py-7'>
                <h3 className='mb-5 text-white fs-2'>Welcome to Redmap!</h3>
                <p className='font-weight-lighter text-white opacity-80'>
                  True automation & innovation starts here
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10'>
                <div className='opacity-70 font-weight-bold	text-white'>
                  &copy; {currentYear} Redmap
                </div>
                <div className='d-flex' style={{columnGap: '1.5rem'}}></div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          <div className='col-xl-8' style={{background: '#fff', margin: 0}}>
            <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
              {/* begin::Content */}
              <Outlet />
              {/* end::Content */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<LoginNew />} />
      <Route path='registration' element={<Registration />} />
      <Route path='logout' element={<Logout />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<LoginNew />} />
    </Route>
  </Routes>
)

export {AuthPage}
