import React, {FC, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import useRouteData from '../../../../../app/routing/routes'
import {MenuDataTypes} from '../../../../../app/modules/dashboard/models/type'
import {KTSVG} from '../../../../helpers'
import {IconPaths} from '../../../../../app/modules/dashboard/common-utilities/ui/icons/iconPaths'
import {H2Title} from '../../../../../app/modules/dashboard/common-utilities/ui/Headings'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, attributes} = useLayout()
  const directLogin: boolean = useSelector<RootState>(
    ({auth}) => auth.directLoggedIn,
    shallowEqual
  ) as boolean
  return (
    <div {...attributes.pageTitle} className='page-title d-flex flex-column'>
      {/* begin::Title */}
      {pageTitle && (
        <h1 className='d-flex text-dark fw-bolder my-1 fs-1'>
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-5 my-1'>
            {Array.from(pageBreadcrumbs).map((item, index) => (
              <li className={'breadcrumb-item text-white opacity-75'} key={`${item.path}${index}`}>
                {(directLogin && item.title.toLocaleLowerCase() === 'home') || !item.isLink ? (
                  <span className='text-white text-muted'>
                    {item.isSeparator && ` > `}
                    {item.title}
                  </span>
                ) : (
                  <Link className='' to={item.path}>
                    {item.isSeparator && ` > `}
                    {item.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
    </div>
  )
}
const ModuleTitle: FC = () => {
  const {pageTitle, pageDescription} = usePageData()
  const {config, attributes} = useLayout()

  return (
    <div {...attributes.pageTitle} className='page-title d-flex flex-column'>
      {/* begin::Title */}
      {pageTitle && (
        <H2Title className='d-flex text-dark'>
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </H2Title>
      )}
      {/* end::Title */}
    </div>
  )
}
const ModuleBreadcrumb: FC = () => {
  const {pageBreadcrumbs} = usePageData()
  const {config, attributes} = useLayout()
  const directLogin: boolean = useSelector<RootState>(
    ({auth}) => auth.directLoggedIn,
    shallowEqual
  ) as boolean
  return (
    <div {...attributes.pageTitle} className='page-title d-flex flex-column'>
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-4 my-1'>
            {Array.from(pageBreadcrumbs).map((item, index) => (
              <li className={'breadcrumb-item text-white opacity-75'} key={`${item.path}${index}`}>
                {(directLogin && item.title.toLocaleLowerCase() === 'home') || !item.isLink ? (
                  <span className='text-white text-muted'>
                    {item.isSeparator && ` > `}
                    {item.title}
                  </span>
                ) : (
                  <Link className='' to={item.path}>
                    {item.isSeparator && ` > `}
                    {item.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
    </div>
  )
}

const BreadcrumbNew: FC = () => {
  const {attributes} = useLayout()
  const location = useLocation()
  const RouteData = useRouteData()
  const paths = location.pathname.split('/').filter(Boolean)
  useEffect(() => {}, [paths])
  const findRouteByPath = (routes: MenuDataTypes[], path: string): MenuDataTypes | undefined => {
    for (let route of routes) {
      if (route.routePath === path) {
        return route
      }

      if (route.subMenu) {
        const nestedRoute = findRouteByPath(route.subMenu, path)
        if (nestedRoute) {
          return nestedRoute
        }
      }
    }

    return undefined
  }

  const renderBreadcrumbItems = (routes: MenuDataTypes[], index = 0): JSX.Element[] | null => {
    //debugger
    if (index >= paths.length || !routes) {
      return null
    }
    const searchPath = '/' + paths[index]
    const currentPath = `/${paths.slice(0, index + 1).join('/')}`
    const currentRoute = findRouteByPath(routes, searchPath)
    if (paths.length === 1 && paths[0] === 'home') return null

    if (paths.length === 1 && paths[0] !== 'home')
      return [
        <li className={'breadcrumb-item text-white opacity-75 py-3'} key={`${currentPath}`}>
          <Link to={'/home'}>{`Home`}</Link>
        </li>,
      ]
    if (!currentRoute) {
      return null
    }

    return [
      <li
        className={`breadcrumb-item text-white opacity-75 py-3${
          index === paths.length - 1 ? 'd-none' : ''
        }`}
        key={`${currentPath}`}
      >
        {index === paths.length - 1 ? (
          <span className='text-white text-muted'>{currentRoute.title}</span>
        ) : (
          <>
            <Link to={currentPath}>{currentRoute.title || currentPath}</Link>
            <KTSVG path='/media/icons/duotune/arrows/arr023.svg' className='svg-icon-2x' />
          </>
        )}
      </li>,
      <React.Fragment>
        {currentRoute.subMenu && renderBreadcrumbItems(currentRoute.subMenu, index + 1)}
      </React.Fragment>,
    ]
  }

  return (
    <>
      {paths.length === 1 && paths[0] !== 'home' && (
        <div className='d-xs-none d-md-none d-lg-block mx-5'>
          <KTSVG path={IconPaths.filledCircle} className='svg-icon-8 svg-icon-secondary' />
        </div>
      )}
      {paths.length > 1 && (
        <div className='d-xs-none d-md-none d-lg-block mx-5'>
          <KTSVG path={IconPaths.filledCircle} className='svg-icon-8 svg-icon-secondary' />
        </div>
      )}
      <div {...attributes.pageTitle} className='page-title d-flex flex-column'>
        <ul className='breadcrumb breadcrumb-separatorless  link1-regular'>
          {paths.length > 1 && paths[0] !== 'home' && (
            <li className={'breadcrumb-item text-white opacity-75 py-3'} key={`/home`}>
              <Link to={'/home'}>{`Home`}</Link>
              <KTSVG path='/media/icons/duotune/arrows/arr023.svg' className='svg-icon-2x' />
            </li>
          )}
          {renderBreadcrumbItems(RouteData)}
        </ul>
      </div>
    </>
  )
}

export {DefaultTitle, ModuleTitle, ModuleBreadcrumb, BreadcrumbNew}
