import React, {useEffect, useRef, useState} from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IconPaths} from '../../../common-utilities/ui/icons/iconPaths'
import {RootButtons} from '../../../common-utilities'
import {toggleArrayValues} from '../../../common-utilities/sorting/SortingMethods'
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import {reorder} from '../../../settings/rules/components/CommonMethods'

type IProps = {
  items: any[]
  uniqueProp: string
  onFinish: (hiddenColumns: any[], columnOrder: any[]) => void
  selectedData: any[]
}
const ITEM_HEIGHT = 100
const ITEM_PADDING_TOP = 8
// const MenuProps = {
//   PaperProps: {
//     style: {
//       padding: 0,
//       width: 250,
//       margin: 0,
//     },
//   },
// }
const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => {
  return {
    ...draggableStyle,
    userSelect: 'none',

    background: isDragging ? 'lightgreen' : 'none',
  }
}
const MuiCustomSelect = ({items, onFinish, selectedData, uniqueProp}: IProps) => {
  const [selectedOptions, setSelected] = useState<any[]>(selectedData)
  const [data, setData] = useState<any[]>(items)
  const [selectWidth, setSelectWidth] = useState<number | null>(null)

  useEffect(() => {
    setData(items)
    setSelected(selectedData)
  }, [items, selectedData])
  const [open, setOpen] = useState(false)
  // const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
  //   const {
  //     target: {value},
  //   } = event
  //   setSelected(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value
  //   )
  // }

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      setSelectWidth(ref.current.offsetWidth)
    }
  }, [ref.current])
  const handleDragEnd = (result: DropResult) => {
    const {destination, source} = result
    if (!destination) return

    let res = reorder(data, source.index, destination?.index)

    setData(res)
  }

  const handleColumnToggle = () => {
    let hiddenColumns = data.filter((ele) => !selectedOptions.includes(ele))

    onFinish(hiddenColumns, data)
    setOpen(false)
  }
  return (
    <div>
      <FormControl fullWidth variant='outlined' className='customReorderSelect'>
        <InputLabel shrink id='demo-multiple-checkbox-label' htmlFor='demo-multiple-checkbox'>
          Select columns & reorder
        </InputLabel>
        <div ref={ref}>
          <Select
            fullWidth
            notched
            labelId='demo-multiple-checkbox-label'
            id='demo-multiple-checkbox'
            multiple
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            variant='outlined'
            label='Select columns & reorder'
            value={selectedOptions}
            renderValue={(selected) => `( ${selected.length} ) Selected`}
            displayEmpty={true}
            native={false}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: {
                style: {
                  width: selectWidth ?? 'auto',
                },
              },
            }}
            IconComponent={(props) => (
              <KTSVG
                className={`svg-icon-2x svg-icon-primary me-3 ${open ? '' : 'rotate-item-180'}`}
                path={IconPaths.closeArrow}
                onClick={() => {
                  setOpen(!open)
                }}
              />
            )}
          >
            <div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={'columnList'}>
                  {(provided, snapshot) => (
                    <div
                      style={{maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, overflowY: 'auto'}}
                      className='dropDownItemPanel'
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {data.map((name, index) => (
                        <Draggable key={name} draggableId={name} index={index}>
                          {(provided, snapshot) => (
                            <MenuItem
                              key={name}
                              value={name}
                              className={`dropDownMenuItem  ${
                                selectedOptions.indexOf(name) > -1 ? 'selected' : ''
                              }`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={Object.assign(
                                getItemStyle(snapshot.isDragging, provided.draggableProps.style)
                              )}
                            >
                              <Checkbox
                                checked={selectedOptions.indexOf(name) > -1}
                                onClick={() => {
                                  setSelected(toggleArrayValues(selectedOptions, name))
                                }}
                                checkedIcon={
                                  <KTSVG
                                    path={IconPaths.checked}
                                    className='svg-icon-2x svg-icon-white'
                                  />
                                }
                                icon={
                                  <KTSVG
                                    path={IconPaths.unchecked}
                                    className='svg-icon-2x svg-icon-white'
                                  />
                                }
                              />
                              <ListItemText>
                                <span className='fs-3'>{name}</span>
                              </ListItemText>
                              <span {...provided.dragHandleProps}>
                                <KTSVG
                                  path={IconPaths.dragIcon}
                                  className={`${
                                    snapshot.isDragging ? 'svg-icon-primary' : 'svg-icon-gray-400'
                                  } svg-icon-2hx icon-hover-color-primary`}
                                />
                              </span>
                            </MenuItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className='bg-light p-3'>
                <RootButtons.NormalButton
                  onClick={handleColumnToggle}
                  title={<span className='text-primary fw-bolder fs-4'>Done</span>}
                  varient='outlined'
                />
                <div className='my-5'>
                  Select checkboxes to show & hide columns. Drag options up and down to re-order
                  columns. Click update report to see the changes
                </div>
              </div>
            </div>
          </Select>
        </div>
      </FormControl>
    </div>
  )
}

export default MuiCustomSelect
