import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {UserManager, WebStorageStateStore} from 'oidc-client'

const queryString = require('query-string')
const AUTH_URL = process.env.REACT_APP_ID_AUTHORITY_URL
const APP_URL = process.env.REACT_APP_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${AUTH_URL}/connect/userinfo`
export const LOGIN_URL = `${AUTH_URL}/connect/token`
export const REGISTER_URL = `${AUTH_URL}/register`
export const REQUEST_PASSWORD_URL = `${AUTH_URL}/forgot_password`

export interface IAuthPayload {
  username: string
  password: string
  scope: string
  client_secret: string
  grant_type: string
  client_id: string
}

const AuthPayload: IAuthPayload = {
  username: '',
  password: '',
  scope:
    'openid profile roles email address Redmap.Widgets.Api Redmap.Library.Api Redmap.Metrics.Api',
  client_secret: '100469a0-7ea2-b9da-67d9-56a4d19113a2',
  grant_type: 'password',
  client_id: 'Redmap6',
}

const authSettings = {
  authority: `${AUTH_URL}/`,
  client_id: 'Redmap6Alpha',
  redirect_uri: `${APP_URL}/signin-callback.html`,
  silent_redirect_uri: `${APP_URL}/silent-renew.html`,
  post_logout_redirect_uri: `${APP_URL}/auth/`,
  response_type: 'code',
  scope:
    'openid profile roles email address Redmap.Widgets.Api Redmap.Library.Api Redmap.Metrics.Api',
  userStore: new WebStorageStateStore({store: window.localStorage}),
}

const userManager = new UserManager(authSettings)

userManager.events.addAccessTokenExpired(() => {
  signInRedirect()
})

// userManager.events.addAccessTokenExpiring((see) => {
//   debugger
// })

// Server should return AuthModel
export function login(email: string, password: string) {
  AuthPayload.username = email
  AuthPayload.password = password
  return axios.post(LOGIN_URL, queryString.stringify(AuthPayload), {
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export const getUserByToken = async () => {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export const signInRedirect = async () => {
  return userManager.signinRedirect()
}

export const signOutRedirect = async () => {
  userManager.clearStaleState()
  return userManager.signoutRedirect()
}

export const silentSignIn = async (token: string) => {
  return userManager.signinSilent({token})
}

export const getUser = async () => {
  return userManager.getUser()
}
export const signInCallBack = async () => {
  return userManager.signinRedirectCallback()
}
