import React, {FC, createContext, useEffect, useState} from 'react'
import {AgedPayableFieldsTypes, ICOLORS, ICardSessionData} from '../../../models/type'
import {getCardDetails} from '../../../../redux/cards/api'
import {errorStatements} from '../../../common-utilities/ui/Standards'

export type ICardDetailsContext = {
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  error: string
  data?: any[]
  configData?: ICardSessionData
  columns: AgedPayableFieldsTypes[]
}

export const initialContext: ICardDetailsContext = {
  searchText: '',
  setSearchText: () => {},
  error: '',
  isLoading: false,
  columns: [],
}

export const CardDetailsContext = createContext<ICardDetailsContext>(initialContext)

type getTrendProps = {
  trendValue: 'Increasing' | 'Decreasing'
  diff: 'up' | 'down' | 'equal'
}
type getTrendResponse = {
  trendClass: string
  border: ICOLORS
}
export const getTrend = ({diff, trendValue}: getTrendProps): getTrendResponse => {
  switch (true) {
    case trendValue === 'Decreasing' && diff === 'up':
      return {
        trendClass: 'svg-icon-danger',
        border: 'danger',
      }
    case trendValue === 'Decreasing' && diff === 'down':
      return {
        trendClass: ' svg-icon-success rotate-item-180',
        border: 'success',
      }
    case trendValue === 'Increasing' && diff === 'up':
      return {
        trendClass: 'svg-icon-success',
        border: 'success',
      }
    case trendValue === 'Increasing' && diff === 'down':
      return {
        trendClass: `svg-icon-danger rotate-item-180`,
        border: 'danger',
      }
    default:
      return {
        trendClass: `svg-icon-warning  rotate-item-90`,
        border: 'warning',
      }
  }
}

type IProps = {
  id: string
}
export const CardDetailsContextProvider: FC<IProps> = ({id, children}) => {
  const [searchText, setSearchText] = useState<string>('')
  const [configData, setConfigData] = useState<ICardSessionData>()
  const [data, setData] = useState<any[] | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<AgedPayableFieldsTypes[]>([])
  useEffect(() => {
    let sessionData = sessionStorage.getItem(`card-${id}`)
    if (sessionData) {
      let temp: ICardSessionData = JSON.parse(sessionData)
      setConfigData(temp)
      loadData(temp)
    } else {
    }
  }, [])

  const loadData = (temp: ICardSessionData) => {
    if (temp) {
      const {columns} = temp.config
      setLoading(true)

      getCardDetails({...temp.parms})
        .then((res) => {
          setData(res.data)
          setDataColumns(columns)
        })
        .catch((err) => {
          setError(errorStatements.noRecords)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  return (
    <CardDetailsContext.Provider
      value={{
        searchText,
        setSearchText,
        error,
        isLoading,
        configData,
        data,
        columns: dataColumns,
      }}
    >
      {children}
    </CardDetailsContext.Provider>
  )
}
