import axios from 'axios'
import {
  AgedPayableReportModel,
  IAgedPayableSummaryCard,
  IAgedPayableSummaryData,
  ISolutionLibs,
  IWidgetModel,
} from '../../dashboard/models/type'
import {FiltersProps} from '../../dashboard/widgets/detail-view/components/data-schema/FilterContext'
import {AGEDPAYABLE_URL, INVOICE_PDF, KPI_URL, SOLUTION_URL} from '../EndPoints'

export function getAgedPayableReportData(widgetId: number, library: string) {
  return axios.get<AgedPayableReportModel>(
    `${KPI_URL}/GetAgedPayableReport?widgetId=${widgetId}&libraryName=${library}`
  )
}

export function getKPIReportList() {
  return axios.get<IWidgetModel[]>(
    `${KPI_URL}/GetKPIReports?token=5d055ae1e1ce0ea03a077e3b4e5edb1cfe468055b1c8dad3377f96491e233001`
  )
}

export function getAgedPayableSummaryData(data: FiltersProps) {
  const {ageingBy, ageingPeriod, groupBy, interval, library} = data
  return axios.get<IAgedPayableSummaryData>(
    `${AGEDPAYABLE_URL}/BySummaryView?AgiengInterval=${interval}&AgeingPeriod=${ageingPeriod}&AgeingBy=${ageingBy}&AgeingGroupBy=${groupBy}&Library=${library}`
  )
}

export function getAgedPayableSummaryCardData(data: FiltersProps) {
  const {ageingBy, ageingPeriod, groupBy, interval, library} = data
  return axios.get<IAgedPayableSummaryCard>(
    `${AGEDPAYABLE_URL}/BySummaryCard?AgiengInterval=${interval}&AgeingPeriod=${ageingPeriod}&AgeingBy=${ageingBy}&AgeingGroupBy=${groupBy}&Library=${library}`
  )
}

export function getInvoicePdf(library: string, itemId: number) {
  return axios.get<any>(`${INVOICE_PDF}/GetByItemId?library=${library}&itemId=${itemId}`, {
    responseType: 'blob',
  })
}
export function getAgedPayableLib() {
  return axios.get<ISolutionLibs[]>(`${SOLUTION_URL}/GetLibraries`)
}

export function setDefaultLib(library: string) {
  return axios.put<any>(`${SOLUTION_URL}/SetDefault?defaulLibrary=${library}`)
}

// export function getAgedPayablePDF(data: FiltersProps) {
//   const {ageingBy, ageingPeriod, groupBy, interval, library, columns} = data
//   return axios.post<any>(
//     `${AGEDPAYABLE_URL}/BySummaryViewPdf`,
//     {
//       agiengInterval: 6,
//       ageingPeriod: 'Day',
//       ageingBy: 'InvoiceDate',
//       ageingGroupBy: 'Approver',
//       library: 'AGWarehouse',
//       columnConfig: 'Entity,Warehouse,Quantity,Supplier',
//       sortingOrderColumn: 'Quantity',
//       isOrderByDescending: true,
//     },
//     {
//       responseType: 'blob',
//     }
//   )
// }
