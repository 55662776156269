import React, {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {IColumnChartConfig} from '../../models/chart'
import {getCommonXYChartConfigs} from './variables'
type IProps = {
  config: IColumnChartConfig
}
function AmColumnChart({config}: IProps) {
  const {data, categoryField, containerId, height, valueField, width, legendConfig} = config
  useLayoutEffect(() => {
    let root = am5.Root.new(containerId)
    root.setThemes([am5themes_Animated.new(root)])

    getCommonXYChartConfigs({categoryField, root, valueField}).then(({chart, xAxis, yAxis}) => {
      xAxis.data.setAll(data)

      // Create series
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: valueField,
          categoryXField: categoryField,
          maskBullets: false,
        })
      )
      series.columns.template.setAll({
        tooltipText: ` {categoryX} :  {valueY}`,
        tooltipY: am5.percent(90),
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
      })

      //to add custom category axis labels

      // Add legend
      if (legendConfig && legendConfig.isRequired) {
        let legend = chart.children.push(am5.Legend.new(root, {}))
        legend.data.setAll(chart.series.values)
      }
      series.data.setAll(data)
      series.appear(1000, 400)
    })

    return () => {
      root.dispose()
    }
  }, [])

  return (
    <div
      id={containerId}
      className='py-3'
      style={{height: `${height}px`, width: width ? `${width}px` : '100%'}}
    ></div>
  )
}

export default AmColumnChart
