import {generateActionQuery, saveRuleConfig, validateSQLQuery} from '../../../../redux/library/api'

import {getErrorMessage, showErrorToast} from '../../../common-utilities/alerts/AlertModels'
import {IOldRuleConfig} from '../../../models/oldRuleType'
import {
  IActionFields,
  IMappingData,
  IRevisionResponseData,
  IRuleActions,
  IRuleBasics,
  IRuleConfigData,
  IRuleSaveData,
  IRulesConfig,
} from '../../../models/type'
import store from '../../../../../../setup/redux/Store'
import {setDraftVersionStatus} from '../../../../redux/RulesConfigSlice'
import moment from 'moment'
import {getTableFields} from '../../../../redux/configurations/api'
import {setDashboardConfiguration} from '../../../../redux/DashboardSlice'
import _ from 'lodash'
import {DropResult} from 'react-beautiful-dnd'
export const convertOldToNew = async (oldConfig: IOldRuleConfig, currentLibrary: string) => {
  const {
    recordsFound,
    noRecordsFound,
    name,
    context,
    ruleType,
    whereClause,
    joinClause,
    ruleID,
    sourceTable,
  } = oldConfig
  const ruleBasic: IRuleBasics = {
    context: context ? context : '',
    ruleName: name,
    type: ruleType === 0 ? 'Rule' : 'System',
    join: '',
  }
  const selectQuery = oldConfig.sqlQuery
  let recordFoundQuery = oldConfig.recordFoundQuery

  let recordNotFoundQuery = oldConfig.recordNoFoundQuery
  if (
    (oldConfig.recordsFound.length > 0 && recordFoundQuery === '') ||
    (oldConfig.noRecordsFound.length > 0 && recordNotFoundQuery === '')
  )
    await generateActionQuery(0, ruleID, currentLibrary)
      .then((res) => {
        recordFoundQuery = res.data.recordFoundQuery
        recordNotFoundQuery = res.data.recordNotFoundQuery
      })
      .catch((err) => {
        console.log('error')
      })
  // console.log('selectQuery', selectQuery)
  // console.log('recordFound', recordFoundQuery)
  // console.log('Notfound', recordNotFoundQuery)
  let rfDataSource: IMappingData[] = []
  let rnfDataSource: IMappingData[] = []
  await convertToMappingData(recordsFound, 'recordFound').then((res) => {
    rfDataSource = res
  })
  await convertToMappingData(noRecordsFound, 'recordNotFound').then((res) => {
    rnfDataSource = res
  })
  console.log(rfDataSource)
  let finalData: IRuleConfigData = {
    id: oldConfig.ruleID,
    groupBy: [],
    joinClause: joinClause,
    mappingData: rfDataSource.concat(rnfDataSource),
    orderBy: [],
    ruleBasics: ruleBasic,
    recordFound: recordsFound,
    recordNotFound: noRecordsFound,
    where: [],
    customCondition: selectQuery,
    finalQuery: selectQuery,
    name: ruleBasic.ruleName,
    recordFoundQuery: recordFoundQuery.toString(),
    recordNotFoundQuery: recordNotFoundQuery.toString(),
    useCustomQuery: false,
    operation: '',
    whereClause: whereClause,
    auditOnFailed: oldConfig.auditOnFailed !== null ? oldConfig.auditOnFailed : '',
    auditOnPassed: oldConfig.auditOnPassed !== null ? oldConfig.auditOnPassed : '',
    commentOnFailed: oldConfig.commentOnFailed !== null ? oldConfig.commentOnFailed : '',
    commentOnPassed: oldConfig.commentOnPassed !== null ? oldConfig.commentOnPassed : '',
    msgPromptOnFailed: oldConfig.msgPromptOnFailed !== null ? oldConfig.msgPromptOnFailed : '',
    msgPromptOnPassed: oldConfig.msgPromptOnPassed !== null ? oldConfig.msgPromptOnPassed : '',
    sourceTable: sourceTable,
  }

  return finalData
}

export const convertToMappingData = async (
  data: IRuleActions[],
  type: 'recordFound' | 'recordNotFound'
) => {
  let result: IMappingData[] = []
  for await (const ele of data) {
    ele.tableSources.forEach((items) => {
      result.push({
        sourceTable: items.tableName,
        targetTable: ele.targetTable,
        selectedFields: items.fieldList.map((ele) => {
          return {fieldName: ele.fieldName, isSum: ele.isSum, type: ''}
        }),
        joins: items.joinList ? items.joinList : [],
        condition: items.whereList ? items.whereList : [],
        type: type,
        isEditing: false,
        id: items.id,
      })
    })
  }
  return result
}
export const generateQuery = (data: IRuleActions[]) => {
  let queries = data.map((ele, index) => {
    if (ele.action === 'Update')
      return getUpdateQuery({table: ele.targetTable, fields: ele.targetFields})
    else if (ele.action === 'Insert')
      return getInsertQuery({table: ele.targetTable, fields: ele.targetFields})
    else return getReplaceQuery({table: ele.targetTable, fields: ele.targetFields})
  })
  return queries
}
export const getUpdateQuery = ({
  table,
  fields,
}: {
  table: string
  fields: IActionFields[]
}): string => {
  let query = `Update  ${table} \nSet \n`
  fields.forEach((ele, index) => {
    let val = ''
    if (ele.isConstantValue)
      val = ele.isConstantQuery ? `${ele.constantValue}` : `'${ele.constantValue}'`
    else if (ele.sourceFieldName !== '' && ele.sourceTableName !== '')
      val = `Select ${ele.sourceFieldName} from ${ele.sourceTableName}`
    query = query + `${ele.fieldName} = ${val}`
    if (fields[index + 1]) query = query + ' , '
    query = query + '\n'
  })
  return query
}
export const getInsertQuery = ({
  table,
  fields,
}: {
  table: string
  fields: IActionFields[]
}): string => {
  let data = fields.map((ele) => {
    let val = ''
    if (ele.isConstantValue)
      val = ele.isConstantQuery ? `${ele.constantValue}` : `'${ele.constantValue}'`
    else if (ele.sourceFieldName !== '' && ele.sourceTableName !== '')
      val = `Select ${ele.sourceFieldName} from ${ele.sourceTableName}`
    return {field: ele.fieldName, value: val}
  })
  return `Insert Into ${table} (${data.map((ele) => ele.field).toString()}) Values (${data
    .map((ele) => ele.value)
    .toString()})`
}
export const getReplaceQuery = ({
  table,
  fields,
}: {
  table: string
  fields: IActionFields[]
}): string => {
  return ''
}

export const SaveRuleAsDraft = async (
  ruleConfig: IRulesConfig
): Promise<{
  status: boolean
  message: string
  data: IRuleConfigData | undefined
  ownerName?: null | string
}> => {
  const {
    selectedRule,
    joins,
    ruleBasics,

    recordFound,
    recordNotFound,
    where,
    groupBy,
    orderBy,
    mappingData,
    customCondition,
    finalQuery,
    recordFoundQuery,
    recordNotFoundQuery,
    useCustomQuery,
    whereClause,
    isOldRule,
  } = ruleConfig

  // console.log(action)
  // console.log(selectedRule)

  let id = selectedRule !== undefined ? selectedRule : 0
  let data: IRuleConfigData = {
    id: id,
    joinClause: joins,
    name: ruleBasics.ruleName,
    recordFound: recordFound,
    recordNotFound: recordNotFound,
    ruleBasics: ruleBasics,
    where: where,
    groupBy: groupBy,
    mappingData: mappingData,
    orderBy: orderBy,
    useCustomQuery: useCustomQuery,
    customCondition: customCondition,
    finalQuery: finalQuery,
    recordFoundQuery: recordFoundQuery !== undefined ? recordFoundQuery : '',
    recordNotFoundQuery: recordNotFoundQuery !== undefined ? recordNotFoundQuery : '',
    whereClause: whereClause,
    auditOnFailed: ruleConfig.auditOnFailed,
    auditOnPassed: ruleConfig.auditOnPassed,
    commentOnFailed: ruleConfig.commentOnFailed,
    commentOnPassed: ruleConfig.commentOnPassed,
    msgPromptOnFailed: ruleConfig.msgPromptOnFailed,
    msgPromptOnPassed: ruleConfig.msgPromptOnPassed,
    sourceTable: ruleConfig.currentDocGroup,
  }

  let saveData: IRuleSaveData = {
    ruleId: id,
    data: JSON.stringify(data),
    docGroupId: ruleConfig.currentDocGroupId !== undefined ? ruleConfig.currentDocGroupId : 0,
    isJsonData: true,
    library: ruleConfig.currentLibrary,
    type: selectedRule === undefined ? 'create' : 'update',
    context: ruleBasics.context,
    ruleName: ruleBasics.ruleName,
    ruleType: ruleBasics.type === 'Rule' ? 0 : 1,
    joinTable: '',
    isNewRule: isOldRule === undefined || isOldRule === false ? true : false,
    status: 'Draft',
  }
  //console.log(saveData)
  let response = await saveRuleConfig(saveData)
    .then((res) => {
      if (res.data.draftData) {
        store.dispatch(setDraftVersionStatus(true))
        let response = JSON.parse(res.data.draftData)
        return {
          status: true,
          message: '',
          data: {...response, id: res.data.ruleID},
          ownerName: res.data.ownerName,
        }
      } else
        return {
          status: true,
          message: '',
          data: {...data, id: res.data.ruleID},
          ownerName: res.data.ownerName,
        }
    })
    .catch((err) => {
      showErrorToast({message: getErrorMessage(err)})

      return {status: false, message: getErrorMessage(err), data: undefined}
    })
  return response
}
export const prepareRevisions = async (
  allData: IRevisionResponseData[],
  currentLibrary: string
) => {
  let revisionData: IRuleConfigData[] = []

  for await (const item of allData) {
    if (item.ruleValidation.ruleJson !== null && item.ruleValidation.ruleJson !== '') {
      let temp = JSON.parse(item.ruleValidation.ruleJson)
      revisionData.push({
        ...temp,
        id: item.ruleValidation.ruleID,
        revisionNo: item.revisionId,
        updateBy: item.updateBy,
        updateDate: item.updateDate,
      })
    } else {
      let result = await convertOldToNew(item.ruleValidation, currentLibrary)

      revisionData.push({
        ...result,
        revisionNo: item.revisionId.toString(),
        updateBy: item.updateBy,
        updateDate: item.updateDate,
      })
    }
  }

  return revisionData
}

export const formatDate = (dateString: string) => {
  const date = moment(dateString)
  // console.log(date)
  return date.format('YYYY-MM-DD HH:mm')
}

export const getTruncateText = (value: undefined | string, limit: number = 10) => {
  if (value === undefined || value === null) return <span></span>
  else if (value === '') return <span>{value}</span>
  else if (value.length <= limit) return <span>{value}</span>
  else
    return (
      <span data-title={value}>{`${
        value.length > limit ? value.substring(0, limit) + '...' : value
      }`}</span>
    )
}

export const checkTableField = async (tableFields: any, tableNames: any[], library: string) => {
  let temp: any = {}
  for await (const item of tableNames) {
    if (item && tableFields[item] === undefined) {
      let res = await getTableFields(library, item)
      temp[item] = res.data
    }
  }

  store.dispatch(setDashboardConfiguration({tableFields: {...tableFields, ...temp}}))
}
export const setTableFields = async (tableFields: any, tableName: string, library: string) => {
  if (tableName !== '' && library !== '') {
    if (tableFields[tableName] === undefined) {
      getTableFields(library, tableName)
        .then((res) => {
          let updateData = {...tableFields, [tableName]: res.data}
          store.dispatch(setDashboardConfiguration({tableFields: updateData}))
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
export const checkIfFormDirty = (data: IRulesConfig) => {
  let temp = data.ruleConfigData.find((ele) => ele.id === data.selectedRule)

  if (temp) {
    if (
      (data.useCustomQuery &&
        (data.finalQuery !== temp.finalQuery ||
          data.recordFoundQuery !== temp.recordFoundQuery ||
          data.recordNotFoundQuery !== temp.recordNotFoundQuery)) ||
      !_.isEqual(temp.ruleBasics, data.ruleBasics)
    )
      return true
    else if (
      !_.isEqual(temp.ruleBasics, data.ruleBasics) ||
      !_.isEqual(temp.joinClause, data.joins) ||
      !_.isEqual(temp.whereClause, data.whereClause) ||
      !_.isEqual(temp.groupBy, data.groupBy) ||
      !_.isEqual(temp.orderBy, data.orderBy) ||
      !_.isEqual(temp.mappingData, data.mappingData) ||
      !_.isEqual(temp.recordFound, data.recordFound) ||
      !_.isEqual(temp.recordNotFound, data.recordNotFound)
    )
      return true
    else return false
  } else return false
}

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
export const getDropResult = async (result: DropResult, data: any[]) => {
  const {destination, source} = result
  if (destination) {
    if (source.droppableId === destination.droppableId) {
      if (source.index !== destination.index) {
        let newList = reorder(data, source.index, destination.index)
        return newList
      }
    } else {
      showErrorToast({message: 'Cannot drop'})
    }
  } else showErrorToast({message: 'Wrong destination'})
}

export const getListStyle = (isDraggingOver: boolean) => {
  return {background: isDraggingOver ? 'white' : 'none'}
}
export const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
  return {
    ...draggableStyle,

    boxShadow: isDragging ? '0 0 .4rem #666' : '',
  }
}
export const getTableName = (alias: string) => {
  const data = store.getState().ruleConfig.aliasData
  let temp = data.find((ele) => ele.alias === alias)
  if (temp) return temp.table
  else return ''
}
export const validateSQL = async (query: string | undefined, library: string) => {
  if (query === '' || query === undefined) return undefined
  let result = await validateSQLQuery(query, library, false)
    .then((res) => {
      return {status: res.data.status, message: res.data.messages.toString()}
    })
    .catch((err) => {
      if (err.response.data) {
        return {
          status: err.response.data.status,
          message: err.response.data.messages.toString(),
        }
      }
    })
  return result
}

export const TruncatedTextWithTooltip = ({value, limit = 10}: {value: string; limit: number}) => {
  if (!value) return null

  const truncatedValue = value.length > limit ? `${value.substring(0, limit)}...` : value

  return (
    <span
      className='d-inline-block text-truncate'
      data-toggle='tooltip'
      data-trigger='click hover'
      title={value}
    >
      {truncatedValue}
    </span>
  )
}
