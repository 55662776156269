import React, {useContext, useEffect, useState} from 'react'
import {IGrid, IWidgetModel, IWidgetTableFields} from '../../../models/type'
import {ConfigurationContext} from '../../drawer/ConfigurationSchema'
import {getTableFields, getTables} from '../../../../redux/configurations/api'
import {getErrorMessage, showErrorToast} from '../../../common-utilities/alerts/AlertModels'
import {Autocomplete, Grid, TextField} from '@mui/material'
import MuiSelectField from '../../../common-utilities/ui/MuiSelectField'
import MuiTextField from '../../../common-utilities/ui/MuiTextField'
import ReactCodeMirror from '@uiw/react-codemirror'
import {langs} from '@uiw/codemirror-extensions-langs'
import {EditorView} from '@codemirror/view'
import {initialGridConfigData} from '../../../models/reportsInitialData'
type IProps = {
  isFormDirty: boolean
  widgetData: IWidgetModel
  configData: IGrid
  setConfigData: React.Dispatch<React.SetStateAction<IGrid>>
}
const DataConfigForm = () => {
  const {configData, widgetData, setConfigData}: IProps = useContext(ConfigurationContext)

  const [tables, setTables] = useState<string[]>([])
  const [tableFields, setTableFields] = useState<IWidgetTableFields[]>([])

  useEffect(() => {
    if (widgetData.library === '') {
      setConfigData({...initialGridConfigData})
    } else {
      loadTables()
    }
  }, [widgetData.library])
  useEffect(() => {
    if (configData.Table !== '' && widgetData.library !== '') loadTableFields()
  }, [configData.Table])

  const onTableChange = (value: string) => {
    if (value !== '') {
      getTableFields(widgetData.library, value)
        .then((res) => {
          setTableFields(res.data)
        })
        .catch((err) => {
          showErrorToast({message: getErrorMessage(err)})
        })
    }
    setConfigData({
      ...configData,
      Table: value,
      Fields: [],
      SQL_Query: `Select * from ${value}`,
      Predicate: '',
      DateField: '',
      DateTable: '',
    })
  }
  const loadTables = async () => {
    getTables(widgetData.library, true)
      .then((res) => {
        setTables(res.data.map((ele) => ele.name))
      })
      .catch((err) => {
        showErrorToast({message: 'Cannot load tables'})
      })
  }
  const loadTableFields = async () => {
    getTableFields(widgetData.library, configData.Table)
      .then((res) => {
        setTableFields(res.data)
      })
      .catch((err) => {
        showErrorToast({message: 'Table Fields: \n' + getErrorMessage(err)})
      })
  }
  return (
    <>
      <Grid container spacing={2} className='p-4'>
        <Grid item xs={12}>
          <MuiSelectField
            changedValues={(value: string) => {
              onTableChange(value)
            }}
            errorText='Select a table'
            hasError={false}
            id='table'
            isRequired={true}
            label='Table'
            val={configData.Table}
            items={tables}
          />
        </Grid>
        <Grid item xs={12}>
          {configData.Table !== '' && (
            <Autocomplete
              multiple
              id='tags-outlined'
              options={tableFields ? tableFields.map((ele) => ele.fieldName) : []}
              value={configData.Fields !== '' || configData.Fields ? configData.Fields : []}
              defaultValue={[]}
              filterSelectedOptions
              disableCloseOnSelect={true}
              onChange={(e: any, newValue: any[]) => {
                setConfigData({
                  ...configData,
                  Fields: newValue,
                  SQL_Query: `Select ${newValue.length > 0 ? newValue.toString() : '*'} from ${
                    configData.Table
                  }`,
                })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{style: {fontSize: 17}, shrink: true}}
                  InputProps={{...params.InputProps, style: {fontSize: 16}}}
                  label='Fields'
                  placeholder='fields'
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <MuiTextField
            changedValues={(value: string) => {
              setConfigData({
                ...configData,
                Predicate: value,
                SQL_Query: `Select ${
                  configData.Fields.length > 0 ? configData.Fields.toString() : '*'
                } from ${configData.Table}  ${value !== '' ? 'Where ' + value : ''}`,
              })
            }}
            errorText=''
            hasError={false}
            id='predicate_txt'
            isRequired={false}
            label='Where Condition'
            val={configData.Predicate}
            placeHolder='Enter condition'
            isFullWidth={true}
          />
          <label>ex: status=true</label>
        </Grid>
        <Grid item xs={12}>
          <ReactCodeMirror
            className='mb-4 text-wrap form-control'
            key={`sqlQuery`}
            value={configData.SQL_Query}
            extensions={[langs.sql(), EditorView.lineWrapping]}
            readOnly
          />
        </Grid>

        <Grid item xs={12}>
          <MuiSelectField
            changedValues={(value: string) => {
              setConfigData({...configData, DateField: value, DateTable: configData.Table})
            }}
            errorText='Select date field'
            hasError={false}
            id='dateField'
            isRequired={false}
            label='Date Field'
            val={configData.DateField}
            items={tableFields.filter((ele) => ele.fieldType === 'DateTime')}
            displayField={'fieldName'}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DataConfigForm
