import {Autocomplete, TextField, createFilterOptions} from '@mui/material'

type IProps = {
  label?: string
  id: string
  hasError: boolean
  val: string
  changedValues: (value: string) => void
  isRequired: boolean
  errorText: string
  placeHolder?: string
  items: any[]
  isDisabled?: boolean
  displayField: string

  uniqueField: string
  returnField: string
  duplicateDisplayValues: boolean
  isFullWidth?: boolean
}
const MuiObjectSelectField = ({
  hasError,
  id,
  label,
  val = '',
  changedValues,
  errorText,
  isRequired,

  items,
  isDisabled,
  displayField,
  returnField,
  uniqueField,
  duplicateDisplayValues,
  isFullWidth,
}: IProps) => {
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   changedValues(e.target.value)
  // }
  const getValue = (value: string) => {
    if (displayField) {
      if (value === '') return {[displayField]: '', [uniqueField]: ''}
      else {
        let val = items.find((ele) => ele[returnField] === value)

        return val ? val : {[displayField]: value}
      }
    }
  }
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option[displayField],
  })
  return (
    <>
      <Autocomplete
        {...(isFullWidth && {fullWidth: true})}
        value={getValue(val)}
        onChange={(event: any, newValue: any) => {
          newValue !== null && changedValues(newValue[returnField])
        }}
        id={id}
        options={items}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{style: {fontSize: 17}, shrink: true}}
            InputProps={{...params.InputProps, style: {fontSize: 16}}}
            required={isRequired}
            {...(label && {label: label})}
            helperText={val === '' && hasError ? errorText : ''}
            error={val === '' && hasError ? true : false}
          />
        )}
        {...(isDisabled && {disabled: isDisabled})}
        getOptionLabel={(option: any) =>
          val === ''
            ? val
            : `${duplicateDisplayValues ? `(${option[uniqueField]})` : ''} ${option[displayField]}`
        }
        filterOptions={filterOptions}
        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
          return (
            <li {...props} key={`${option[uniqueField]}`} value={option[uniqueField]}>
              {duplicateDisplayValues
                ? `${option[uniqueField]}  As  ${option[displayField]}`
                : `${option[displayField]}`}
            </li>
          )
        }}
        onInputChange={(e: React.SyntheticEvent, value: any, reason: string) => {
          if (reason === 'clear') {
            changedValues('')
          }
        }}
      />
    </>
  )
}

export default MuiObjectSelectField
