import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUser, getUserByToken, signInRedirect, signOutRedirect} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {UserModel} from '../models/UserModel'
import {User} from 'oidc-client'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // const store = sessionStorage.getItem(
  //   `oidc.user:${process.env.REACT_APP_ID_AUTHORITY_URL}/:Redmap6Alpha`
  // )
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string
  // const oidcToken: User | null = store ? JSON.parse(store) : null
  const isProd = process.env.NODE_ENV === 'production' //to decide which identity platform use
  const PROD = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'prod' //It will decide actual environment for debug user

  useEffect(() => {
    ;(async () => {
      let user: User | null

      const requestUser = async () => {
        try {
          if (!didRequest.current) {
            if (!isProd) {
              const {data: user} = await getUserByToken()

              let role: any = user.role

              if (role === undefined || role === null) role = []
              if (typeof role === 'string') role = role.split(',')
              dispatch(props.fulfillUser({...user, debugUser: true, role}))
            } else {
              if (user) {
                let debugUser = false
                if (!PROD) debugUser = true
                else if (PROD && user.profile.role?.includes('DebugMode')) debugUser = true

                let role = user.profile.role
                if (role === undefined || role === null) role = []
                if (typeof role === 'string') role = role.split(',')

                const userModel: UserModel = {
                  username: user.profile.preferred_username,
                  email: user.profile.email,
                  preferred_username: user.profile.preferred_username,
                  role: role,
                  debugUser,
                }

                dispatch(props.fulfillUser(userModel))
                dispatch(props.refreshToken(user.access_token))
              }
            }
          }
        } catch (error) {
          console.error(error)
          if (!didRequest.current) {
            isProd && signOutRedirect()
            dispatch(props.logout())
          }
        } finally {
          setShowSplashScreen(false)
        }
        return () => (didRequest.current = true)
      }
      if (isProd) {
        user = await getUser()
        if (user && user !== null) requestUser()
        else {
          dispatch(props.logout())
          setShowSplashScreen(false)
          signInRedirect()
        }
      } else {
        if (accessToken) requestUser()
        else {
          dispatch(props.logout())
          setShowSplashScreen(false)
        }
      }
    })()

    // if (oidcToken || (!isProd && accessToken)) {
    //   requestUser()
    // } else {
    //   dispatch(props.logout())
    //   setShowSplashScreen(false)
    //   if (isProd && window.location.pathname != '/auth/logout') {
    //     signInRedirect()
    //   }
    // }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
