import {useState} from 'react'
import {signInRedirect} from './redux/AuthCRUD'

export function Logout() {
  const [loading, setLoading] = useState(false)
  const onSubmit = () => {
    setLoading(true)
    signInRedirect()
  }
  return (
    <>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10'>
        <div className='w-lg-500px bg-white p-10 p-lg-15 mx-auto'>
          <div className='fw-bolder fs-2 pb-5'>Account Logout</div>
          <label className='mb-10 text-gray-700  fw-bolder fs-6'>
            {loading ? (
              <>
                {' '}
                <button
                  type='button'
                  className='btn btn-primary me-10'
                  data-kt-indicator='on'
                  onClick={onSubmit}
                >
                  <span className='indicator-label'>Continue login</span>
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </>
            ) : (
              <>
                <button
                  type='button'
                  className='btn btn-primary me-10'
                  data-kt-indicator='off'
                  onClick={onSubmit}
                >
                  <span className='indicator-label'>Continue login</span>
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </>
            )}
          </label>
        </div>
        {/* end::Wrapper   src\_metronic\assets\sass\components\_variables.custom.scss*/}
      </div>
    </>
  )
}
