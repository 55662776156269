import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import {CloseButtonProps, ToastContainer} from 'react-toastify'
import {KTSVG} from '../_metronic/helpers'
import {IconPaths} from './modules/dashboard/common-utilities/ui/icons/iconPaths'

const App = () => {
  const closeButton = ({closeToast}: CloseButtonProps) => {
    return (
      <span onClick={closeToast} className='linkedTitle'>
        <KTSVG path={IconPaths.crossClose} className='svg-icon-2x svg-icon-primary' />
      </span>
    )
  }
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ToastContainer
        position='top-right'
        autoClose={7000}
        newestOnTop={true}
        closeOnClick
        theme='colored'
        closeButton={closeButton}
        hideProgressBar={false}
        progressStyle={{animationDirection: 'reverse'}}
      />
      <I18nProvider>
        {/* <AuthProvider {...oidcConfig}> */}
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
        {/* </AuthProvider> */}
      </I18nProvider>
    </Suspense>
  )
}

export {App}
