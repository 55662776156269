import {FC} from 'react'
import {ErrorText, H3Title} from '../ui/Headings'

const ContentLoading = ({text}: {text?: string}) => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
    display: 'flex',
  }

  return (
    <div style={{...styles, position: 'absolute', textAlign: 'center'}}>
      {text ? text : `Processing...`}
      <span className='indicator-progress' style={{display: 'block'}}>
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    </div>
  )
}
type IPros = {
  text?: string
}
const LoadingText = ({text = 'Loading data...'}: IPros) => {
  return (
    <div className='d-flex align-items-center'>
      <H3Title color={'primary'}>{text}</H3Title>
      <span className='indicator-progress' style={{display: 'block'}}>
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    </div>
  )
}
type ContainerProps = {
  isLoading: boolean
  errorBlockRequired: boolean
  error?: string | JSX.Element
  children: JSX.Element
  centerContent?: boolean
}
const LoadingContainer: FC<ContainerProps> = ({
  children,
  errorBlockRequired,
  isLoading,
  centerContent,
  error,
}) => {
  if (isLoading && centerContent) return <ContentLoading />
  else if (isLoading && centerContent === undefined) return <LoadingText />
  else if (errorBlockRequired && !isLoading && error && error !== '')
    return <ErrorText>{error}</ErrorText>
  else return children
}
export {ContentLoading, LoadingText, LoadingContainer}
