import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {signOutRedirect} from '../../../../app/modules/auth/redux/AuthCRUD'
import {RootState} from '../../../../setup'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {removeCardSessionData, resetAllSlices} from '../../../../app/modules/redux/resetActions'
import {useAgedPayableSlice} from '../../../../app/modules/dashboard/common-utilities/hooks/storeData'
const AccountMenus = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const {filterValues, level2Filters} = useAgedPayableSlice()
  const logout = () => {
    resetAllStates()

    dispatch(auth.actions.logout())

    process.env.NODE_ENV === 'production' && signOutRedirect()
  }

  const resetAllStates = () => {
    if (filterValues.library !== '')
      localStorage.setItem(
        `agedConfig_${user.email}`,
        JSON.stringify({filterValues, level2Filters})
      )
    removeCardSessionData()
    dispatch(resetAllSlices())
  }

  return (
    <>
      <div className='menu-item px-3 bg-gray-100'>
        <div className='menu-content d-flex px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>Hi {user?.given_name}</div>
            <div className='fw-bolder d-flex align-items-center fs-5 mt-1'>{user?.family_name}</div>
            <span className='badge badge-white border border-3 text-primary fw-bolder fs-7 py-2 my-2'>
              {user && user.role && user.role.join(',')}
            </span>
            <a
              href={`mailto:${user?.email}`}
              className='fw-bold text-primary fs-6 '
              style={{overflowWrap: 'anywhere'}}
            >
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='menu-item px-5  border-gray-300 border-bottom '>
        <Link
          to={''}
          onClick={() => {
            window.location.replace('https://ideng.redmap.com/Manage')
          }}
          className='menu-link px-5 link2-semibold'
        >
          Update Profile
        </Link>
      </div>
      <div className='menu-item px-5  border-gray-300 border-bottom '>
        <Link
          to={''}
          className='menu-link px-5 link2-semibold'
          onClick={() => window.location.replace('https://ideng.redmap.com/Manage/ChangePassword')}
        >
          Change Password
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}
      <div className='menu-item px-5 bg-gray-100'>
        <a onClick={logout} className='menu-link px-5 link2-semibold'>
          Sign Out
        </a>
      </div>
    </>
  )
}

export default AccountMenus
