import React, {useLayoutEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {ILineChartConfig} from '../../models/chart'
import {getCommonXYChartConfigs} from './variables'
type IProps = {
  config: ILineChartConfig
}
function AmLineChart({config}: IProps) {
  const {data, categoryField, containerId, height, width, valueField} = config
  useLayoutEffect(() => {
    let root = am5.Root.new(containerId)
    root.setThemes([am5themes_Animated.new(root)])

    getCommonXYChartConfigs({categoryField, root, valueField}).then(({chart, xAxis, yAxis}) => {
      xAxis.data.setAll(data)

      // Create line series
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: valueField,
          categoryXField: categoryField,
        })
      )
      series.strokes.template.setAll({
        strokeWidth: 3,
      })

      series.bullets.push(function () {
        var bulletCircle = am5.Circle.new(root, {
          radius: 5,
          fill: am5.color('#fff'),
          tooltipText: ` {categoryX} : $ {valueY}`,
          tooltipY: am5.percent(90),
          stroke: am5.color('#0077CC'),
          strokeWidth: 2,
        })
        return am5.Bullet.new(root, {
          sprite: bulletCircle,
        })
      })

      series.data.setAll(data)
      series.appear(1000, 400)
    })
    return () => {
      root.dispose()
    }
  }, [])

  return (
    <div
      id={containerId}
      className='py-3'
      style={{height: `${height}px`, width: width ? `${width}px` : `100%`}}
    ></div>
  )
}

export default AmLineChart
