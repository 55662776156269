import React, {useEffect, useMemo, useState} from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFilters,
  Column,
  useColumnOrder,
  useGroupBy,
  useExpanded,
  SortingRule,
  ColumnInstance,
  useGlobalFilter,
} from 'react-table'
import {KTSVG} from '../../../../../_metronic/helpers'
import {sortNumbers, sortString} from '../../common-utilities/sorting/SortingMethods'

import MuiSelectField from '../../common-utilities/ui/MuiSelectField'
import {IconPaths} from '../../common-utilities/ui/icons/iconPaths'

import {DefaultColumnFilter} from './ColumnFilters'
import ColumnFilterPanel from './ColumnFilterPanel'
import ColumnGroupBy from './ColumnGroupBy'
import MuiCustomSelect from '../detail-view/components/MuiCustomSelect'
import Pagination from './Pagination'
import {Grid} from '@mui/material'
import ExportHoc, {IPropsExports} from '../detail-view/components/excel-export/ExportHoc'
import {showErrorToast} from '../../common-utilities/alerts/AlertModels'

export type ExtraConfigType = {
  order?: 'asc' | 'desc'
  fieldType?: 'string' | 'number'
  sort?: string
  hiddenColumns?: string[]
  isChildren?: boolean
  childColumns?: Column[]
  childCheckPropertyName?: string
  selectionRequired?: boolean
  path?: string
  defaultGroupBy?: string[]
  defaultSortBy?: Array<SortingRule<{}>>
  columnOrder?: string[]
  allowGroupBy?: boolean
  allowReorder?: boolean
  columnsToToggle?: string[]
  defaultFilters?: {id: string; value: any}[]
  defaultExpand?: any
}

export type ReactTableProps = {
  columns: Column[]
  data: any
  reportConfig?: ExtraConfigType
  isFooterRow?: boolean
  deleteSelected?: (values: any) => void
  exportSelected?: (values: any) => void
  getHeaderProps?: (column: ColumnInstance) => {
    className?: string
    style?: React.CSSProperties
  }
  tableClass?: string
  globalSearch?: string
  reference?: React.LegacyRef<HTMLTableElement>
  headerRowTitle?: JSX.Element
  footerOnLast?: boolean
  exportConfig?: IPropsExports
}
const DataTable = ({
  columns,
  data,
  reportConfig,
  isFooterRow,

  getHeaderProps,
  tableClass = '',
  globalSearch = '',
  reference,
  headerRowTitle,
  footerOnLast = false,
  exportConfig,
}: ReactTableProps) => {
  const [temp, setTemp] = useState<any[]>([])
  useEffect(() => {
    setTemp(getSortData(data))
  }, [data, reportConfig])

  const getSortData = (list: any) => {
    if (reportConfig && reportConfig.sort && reportConfig.order) {
      let d =
        reportConfig.fieldType === 'string'
          ? sortString(list, reportConfig.order, reportConfig.sort)
          : sortNumbers(list, reportConfig.order, reportConfig.sort)
      return d
    } else {
      return list
    }
  }

  const defaultColumn: Partial<Column<object>> | undefined = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      disableFilters: true,
      maxWidth: 100,
      width: 100,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    rows,
    pageOptions,
    state,
    gotoPage,
    setPageSize,
    setSortBy,
    allColumns,
    setColumnOrder,
    setGroupBy,
    visibleColumns,

    setHiddenColumns,

    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: temp,
      initialState: {
        sortBy: reportConfig && reportConfig.defaultSortBy ? reportConfig.defaultSortBy : [],

        pageIndex: 0,
        pageSize: 25,
        hiddenColumns:
          reportConfig !== undefined && reportConfig.hiddenColumns !== undefined
            ? reportConfig.hiddenColumns
            : [],
        columnOrder: reportConfig && reportConfig.columnOrder ? reportConfig.columnOrder : [],
        groupBy: reportConfig && reportConfig.defaultGroupBy ? reportConfig.defaultGroupBy : [],
        expanded: reportConfig && reportConfig.defaultExpand ? reportConfig.defaultExpand : {},
      },
      defaultColumn,
    },
    useFilters,
    (hooks) => {
      return reportConfig && reportConfig.selectionRequired
        ? hooks.visibleColumns.push((columns) => {
            return [
              {
                id: 'selection',
                Header: 'All',
                Cell: ({row}) => (
                  <input
                    type='checkbox'
                    className='form-check-input'
                    {...row.getToggleRowSelectedProps()}
                  />
                ),
                disableSortBy: true,
              },
              ...columns,
            ]
          })
        : [...columns]
    },
    useColumnOrder,
    useGlobalFilter,
    useGroupBy,
    useSortBy,

    useExpanded,
    usePagination,
    useRowSelect
  )
  useMemo(() => {
    setGlobalFilter(globalSearch)
  }, [globalSearch, setGlobalFilter])
  const {pageIndex, pageSize, groupBy, sortBy} = state
  useMemo(() => {
    if (reportConfig && reportConfig.columnOrder) {
      setColumnOrder(reportConfig?.columnOrder)
    }
  }, [reportConfig?.columnOrder, setColumnOrder])
  useMemo(() => {
    if (reportConfig && reportConfig.hiddenColumns) setHiddenColumns(reportConfig.hiddenColumns)
  }, [reportConfig?.hiddenColumns, setHiddenColumns])
  const sortedData = useMemo(() => {
    let tempsort = rows.filter((item) => item.original).map((ele) => ele.original)

    return tempsort
  }, [rows])

  const getHeaderClass = (column: ColumnInstance) => {
    const {id} = column

    let col = exportConfig?.columns.find(
      (ele) =>
        ele.FieldName.toLowerCase() === id.toLowerCase() ||
        ele.caption?.toLowerCase() === id.toLowerCase()
    )
    if (col) {
      let {FieldType, FieldName} = col
      switch (true) {
        case FieldType === 'string' ||
          FieldType === 'datetime' ||
          FieldName.toLowerCase() === 'itemid':
          return {className: 'justify-content-start text-nowrap'}
        case FieldType === 'number' || FieldType === 'double':
          return {className: 'justify-content-end text-nowrap '}

        default:
          return {className: 'justify-content-center text-nowrap'}
      }
    } else return {className: 'justify-content-center text-nowrap'}
  }
  return (
    <>
      <div className='d-flex align-items-center'>
        {reportConfig?.allowReorder && (
          <MuiCustomSelect
            items={
              reportConfig?.columnsToToggle
                ? reportConfig?.columnsToToggle
                : allColumns.map((ele) => ele.id)
            }
            onFinish={(hiddCol, colOrder) => {
              setColumnOrder(colOrder)
              setHiddenColumns(hiddCol)
            }}
            selectedData={visibleColumns.map((ele) => ele.id)}
            uniqueProp=''
          />
        )}
        {reportConfig?.allowGroupBy && (
          <ColumnGroupBy columns={visibleColumns} setGroupBy={setGroupBy} groupBy={groupBy} />
        )}
      </div>
      {exportConfig && sortedData && (
        <ExportHoc
          {...exportConfig}
          serverPdfExtraProps={{
            isOrderByDescending: sortBy[0]?.desc ? true : false,
            sortingOrderColumn: sortBy[0]?.id,
          }}
          data={sortedData}
        />
      )}
      <div className='table-responsive '>
        <table
          className={`table align-middle  table-row-bordered  fs-6 gy-5 dataTable no-footer ${tableClass}`}
          {...getTableProps()}
          ref={reference}
        >
          <thead>
            {headerGroups.map((group) => (
              <tr
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 mainTableHeaderRow'
                {...group.getHeaderGroupProps()}
              >
                {group.headers.map((column) => {
                  let data = getHeaderProps ? getHeaderProps(column) : getHeaderClass(column)

                  return (
                    <th {...column.getHeaderProps()}>
                      <span
                        className={`headContent  text-nowrap ${
                          data.className ? data.className : ''
                        }`}
                      >
                        {column.render('Header')}

                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                        {!column.disableSortBy && (
                          <span className='d-flex flex-column mx-2 sortIcon'>
                            <KTSVG
                              path={IconPaths.ascIcon}
                              className={`${
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? 'svg-icon-gray-400'
                                    : 'svg-icon-primary'
                                  : 'svg-icon-gray-400'
                              }`}
                              onClick={() => {
                                setSortBy([{id: column.id}])
                                column.toggleSortBy(false)
                              }}
                            />

                            <KTSVG
                              path={IconPaths.descIcon}
                              className={`${
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? 'svg-icon-primary'
                                    : 'svg-icon-gray-400'
                                  : 'svg-icon-gray-400'
                              }`}
                              onClick={() => {
                                setSortBy([{id: column.id, desc: true}])
                                column.toggleSortBy(true)
                              }}
                            />
                          </span>
                        )}
                        {column.canFilter && (
                          <ColumnFilterPanel
                            column={column}
                            setSortBy={setSortBy}
                            setGroupBy={setGroupBy}
                            groupBy={groupBy}
                          >
                            {column.render('Filter')}
                          </ColumnFilterPanel>
                        )}
                      </span>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {headerRowTitle && (
              <tr>
                <td>{headerRowTitle}</td>
                <td colSpan={columns.length - 1}></td>
              </tr>
            )}
            {page.map((row, i) => {
              prepareRow(row)
              if (row.isGrouped) {
                return (
                  <>
                    <tr className='bg-white' {...row.getRowProps()}>
                      <td colSpan={row.cells.length - 1}>{row.cells[0].render('Cell')}</td>
                    </tr>
                    {/* {groupBy.length === 1 &&
                      row.subRows.map((ele) => {
                        prepareRow(ele)
                        return (
                          <>
                            <tr {...ele.getRowProps()}>
                              {ele.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              })}
                            </tr>
                          </>
                        )
                      })} */}
                  </>
                )
              } else {
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              }
            })}
          </tbody>
          {footerOnLast && pageOptions.length - 1 === pageIndex ? (
            <tfoot style={{background: '#ffffff'}}>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
          ) : (
            <>
              {isFooterRow && (
                <tfoot style={{background: '#ffffff'}}>
                  {footerGroups.map((group) => (
                    <tr {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              )}
            </>
          )}
        </table>
      </div>
      {data.length > pageSize && (
        <Grid container spacing={2} className='align-items-center mt-8'>
          <Grid item xs={4} md={2} xl={1}>
            <MuiSelectField
              changedValues={(val) => {
                if (val === '') {
                  showErrorToast({message: 'Cannot set blank'})
                  setPageSize(25)
                } else setPageSize(Number(val))
              }}
              hasError={false}
              errorText=''
              id='rowSize'
              isRequired={false}
              items={['10', '15', '25', '50', '100']}
              val={pageSize.toString()}
              label='Show results'
              disableClear={true}
            />
          </Grid>
          <Grid item xs={8} sm={3}>
            <div className={'fs-4 mx-5'}>
              {`Showing page ${pageIndex + 1} of ${pageOptions.length}`}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Pagination
              currentPage={pageIndex}
              goBack={previousPage}
              goNext={nextPage}
              goToPage={gotoPage}
              totalPages={pageOptions.length}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default DataTable
