import axios from 'axios'
import {
  IAuditData,
  IImportPostData,
  IImportResponseData,
  IWidgetModel,
  WidgetDataModel,
} from '../../dashboard/models/type'
import {BATCH_UPDATE_URL, IMPORT_URL, WIDGET_URL} from '../EndPoints'

export function getAllWidgets() {
  return axios.get<IWidgetModel[]>(`${WIDGET_URL}/GetAll`)
}

export function getWidgetDataById(widgetId: number, pageNo: number, pageSize: number) {
  return axios.get<WidgetDataModel>(
    `${WIDGET_URL}/GetDataById?widgetId=${widgetId}&pageNo=${pageNo}&pageSize=${pageSize}`
  )
}

export function saveGridShortConfig(parms: any) {
  return axios.post<IWidgetModel>(`${WIDGET_URL}/Upsert`, parms)
}

// Publish Widget API'S
// export function getPublishWidgetData(widgetId: number) {
//   return axios.get<IPublishWidget[]>(`${PUBLISH_WIDGET_CONFIG_URL}/GetById?widgetId=${widgetId}`)
// }

// export function createPublishWidgetData(parms: any) {
//   return axios.post<IPublishWidget>(`${PUBLISH_WIDGET_CONFIG_URL}/Create`, parms)
// }

// export function updatePublishWidgetData(parms: any) {
//   console.log('updated Parms', parms)
//   return axios.put<IPublishWidget>(`${PUBLISH_WIDGET_CONFIG_URL}/Update`, parms)
// }

// export function deletePublishWidgetData(parms: any) {
//   console.log('deleted Parms', parms)
//   return axios.delete<IPublishWidget>(`${PUBLISH_WIDGET_CONFIG_URL}`, {data: parms})
// }

export function saveGridConfiguration(parms: any) {
  return axios.post<IWidgetModel[]>(`${WIDGET_URL}/Upsert`, parms)
}

export function getWidgetConfigById(widgetId: number) {
  return axios.get<IWidgetModel>(`${WIDGET_URL}/GetById?widgetId=${widgetId}`)
}
export function getColumnHints(id: number, columnName: string) {
  return axios.get(`${WIDGET_URL}/Hints?widgetId=${id}&columnName=${columnName}`)
}
export function deleteWidget(id: number) {
  return axios.delete<boolean>(`${WIDGET_URL}?widgetId=${id}`)
}
export function updateBatchUpdateItems(parms: any) {
  return axios.post(`${BATCH_UPDATE_URL}/BatchUpdate`, parms)
}

export function getAuditData(id: number, table: string) {
  return axios.get<IAuditData[]>(`${WIDGET_URL}/Audit?widgetId=${id}&tableName=${table}`)
}

export function importCSVData(data: IImportPostData) {
  return axios.post<IImportResponseData>(`${IMPORT_URL}/Import`, data)
}
