import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../setup/redux/RootReducer'
import {QueryState} from '../dashboard/models/reactTableConfigType'
const initialReportState: QueryState = {
  items_per_page: 10,
  page: 1,
  filter: undefined,
  links: [{label: '', active: false, url: '', page: 1}],
  order: 'asc',
  sort: undefined,
  fieldType: 'string',
  search: '',
  isLoading: false,
  currentReportId: undefined,
  reportData: undefined,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const reactTableSlice = createSlice({
  name: 'reactTable',
  initialState: initialReportState,
  reducers: {
    startCall: (state: QueryState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    resetConfig: (state: QueryState, action: PayloadAction<boolean>) => {
      state.items_per_page = 10
      state.page = 1
      state.filter = undefined
      state.links = [{label: '', active: false, url: '', page: 1}]
      state.order = 'asc'
      state.sort = undefined
      state.search = ''
      state.isLoading = false
      state.fieldType = 'string'
      if (action.payload) {
        state.currentReportId = undefined
        state.reportData = undefined
      }
    },
    updateConfig: (state: any, action: PayloadAction<any>) => {
      let temp = state
      let data = action.payload

      let finalData = {...temp, ...data}

      return {...finalData}
    },
  },
})

export const {startCall, resetConfig, updateConfig} = reactTableSlice.actions

export const reportConfig = (state: RootState) => state.reportConfig

export default reactTableSlice.reducer
