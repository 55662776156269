import {lazy} from 'react'
import {NewDashboardWrapper} from '../pages/new-dashboard/NewDashboardWrapper'
import {MenuDataTypes} from '../modules/dashboard/models/type'
import {configurationRoutes} from './configurationRoutes'
import {IconPaths} from '../modules/dashboard/common-utilities/ui/icons/iconPaths'
import {administrationRoutes} from './administrationRoutes'
import {DrillDownView, SummaryView} from '../modules/dashboard/widgets/detail-view/Views'
import {useAuthSlice} from '../modules/dashboard/common-utilities/hooks/storeData'
const DetailViewPanel = lazy(
  () => import('../modules/dashboard/widgets/cards/detail-view/DetailViewPanel')
)
const UnderConstruction = lazy(
  () => import('../modules/dashboard/common-utilities/ui/UnderConstruction')
)
const UtilitiesPanel = lazy(() => import('../modules/dashboard/utilities'))

const MyFormsPanel = lazy(() => import('../modules/dashboard/my-forms'))
const Setting = lazy(() => import('../modules/dashboard/settings'))
const AdministrationPanel = lazy(() => import('../modules/dashboard/adminstration'))
const ConfigurationsPanel = lazy(() => import('../modules/dashboard/configurations'))
const HelpPanel = lazy(() => import('../modules/dashboard/help'))
const ProductivityDetailPanel = lazy(
  () => import('../modules/dashboard/widgets/productivity-report/detail-view/DetailPanel')
)
const REDMAP_URL = process.env.REACT_APP_REDMAP_URL

const useRouteData = () => {
  const {user} = useAuthSlice()
  const RouteData: MenuDataTypes[] = [
    {
      linkPath: '/home',
      routePath: '/home',
      component: NewDashboardWrapper,
      navigatePath: '/home',
      isForDirectLogin: false,
      show: true,
      key: 'home',
      isLazy: false,
      title: 'Home',
      icon: IconPaths.homeIcon,
      role: [],
      disabled: false,
      isMenuLink: true,
    },

    {
      linkPath: '',
      routePath: '/agedPayableSummary',
      component: SummaryView,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: true,
      key: 'agedPayableSummary',
      isLazy: true,
      title: 'Aged payable summary',
      icon: '/media/svg/general/home.svg',
      role: [],
      disabled: false,
      isMenuLink: false,
      subMenu: [
        {
          linkPath: '',
          routePath: '/details',
          component: DrillDownView,
          navigatePath: undefined,
          isForDirectLogin: false,
          show: true,
          key: 'agedPayableDetails',
          isLazy: true,
          title: '',
          icon: '/media/svg/general/home.svg',
          role: [],
          disabled: false,
          isMenuLink: false,
        },
      ],
    },
    {
      linkPath: '/search',
      routePath: '/search',
      component: UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: true,
      key: 'search',
      isLazy: true,
      title: 'Search',
      icon: IconPaths.search,
      role: [],
      disabled: false,
      isMenuLink: true,
      redirectLink: `${REDMAP_URL}/Search/Main`,
    },
    {
      linkPath: '/tasks',
      routePath: '/tasks',
      component: UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: true,
      key: 'tasks',
      isLazy: true,
      title: 'My Tasks',
      icon: IconPaths.taskMenuIcon,
      role: [],
      disabled: false,
      isMenuLink: true,
      redirectLink: `${REDMAP_URL}/Workflow/Main`,
    },

    {
      linkPath: '/requests',
      routePath: '/requests',
      component: UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: true,
      key: 'requests',
      isLazy: true,
      title: 'My Requests',
      icon: IconPaths.requestsIcon,
      role: [],
      disabled: false,
      isMenuLink: true,
      redirectLink: `${REDMAP_URL}/eForms/Forms`,
    },
    {
      linkPath: '/notifications',
      routePath: '/notifications',
      component: UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: true,
      key: 'notifications',
      isLazy: true,
      title: 'Notifications',
      icon: IconPaths.notificationIcon,
      role: [],
      disabled: false,
      isMenuLink: false,
    },
    {
      linkPath: '/reports',
      routePath: '/reports',
      component: UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: false,
      key: 'reports',
      isLazy: true,
      title: 'Reports',
      icon: IconPaths.reportsIcon,
      role: [],
      disabled: false,
      isMenuLink: true,
    },
    {
      linkPath: '/upload',
      routePath: '/upload',
      component: UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: true,
      key: 'upload',
      isLazy: true,
      title: 'Upload',
      icon: IconPaths.uploadMenuIcon,
      role: [],
      disabled: false,
      isMenuLink: true,
    },
    {
      linkPath: '/utilities',
      routePath: '/utilities',
      component: UtilitiesPanel,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: false,
      key: 'utilities',
      isLazy: true,
      title: 'Utilities',
      icon: '/media/svg/general/utilities.svg',
      role: [],
      disabled: false,
      isMenuLink: false,
    },
    {
      linkPath: '/myForms',
      routePath: '/myForms',
      component: MyFormsPanel,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: false,
      key: 'myform',
      isLazy: true,
      title: 'My Forms',
      icon: '/media/svg/general/myforms.svg',
      role: [],
      disabled: false,
      isMenuLink: false,
    },
    {
      linkPath: '/settings',
      routePath: '/settings',
      component: Setting,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: false,
      key: 'setting',
      isLazy: true,
      title: 'Setting',
      icon: '/media/icons/duotune/coding/cod001.svg',
      role: [],
      disabled: false,
      isMenuLink: false,
    },

    {
      linkPath: '/administration',
      routePath: '/administration',
      component: user?.debugUser ? AdministrationPanel : UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: true,
      key: 'adminstration',
      isLazy: true,
      title: 'Administration',
      icon: IconPaths.adminMenuIcon,
      role: [],
      disabled: false,
      isMenuLink: true,
      subMenu: [...administrationRoutes],
    },
    {
      linkPath: '/configuration',
      routePath: '/configuration',
      component: user?.debugUser ? ConfigurationsPanel : UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: true,
      key: 'configuration',
      isLazy: true,
      title: 'Configuration',
      icon: IconPaths.configMenuIcon,
      role: [],
      disabled: false,
      isMenuLink: true,
      subMenu: [...configurationRoutes],
    },
    {
      linkPath: '/help',
      routePath: '/help',
      component: HelpPanel,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: true,
      key: 'help',
      isLazy: true,
      title: 'Help',
      icon: IconPaths.faq,
      role: [],
      disabled: false,
      isMenuLink: true,
    },
    {
      linkPath: '',
      routePath: 'home/card-details/:id',
      component: DetailViewPanel,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: true,
      key: 'cardDetailView',
      isLazy: true,
      title: 'Home',
      icon: '',
      role: [],
      disabled: false,
      isMenuLink: false,
    },
    {
      linkPath: '/feedback',
      routePath: '/feedback',
      component: UnderConstruction,
      navigatePath: undefined,
      isForDirectLogin: true,
      show: true,
      key: 'feedback',
      isLazy: true,
      title: 'Feedback',
      icon: IconPaths.feedback,
      role: [],
      disabled: false,
      isMenuLink: true,
      redirectLink: `https://redmap.atlassian.net/jira/core/projects/SST2/form/11`,
    },
    {
      linkPath: '',
      routePath: '/productivityReport/:category',
      component: ProductivityDetailPanel,
      navigatePath: undefined,
      isForDirectLogin: false,
      show: true,
      key: 'productivityReport',
      isLazy: true,
      title: 'Productivity report',
      icon: '',
      role: [],
      disabled: false,
      isMenuLink: false,
      subMenu: [],
    },
  ]
  return RouteData
}

export default useRouteData
