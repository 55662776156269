import React, {useEffect, useState, useCallback} from 'react'
import {IWidgetModel} from './models/type'
import {Layout, Layouts, Responsive, WidthProvider} from 'react-grid-layout'
import GridContainer from './GridContainer'
import _ from 'lodash'
import {useAuthSlice} from './common-utilities/hooks/storeData'

const ResponsiveGridLayout = WidthProvider(Responsive)

const defaultPositions: {[p: string]: Layout} = {
  lg: {x: 0, y: 0, w: 4, h: 3, i: '', minW: 4, minH: 3},
  md: {x: 0, y: 0, w: 4, h: 3, i: '', minW: 4, minH: 3},
  sm: {x: 0, y: 0, w: 1, h: 3, i: '', minW: 1, minH: 3},
}
type IProps = {
  data: IWidgetModel[]
}
const DynamicLayout = ({data}: IProps) => {
  const {user} = useAuthSlice()
  const calculateNewLayout = (widgets: IWidgetModel[], widgetList: number[], layout: Layouts) => {
    const newWidgets = widgets.filter((ele) => !widgetList.includes(ele.widgetId))
    if (!_.isEmpty(layout)) {
      if (newWidgets.length === 0) return layout

      const newLayout = {...layout}
      Object.keys(layout).forEach((breakpoint) => {
        const newTemp = newWidgets.map((ele) => ({
          ...defaultPositions[breakpoint],
          i: ele.widgetId.toString(),
        }))
        newLayout[breakpoint] = [...newTemp, ...newLayout[breakpoint]]
      })
      return newLayout
    }
    if (widgets.length !== 0) {
      const newLayout: {[p: string]: Layout[]} = {lg: [], md: [], sm: []}
      widgets.forEach((w) => {
        const widgetLayout = w.pfStream ? JSON.parse(w.pfStream) : defaultPositions.lg
        ;['lg', 'md', 'sm'].forEach((breakpoint) => {
          newLayout[breakpoint].push({
            x: widgetLayout.x || defaultPositions[breakpoint].x,
            y: widgetLayout.y || defaultPositions[breakpoint].y,
            w: widgetLayout.w || defaultPositions[breakpoint].w,
            h: widgetLayout.h || defaultPositions[breakpoint].h,
            i: w.widgetId.toString(),
            minW: 4,
            minH: 3,
          })
        })
      })
      return newLayout
    } else return {}
  }

  //Get layout from local storage
  const getLayoutFromLS = () => {
    const widgetList = JSON.parse(localStorage.getItem('widgetList_' + user?.email) || '[]')
    const layout: Layouts = JSON.parse(localStorage.getItem('layout_' + user?.email) || '{}')
    return {widgetList, layout}
  }
  const getInitialLayout = (widgets: IWidgetModel[]) => {
    const {widgetList, layout} = getLayoutFromLS()
    return calculateNewLayout(widgets, widgetList, layout)
  }
  const [layouts, setLayouts] = useState<Layouts>(() => getInitialLayout(data))
  const storeToLS = (layouts: Layouts, widgets: IWidgetModel[]) => {
    localStorage.setItem(`layout_${user?.email}`, JSON.stringify(layouts))
    localStorage.setItem(
      `widgetList_${user?.email}`,
      JSON.stringify(widgets.map((ele) => ele.widgetId))
    )
  }
  useEffect(() => {
    const {widgetList, layout} = getLayoutFromLS()
    const newLayouts = calculateNewLayout(data, widgetList, layout)
    setLayouts(newLayouts)
    storeToLS(newLayouts, data)
  }, [data])

  useEffect(() => {
    storeToLS(layouts, data)
  }, [layouts])

  const handleLayoutChange = useCallback((current: Layout[], allLayout: Layouts) => {
    setLayouts(allLayout)
  }, [])

  return (
    <>
      {data.length > 0 && !_.isEmpty(layouts) && (
        <ResponsiveGridLayout
          className='layout'
          layouts={layouts}
          isDraggable={true}
          onLayoutChange={handleLayoutChange}
          breakpoints={{lg: 1200, md: 996, sm: 480}}
          cols={{lg: 12, md: 8, sm: 1}}
          draggableHandle='.card-drag-area'
          isResizable
        >
          {data.map((w) => (
            <GridContainer key={w.widgetId} item={w} />
          ))}
        </ResponsiveGridLayout>
      )}
    </>
  )
}

export default React.memo(DynamicLayout)
