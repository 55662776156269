import React, {useState} from 'react'
import Popover from '@mui/material/Popover'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IconPaths} from '../../../common-utilities/ui/icons/iconPaths'
import {getInvoicePdf} from '../../../../redux/kpi/api'
import {getErrorMessage} from '../../../common-utilities/alerts/AlertModels'
import {ContentLoading} from '../../../common-utilities/loading/ContentLoading'
import {ErrorText} from '../../../common-utilities/ui/Headings'
type IProps = {
  itemId: number
  library: string
}
export default function BasicPopover({itemId, library}: IProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setLoading(true)
    try {
      getInvoicePdf(library, itemId)
        .then((res) => {
          const file = new Blob([res.data], {type: 'application/pdf'})
          const fileURL = URL.createObjectURL(file)

          setData(fileURL)
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (err) {
      setLoading(false)
      setData(undefined)
      setError('Failed to process data')
    }
  }

  const handleClose = () => {
    setLoading(false)
    setData(undefined)
    setError('')
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? `simple-popover_${itemId}` : undefined

  return (
    <div>
      <span
        aria-describedby={id}
        style={{cursor: 'pointer'}}
        onClick={handleClick}
        id={`pdfBtn_${itemId}`}
        className='mx-2'
        title='Invoice PDF'
      >
        <KTSVG
          path={IconPaths.pdfFileIcon}
          className='svg-icon-danger svg-icon-2hx'
          alt='Invoice preview'
          title='Invoice preview'
        />
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div
          className='d-flex justify-content-center align-items-center'
          style={{height: 600, width: 600}}
        >
          {loading && <ContentLoading />}
          {!loading && error.length > 0 && <ErrorText>{error}</ErrorText>}
          {data && <object data={data} height={600} width={600} />}
        </div>
      </Popover>
    </div>
  )
}
