import {useState, useEffect} from 'react'
import {IwidgetDataModel, IChartGrid} from '../../models/type'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import AmPieChart from '../../common-utilities/am-charts/AmPieChart'
import AmColumnChart from '../../common-utilities/am-charts/AmColumnChart'
import AmBarChart from '../../common-utilities/am-charts/AmBarChart'
import AmLineChart from '../../common-utilities/am-charts/AmLineChart'
import AmAreaChart from '../../common-utilities/am-charts/AmAreaChart'
import AmRadarChart from '../../common-utilities/am-charts/AmRadarChart'

type IChartPanelProps = {
  contentId: number
  name: string
  widgetConfig: IChartGrid | undefined
  height: number
  width: number
}
const ChartContainer = ({contentId, name, widgetConfig, height, width}: IChartPanelProps) => {
  const widgetData: IwidgetDataModel = useSelector<RootState>(
    ({dashboard}) =>
      dashboard.widgetsData.find((item: IwidgetDataModel) => item.widgetId === contentId),
    shallowEqual
  ) as IwidgetDataModel
  // const dispatch = useDispatch()

  const [chartType, setChartType] = useState<string | undefined>(undefined)
  useEffect(() => {
    // console.log('chartContainer')
    widgetConfig && setChartType(widgetConfig.ChartType.toLowerCase())
  }, [widgetConfig])

  const GetChart = () => {
    const contentData: any = widgetData.content

    if (chartType === 'pie')
      return (
        <AmPieChart
          config={{
            categoryField: 'category',
            data: contentData.data,
            valueField: 'series',

            height: height,
            width: width,
            containerId: `widgetId_${contentId}`,
          }}
        />
      )
    else if (chartType === 'area')
      return (
        <AmAreaChart
          config={{
            categoryField: 'category',
            data: contentData.data,
            valueField: 'series',

            height: height,
            width: width,
            containerId: `widgetId_${contentId}`,
          }}
        />
      )
    else if (chartType === 'bar')
      return (
        <AmBarChart
          config={{
            categoryField: 'category',
            data: contentData.data,
            valueField: 'series',
            height: height,
            width: width,
            containerId: `widgetId_${contentId}`,
          }}
        />
      )
    else if (chartType === 'line')
      return (
        <AmLineChart
          config={{
            categoryField: 'category',
            data: contentData.data,
            valueField: 'series',
            height: height,
            width: width,
            containerId: `widgetId_${contentId}`,
          }}
        />
      )
    else if (chartType === 'column')
      return (
        <AmColumnChart
          config={{
            categoryField: 'category',
            data: contentData.data,
            valueField: 'series',
            height: height,
            width: width,
            containerId: `widgetId_${contentId}`,
          }}
        />
      )
    else if (chartType === 'radarline')
      return (
        <AmRadarChart
          config={{
            categoryField: 'category',
            data: contentData.data,
            valueField: 'series',
            height: height,
            width: width,
            containerId: `widgetId_${contentId}`,
          }}
        />
      )
    else return <p>No Data</p>
  }

  return (
    <>
      {widgetData !== undefined && (
        <>
          <GetChart />
        </>
      )}
    </>
  )
}

export default ChartContainer
