import React, {useMemo, useState} from 'react'
import {Column} from 'react-table'
import {ErrorText, H3Title, H4Title} from '../../../../common-utilities/ui/Headings'
import {USDollar} from '../../../../common-utilities/sorting/SortingMethods'
import {AgedPayableFieldsTypes} from '../../../../models/type'
import moment from '@date-io/moment'
import {useAgedPayableSlice} from '../../../../common-utilities/hooks/storeData'
import {useDispatch} from 'react-redux'
import {setDetailedViewProp} from '../../../../../redux/AgedPayableSlice'
import {Link, useNavigate} from 'react-router-dom'
import VirtualTable from '../../../tables/VirtualTable'
import {serverPDFUrls} from '../../../../common-utilities/ui/Standards'
export type Fields = {
  FieldName: string
  FieldType: string
}
export type ExcelColumns = {
  FieldName: string
  FieldType: string
  isCurrency: boolean
  totalRequired: boolean
  caption?: string
}

const Level1DataTable = () => {
  const currentDate = new moment()
  const {filterValues, loading, error, isError, reportData, searchTextLevel1} =
    useAgedPayableSlice()
  const [columns, setColumns] = useState<Column[]>([])
  const [data, setData] = useState<any[]>([])
  const [excelColumns, setExcelColumns] = useState<ExcelColumns[]>([])
  const [amount, setAmount] = useState<number>(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getGroupedColumn = (FieldName: string): Column => {
    const accessField = FieldName.toLowerCase()
    return {
      Header: (props: any) => <span>{`${accessField}`}</span>,
      accessor: accessField,
      id: FieldName,
      Cell: ({row}: any) => (
        <Link
          to={`/agedPayableSummary/details`}
          onClick={() => dispatch(setDetailedViewProp([row.original[accessField]]))}
          className='linkedTitle'
        >{`${row.original[accessField]}`}</Link>
      ),
      Footer: () => {
        return (
          <div className='text-nowrap'>
            <H4Title className='py-3'>{`Totals AUD - including tax`}</H4Title>

            <H4Title className='py-3'>{`Percentage of total`}</H4Title>
          </div>
        )
      },
      width: 150,
      maxWidth: 150,
      minWidth: 100,
    }
  }

  const getCurrencyColumns = (item: AgedPayableFieldsTypes, totalAmount: number): Column => {
    return {
      Header: (props: any) => {
        return <span>{item.fieldCaption}</span>
      },

      accessor: item.fieldName,

      Cell: ({row}: any) => {
        return (
          <div
            className={`currencyCell ${
              item.fieldName === 'older' || item.fieldName === 'totalIncTax'
                ? 'fs-3 fw-boldest'
                : ''
            } ${item.fieldName === 'older' ? 'text-primary' : ''}`}
            style={{textAlign: 'end'}}
          >{`${
            row.values[item.fieldName] !== null && row.values[item.fieldName] !== undefined
              ? USDollar.format(row.values[item.fieldName].toFixed(2))
              : '$ 0.00'
          }`}</div>
        )
      },
      Footer: (info: any) => {
        // Only calculate total visits if rows change
        //console.log('info', info)
        const total = React.useMemo(
          () => info.rows.reduce((sum: number, row: any) => row.values[item.fieldName] + sum, 0),
          [info.rows]
        )
        let p = (total / totalAmount) * 100
        return (
          <div style={{textAlign: 'end'}}>
            <H4Title
              className='py-3'
              color={item.fieldName === 'older' ? 'primary' : 'dark'}
            >{`${USDollar.format(total.toFixed(2))}`}</H4Title>

            <H4Title
              className='py-3'
              color={item.fieldName === 'older' ? 'primary' : 'dark'}
            >{`${p.toFixed(2)}%`}</H4Title>
          </div>
        )
      },
      maxWidth: 150,
      minWidth: 100,
    }
  }

  const getToggledColumns = (FieldName: string): Column => {
    const accessField = FieldName.toLowerCase()
    if (accessField === 'supplier' || accessField === 'entity') {
      return {
        Header: (props: any) => {
          return <span>{accessField}</span>
        },

        accessor: accessField,
        id: FieldName,
        Cell: ({row}: any) => {
          return (
            <div
              style={{
                textAlign:
                  accessField === 'entity'
                    ? 'start'
                    : filterValues.groupBy.toLowerCase() === 'entity'
                    ? 'start'
                    : 'end',
              }}
            >{`${
              row.values[FieldName] !== null && row.values[FieldName] !== undefined
                ? row.values[FieldName]
                : '-'
            }`}</div>
          )
        },
        Footer: (info: any) => {
          return (
            <div style={{textAlign: 'end'}}>
              <H4Title className='py-3'>{`-`}</H4Title>

              <H4Title className='py-3'>{`-`}</H4Title>
            </div>
          )
        },
        maxWidth: 150,
        minWidth: 100,
      }
    } else
      return {
        Header: (props: any) => {
          return <span>{accessField}</span>
        },

        accessor: accessField,
        id: FieldName,
        Cell: ({row}: any) => {
          return (
            <div style={{textAlign: 'end'}}>{`${
              row.values[FieldName] !== null && row.values[FieldName] !== undefined
                ? row.values[FieldName]
                : '0'
            }`}</div>
          )
        },
        Footer: (info: any) => {
          if (accessField === 'quantity') {
            // console.log('info', info.rows)
            const total = info.rows.reduce(
              (sum: number, row: any) => row.values[FieldName] + sum,
              0
            )

            return (
              <div style={{textAlign: 'end'}}>
                <H4Title className='py-3'>{`${total}`}</H4Title>

                <H4Title className='py-3'>{`-`}</H4Title>
              </div>
            )
          } else
            return (
              <div style={{textAlign: 'end'}}>
                <H4Title className='py-3'>{`-`}</H4Title>

                <H4Title className='py-3'>{`-`}</H4Title>
              </div>
            )
        },
        maxWidth: 150,
        minWidth: 100,
      }
  }
  useMemo(() => {
    if (isError) {
      setData([])
      setColumns([])
      setExcelColumns([])
      setAmount(0.0)
      return
    }
    if (reportData) {
      const totalAmount = reportData.agedPayableDataView.reduce(
        (sum: number, row: any) => row['totalIncTax'] + sum,
        0
      )

      let firstColumn = getGroupedColumn(filterValues.groupBy)
      let currColumns = reportData.currencyColumns.map((ele) =>
        getCurrencyColumns(ele, totalAmount)
      )
      let toggledColumns = filterValues.columns.map((ele) => getToggledColumns(ele))
      let temp = [firstColumn, ...toggledColumns, ...currColumns]

      let exportColumns: ExcelColumns[] = [
        {
          FieldName: filterValues.groupBy.toLowerCase(),
          FieldType: 'string',
          isCurrency: false,
          totalRequired: false,
        },
      ]
      exportColumns.push(
        ...getExcelColumns(
          filterValues.columns,
          reportData.currencyColumns,
          filterValues.hiddenColumns,
          filterValues.groupBy
        )
      )
      if (temp !== undefined && reportData) {
        setColumns(temp)
        setData(reportData.agedPayableDataView)
        setExcelColumns(exportColumns)
        setAmount(totalAmount)
      }
    }
  }, [filterValues, reportData, isError])

  const handleAllView = () => {
    if (reportData) {
      const repoData = reportData.agedPayableDataView
      let data = repoData.map((ele: any) => ele[filterValues.groupBy.toLocaleLowerCase()])
      dispatch(setDetailedViewProp(data))
      navigate(`/agedPayableSummary/details`)
    }
  }
  const FileName = `${filterValues.library}_${filterValues.groupBy}_${filterValues.interval}_${
    filterValues.ageingPeriod
  }_${currentDate.date().format('DD-MM-YYYY')}`
  return (
    <>
      {loading && (
        <div className='d-flex align-items-center'>
          <H4Title color={'primary'}>{`Processing data please wait...`}</H4Title>
          <span className='indicator-progress' style={{display: 'block'}}>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      )}
      {loading === false && !isError && data !== undefined && data.length === 0 && (
        <ErrorText>We couldn't find any records for this time period.</ErrorText>
      )}
      {loading === false && isError && error && (
        <ErrorText>{error !== null ? error : `Something wrong ...!`}</ErrorText>
      )}
      {!loading && !isError && data !== undefined && data.length > 0 && (
        <>
          <div className='d-flex flex-stack py-2 mb-8'>
            <div>
              <H3Title>{'Aged payables daily summary'}</H3Title>
              <div className='d-flex flex-wrap py-4 text-gray-700'>
                <span className='fs-3 fw-bold'>{` ${filterValues.groupBy}, `}</span>
                &nbsp;&nbsp;
                <span className='fs-3 fw-boldest'>{` ${filterValues.library}, `}</span>&nbsp;&nbsp;
                <span className='fs-3 fw-bold'>{` Ageing by`}</span>&nbsp;&nbsp;
                <span className='fs-3 fw-boldest'>{` ${filterValues.ageingBy}, `}</span>&nbsp;&nbsp;
                <span className='fs-3 fw-bold'>{` as of ${currentDate
                  .date()
                  .format('DD MMM YYYY')}`}</span>
              </div>
            </div>
          </div>

          <VirtualTable
            columns={columns}
            data={data}
            reportConfig={{
              defaultSortBy: [{id: filterValues.groupBy}],
              hiddenColumns: filterValues.hiddenColumns,
              columnOrder: [filterValues.groupBy, ...filterValues.columns],
            }}
            isFooterRow={true}
            tableClass='firstColSticky dividedFooter'
            globalSearch={searchTextLevel1}
            headerRowTitle={
              <h1 className='linkedTitle' onClick={handleAllView}>{`All Aged Payables`}</h1>
            }
            exportConfig={{
              columns: excelColumns,
              data: data,
              totalForPerRow: amount,
              fileName: FileName,
              excelExtraConfig: {
                freezCol: 2,
              },
              pdfLandscape: false,
              serverPdf: {
                url: serverPDFUrls.agedPayableSummary,
                body: {
                  ageingGroupBy: filterValues.groupBy,
                  library: filterValues.library,

                  ageingPeriod: filterValues.ageingPeriod,

                  ageingBy: 'Invoice date' ? 'InvoiceDate' : 'DueDate',
                  columnConfig: filterValues.columns
                    .filter((ele) => !filterValues.hiddenColumns.includes(ele))
                    .toString(),
                  agiengInterval: filterValues.interval,
                },
              },
            }}
            footerOnLast={true}
            horizontalScrollRequired={true}
          />
        </>
      )}
    </>
  )
}

export const getExcelColumns = (
  toggledCol: string[],
  currencyCol: AgedPayableFieldsTypes[],
  hiddCol: string[],
  grp: string
): ExcelColumns[] => {
  let col1: ExcelColumns[] = toggledCol
    .filter((ele) => !hiddCol.includes(ele))
    .map((item) => {
      if (item.toLowerCase() === 'quantity') {
        return {
          FieldName: item.toLowerCase(),
          FieldType: 'number',
          isCurrency: false,
          totalRequired: true,
        }
      } else
        return {
          FieldName: item.toLowerCase(),
          FieldType:
            item.toLowerCase() === 'entity'
              ? 'string'
              : grp.toLowerCase() === 'entity' && item.toLowerCase() === 'supplier'
              ? 'string'
              : 'number',
          isCurrency: false,
          totalRequired: false,
        }
    })

  let col2: ExcelColumns[] = currencyCol.map((item) => {
    return {
      FieldName: item.fieldName,
      FieldType: 'number',
      isCurrency: true,
      totalRequired: true,
      caption: item.fieldCaption,
    }
  })
  return [...col1, ...col2]
}

export default Level1DataTable
