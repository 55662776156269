import React from 'react'
import {IAgedPayableSummaryCard} from '../../models/type'
import AmDonutChart from '../../common-utilities/am-charts/AmDonutChart'
import * as am5 from '@amcharts/amcharts5'
type IProps = {
  mainData: IAgedPayableSummaryCard
}

const AgedPayableChart = ({mainData}: IProps) => {
  const seriesColors = [
    am5.color('#1f3347'),
    am5.color('#00b295'),
    am5.color('#9de2d8'),
    am5.color('#ffee99'),
    am5.color('#ff9900'),
    am5.color('#eb5757'),
  ]
  return (
    <>
      <div className='w-100 h-400px'>
        <AmDonutChart
          config={{
            categoryField: 'fieldName',
            data: mainData.summaryTotalOs,
            valueField: 'totalOutStanding',
            centerLabel: {value: mainData.totalBalance, isAmount: true},
            height: 380,
            legendConfig: {
              isRequired: true,
              position: 'bottom',
            },
            isSemiDonut: true,
            containerId: 'agedPayableDonutChart',
            seriesColors: seriesColors,
          }}
        />
      </div>
    </>
  )
}
export default AgedPayableChart
