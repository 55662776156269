import moment from 'moment'

type IdateFormats = {
  onTable: string
  forRequest: string
  dateTime: string
}
const dateFormats: IdateFormats = {
  onTable: 'DD MMM YYYY',
  forRequest: 'YYYY-MM-DD',
  dateTime: 'DD MMM YYYY , hh:mm a',
}

const dataTypes = (type: string) => {
  switch (type) {
    case 'System.Int32':
    case 'System.Int64':
      return 'number'
    case 'System.Nullable`1[System.DateTime]':
    case 'System.DateTime':
      return 'datetime'
    case 'System.Double':
      return 'currency'
    default:
      return 'string'
  }
}

const errorStatements = {
  noRecords: `We couldn't find any records for this time period.`,
}
const serverPDFUrls = {
  agedPayableSummary: `${process.env.REACT_APP_API_METRICS_URL}/AgedPayable/BySummaryViewPdf`,
  agedPayableSummaryDetail: `${process.env.REACT_APP_API_METRICS_URL}/AgedPayable/ByDetailedReportPdf`,
  cards: `${process.env.REACT_APP_API_METRICS_URL}/Card/GetDetailsPdf`,
}
const iconClasses = {
  size: 'svg-icon-2x',
}
const formatDate = (date: string, format: string) => {
  let formatedDate = moment(date).format(format)

  return formatedDate
}
export {dateFormats, dataTypes, errorStatements, serverPDFUrls, iconClasses, formatDate}
