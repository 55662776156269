import axios from 'axios'
import {
  IAllCards,
  IUserCards,
  CardCountRequestProps,
  ICardResponse,
} from '../../dashboard/models/type'
import {CARDS_URL, CARD_COUNT_URL} from '../EndPoints'

export function getAllCards() {
  return axios.get<IAllCards[]>(`${CARDS_URL}/GetCards`)
}

export function getUserCards() {
  return axios.get<IUserCards[]>(`${CARDS_URL}/GetUserCards`)
}
export function getDefaultCards() {
  return axios.get<IUserCards[]>(`${CARDS_URL}/GetDefaultCards`)
}
export function getRoleSubscribedCards() {
  return axios.get<number[]>(`${CARDS_URL}/GetRoleSubscribedCards`)
}
export function getCardCount({cardtype, libraryName, period, uom}: CardCountRequestProps) {
  return axios.get<ICardResponse>(
    `${CARD_COUNT_URL}/GetValue?UserTimeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&LibraryName=${libraryName}&Period=${period}&CardType=${cardtype}`
  )
}
export function getCardDetails({cardtype, libraryName, period, uom}: CardCountRequestProps) {
  return axios.get<any[]>(
    `${CARD_COUNT_URL}/GetDetails?UserTimeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&LibraryName=${libraryName}&Period=${period}&CardType=${cardtype}`
  )
}
