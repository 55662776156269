import {useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../../setup'
import {IwidgetDataModel, WidgetDataModel, GridData} from '../../models/type'
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'
import {State, process} from '@progress/kendo-data-query'
import {H4Title} from '../../common-utilities/ui/Headings'

type GridReportProps = {
  contentId: number
  height: number
  width: number
}
const gridDataState: State = {
  sort: [{field: 'roW_Id', dir: 'asc'}],
}
const GridReport = ({contentId, height}: GridReportProps) => {
  const [ReportData, setReportData] = useState<undefined | WidgetDataModel>(undefined)
  const [skip, setSkip] = useState<number>(0)

  const widgetData: IwidgetDataModel = useSelector<RootState>(
    ({dashboard}) =>
      dashboard.widgetsData.find((item: IwidgetDataModel) => item.widgetId === contentId),
    shallowEqual
  ) as IwidgetDataModel
  useEffect(() => {
    // console.log('grid', widgetData)
    if (widgetData !== undefined) setReportData(widgetData.content)
  }, [widgetData])

  // const getFieldName = (fieldName: string) => {
  //   let temp: string = ''
  //   temp = fieldName.replace(/^.{1}/g, fieldName[0].toLowerCase())
  //   return temp
  // }
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    //console.log(event)
    setDataState({
      ...dataState,
      sort: event.dataState.sort,
    })
  }
  const [dataState, setDataState] = useState<State>(gridDataState)
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip)
  }

  return ReportData !== undefined && ReportData.dataTableDynamic?.length !== 0 ? (
    <Grid
      // pageable
      resizable
      style={{height: height}}
      data={process(ReportData?.dataTableDynamic.slice(skip, skip + 10), dataState)}
      sortable={true}
      skip={skip}
      pageSize={10}
      scrollable={'virtual'}
      {...dataState}
      onPageChange={pageChange}
      rowHeight={40}
      total={ReportData?.dataTableDynamic.length}
      onDataStateChange={dataStateChange}
    >
      {ReportData?.gridData?.map(
        ({caption, fieldName, fieldType, currency}: GridData, index: number) => {
          if (fieldName.toLowerCase() === 'itemid' || fieldName.toLowerCase() === '_id') {
            return (
              <GridColumn
                field={fieldName.replace(/^.{1}/g, fieldName[0].toLowerCase())}
                title={caption ? caption.toUpperCase() : fieldName.toUpperCase()}
                editable={false}
                key={index}
                width='100px'
                className={`numberCell`}
              />
            )
          } else {
            return (
              <GridColumn
                key={index}
                field={fieldName.replace(/^.{1}/g, fieldName[0].toLowerCase())}
                title={caption ? caption.toUpperCase() : fieldName.toUpperCase()}
                className={fieldType === 'System.String' ? `stringCell` : `numberCell`}
                width='100px'
              />
            )
          }
        }
      )}
    </Grid>
  ) : (
    <H4Title>No data found</H4Title>
  )
}

export default GridReport
